import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Footer from './Footer';
import Header from './Header';

const ComplaintsProcedure = ({ showstatus, hidepopup }) => {
  return (
    <>
      <Header />
      <section className="main-start bg text-center mb-5">
        <h1>Complaints Procedure</h1>
        <h4>Welcome to Clear Legal Marketing Limited's Complaints Procedure</h4>
      </section>
      <div className="form-wrap">
        <div className="container pb-4">
          <h5>Complaints Procedure</h5>
          <p>
            We hope the service that we provide to all our customers meets, and indeed exceeds, their expectations. However, we appreciate that sometimes, despite our best care and attention, things can go wrong and you feel the need to complain. This document details how you can do this, and what we will do when we receive your complaint.
          </p>
          <h5>How To Complain</h5>
          <p>
            You can make your complaint by email to <a href="mailto:complaintsteam@clearlegalmarketing.co.uk">complaintsteam@clearlegalmarketing.co.uk</a>
          </p>
          <p>
            If you are sending your complaint to us, please ensure that:
          </p>
          <p>
            You provide enough information for us to positively identify you on our systems
          </p>
          <p>You provide details of your complaint</p>
          <p>We would also find it useful if you could provide the following, however this is optional and we will still investigate your complaint without it:
          </p>
          <p>Additional information around your complaint that you think will help us in our investigation.
          </p>
          <p>An indication of what you would like us to do to put things right for you. We cannot guarantee that we will do this, but sometimes it can help us resolve things much quicker if we know what you would like us to do.</p>

          <h5>We Will Do</h5>
          <p>
            When we receive your complaint we will start by sending you an acknowledgement of your complaint. We will include details of the senior manager who will be handling this. We will also send you a copy of this document so you have it to hand. This will be sent to you within 5 business days of us receiving your complaint.
          </p>
          <p>
            We will then investigate the complaint.
          </p>
          <p>
            Within 8 weeks of us receiving your complaint we will either:
          </p>
          <p>
            Send you our final response communication detailing the results of the investigation, whether we uphold the complaint, and if so, whether we are making an offer of redress. (Please note that an offer of redress may not always be financial compensation, it may be that we take some action to put things right with your claim, or even offer an apology).
          </p>
          <p>Send you a communication advising you that we are still not in a position to issue our final response. We will tell you why this is and also when we expect to be able to do so.</p>
          <p>
            In both of these cases we will provide you with details of the Financial Ombudsman whom you will then be entitled to refer the matter to if you are unhappy with our response, or do not want to wait beyond the 8 week time limit.
          </p>
          <p>Please note, you will not be able to refer the case to the Financial Ombudsman unless you have given us the opportunity to investigate it first.
          </p>

          <h5>Financial Ombudsmen</h5>
          <p>The Financial Ombudsman is a free arbitration service which is provided to review and adjudicate on complaints where the complainant is not satisfied with the businesses response or has taken more than 8 weeks to issue a final response and the complainant is not willing to wait any longer.</p>
          <p>The Financial Ombudsman website can be found at<a href=" https://www.financial-ombudsman.org.uk/" target="_blank"> https://www.financial-ombudsman.org.uk/</a></p>
          <p>They can also be contacted by:</p>
          <p>Telephone : 0800 023 4567 (Between 8:00am and 8:00pm Monday – Friday and 9:00am and 1:00pm Saturday)</p>
          <p>Online Complaint Form : <a href="https://help.financial-ombudsman.org.uk/help " target="_blank">https://help.financial-ombudsman.org.uk/help </a>
          </p>
          <p>
            By Post : Financial Ombudsman, Exchange Tower, Harbour Exchange, London, E14 9SR
          </p>

          <h5>Time Limits
          </h5>
          <p>
            There are some important time limits that you must observe if you want to refer your complaint against us to the Financial Ombudsman. These limits are:
          </p>
          <p>
            Within 6 years from when the problem happened OR 3 years from when you found out about it
          </p>
          <p>
            AND
          </p>
          <p>Within 6 months of us providing you with our Final Response Communication</p>
          <p>If the referral of your complaint does not meet both time limits the Financial Ombudsman may not be able to investigate it.</p>
        </div>
      </div>
      <Footer />

      <div className="modal fade" id="model1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog ">
          <div className="modal-content popup-style p-4">
            <div className="modal-body text-center">
              <h1>Unfortunately we cannot process any cases based on this criteria</h1>
            </div>
            <button type="button" className="btn com_bnt w-100" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
      <script src="dist/js/app.js"></script>

    </>
  )
}

export default ComplaintsProcedure;