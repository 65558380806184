import React from "react";
import Header from "./Header";
import Footer from "./Footer";
const PreContractInfo = () => {
    return (
        <>
            <div className="pre_contact_info">
                <body className="bg">
                    <div className="clearfix"></div>
                    <Header />
                    <section className="main-start bg text-center mb-5">
                        <h1>Pre Contract Info</h1>
                        <h4>Welcome to Clear Legal Marketing Limited's Pre Contract Info</h4>
                    </section>
                    <div className="form-wrap">
                        <div className="container pb-4">
                            <table className="main-table" aria-describedby="ex1">
                                <th className="d-none"></th>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5>
                                                <b>Pre Contract Information</b>
                                            </h5>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table style={{ borderCollapse: 'unset !important' }} aria-describedby="ex3">
                                                <th className="d-none"></th>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>
                                                                <b>Nature of the Service</b>
                                                            </p>
                                                            <p>
                                                                We pursue claims on behalf of individuals for Irresponsible Lending. Most loans were
                                                                arranged online in minutes. The lenders were and continue to be under a duty to
                                                                carry out reasonable and proportionate affordability checks before approving the
                                                                loans. Often, the checks carried out were either not sufficient or further lending
                                                                meant that additional checks should have been carried out.
                                                            </p>
                                                            <p>
                                                                If you wish to instruct us, we will gather information from you and/or your Lender
                                                                to establish whether they indeed carried out the appropriate affordability checks.
                                                                If they did not, we will then establish whether, had they done so, they would have
                                                                still agreed to lend to you.
                                                            </p>
                                                            <p>
                                                                If, once our investigations are complete, we determine that the lender shouldn’t
                                                                have lent to you then we will seek redress either directly with the lender or if
                                                                necessary via the Financial Ombudsman.
                                                            </p>
                                                            <p>
                                                                Where we do not believe your claim has merit, we will advise you of this and the
                                                                reasons why.
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <p>
                                                <b>Illustration of fees</b>
                                            </p>
                                            <p>
                                                We will act on a “no win no fee” basis which means that you only pay our fees in the event you
                                                are
                                                successful and at least part of the award includes a cash refund.
                                            </p>
                                            <p>
                                                Where you have an outstanding balance with your lender, they might seek to offset any
                                                compensation against your outstanding liabilities. This means that you may not receive the
                                                actual cash in your hand. In the event that ALL of your compensation is a reduction in your
                                                outstanding balance, we will not charge you a fee. The amount you will pay us will never exceed
                                                the total cash compensation you receive but it is calculated based on the amount of Total
                                                Compensation you are awarded
                                            </p>
                                            <p>
                                                ‘Total Compensation’ means any direct monetary payment or balance reduction made by your
                                                opponent in respect of your claim. It includes both the cash and balance reduction elements of a
                                                refund. For Example, if you are award £1000 refund but have a £500 balance and as a result
                                                receive £500 cash plus a reduction in your balance of £500, we will charge our fee based on
                                                £1000.
                                            </p>
                                            <p>
                                                The below table details what we charge:
                                            </p>

                                            <table className="table_123" aria-describedby="ex4">
                                                <th className="d-none"></th>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>
                                                                <b>Band</b>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <b>Total Compensation Received</b>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <b>Maximum % rate of charge (Inc VAT)</b>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <b>Maximum total fee (Inc VAT)</b>
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            £1 - £1,499
                                                        </td>
                                                        <td>
                                                            36%
                                                        </td>
                                                        <td>
                                                            £504.00
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            £1,500 - £9,999
                                                        </td>
                                                        <td>
                                                            33.6%
                                                        </td>
                                                        <td>
                                                            £3,000.00
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            3
                                                        </td>
                                                        <td>
                                                            £10,000 - £24,999
                                                        </td>
                                                        <td>
                                                            30%
                                                        </td>
                                                        <td>
                                                            £6,000.00
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            4
                                                        </td>
                                                        <td>
                                                            £25,000 - £49,999
                                                        </td>
                                                        <td>
                                                            24%
                                                        </td>
                                                        <td>
                                                            £9,000.00
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            5
                                                        </td>
                                                        <td>
                                                            £50,000 or above
                                                        </td>
                                                        <td>
                                                            18%
                                                        </td>
                                                        <td>
                                                            £12,000.00
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- ======  page 2 ====== --> */}
                    <div className="form-wrap">
                        <div className="container pb-4">
                            <p>
                                <i>
                                    The standardised figures used above are for cost illustrations only and are not to be taken as an
                                    estimate of the amount likely to be recovered for you. The fee you will have to pay will be based on your
                                    compensation award which will vary based on the amount you borrowed, length of time and overall cost of
                                    the lending. This means what you pay might be more or less than the illustrations shown above.
                                </i>
                            </p>
                            <p>
                                If you fail to cooperate or provide false or misleading information to Clear Legal Marketing Limited, you
                                may be liable to pay any costs incurred.
                            </p>
                            <p>
                                Please note: the amount you pay will either be a sum equivalent to the ‘Maximum % Rate of Charge’ or a sum
                                equivalent to the ‘Maximum Total Fee’, whichever is the lesser.
                            </p>
                            <p>
                                For example, a customer who is awarded redress of £1,000 must not be charged more than £300 (plus VAT). This
                                is the lower figure out of: (a) the maximum % rate 36% of £1,000 and (b) the maximum total fee of £504.00.
                            </p>
                            <p>
                                However, a customer who is awarded redress of £1,450 must not be charged more than £504. This is the lower
                                figure out of: (a) the maximum % rate of 36% of £1,450 awarded (which is £522); and (b) the Max Total Fee of
                                £504.
                            </p>
                            <p>
                                <b>Worked Fee Examples</b>
                            </p>

                            <p>
                                <u>Full Cash Refund</u>
                            </p>
                            <p>
                                This provides you with worked examples where you receive 100% of your Total Compensation in cash.
                            </p>

                            <div className="scroll-table1">
                                <table className="table_123" aria-describedby="ex-7">
                                    <thead className="d-none">
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>
                                                    <b>Example</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>Cash Refund Received</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>Maximum % rate of charge (Inc VAT)</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>Maximum Fee You Will Pay</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>
                                                        Amount you will receive after our fee
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                1
                                            </td>
                                            <td>
                                                £749.50
                                            </td>
                                            <td>
                                                36%
                                            </td>
                                            <td>
                                                £269.82
                                            </td>
                                            <td>
                                                £479.68
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                2
                                            </td>
                                            <td>
                                                £4,999.50
                                            </td>
                                            <td>
                                                33.6%
                                            </td>
                                            <td>
                                                £1,679.83
                                            </td>
                                            <td>
                                                £3,319.67
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                3
                                            </td>
                                            <td>
                                                £12,499.50
                                            </td>
                                            <td>
                                                30%
                                            </td>
                                            <td>
                                                £3,749.85
                                            </td>
                                            <td>
                                                £8,749.65
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                4
                                            </td>
                                            <td>
                                                £24,999.50
                                            </td>
                                            <td>
                                                24%
                                            </td>
                                            <td>
                                                £5,999.88
                                            </td>
                                            <td>
                                                £18,999.62
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                5
                                            </td>
                                            <td>
                                                £50,000.00
                                            </td>
                                            <td>
                                                18%
                                            </td>
                                            <td>
                                                £9,000.00
                                            </td>
                                            <td>
                                                £41,000.00
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>
                                <u>Part Cash Refund and Part Balance Reduction</u>
                            </p>
                            <p>
                                This provides you with worked examples where you receive only part of your Total Compensation in cash and
                                the rest pays off your outstanding balance.
                            </p>
                            <div className="scroll-table2 ">
                                <table className="table_123" aria-describedby="ex-6">
                                    <thead className="d-none">
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>
                                                    <b>Total Compensation</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>Cash Refund Element</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>Balance Reduction Element</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>Maximum Cash Fee You Will Pay</b>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <b>
                                                        Amount you will receive after our fee
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                £749.50
                                            </td>
                                            <td>
                                                £374.75
                                            </td>
                                            <td>
                                                £374.75
                                            </td>
                                            <td>
                                                £269.82
                                            </td>
                                            <td>
                                                £104.93 plus 100% write off your remainingbalance
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                £4,999.50
                                            </td>
                                            <td>
                                                £2,499.75
                                            </td>
                                            <td>
                                                £2,499.75
                                            </td>
                                            <td>
                                                £1,679.83
                                            </td>
                                            <td>
                                                £819.918 plus 100% write off your remaining balance
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                £12,499.50
                                            </td>
                                            <td>
                                                £6,249.75
                                            </td>
                                            <td>
                                                £6,249.75
                                            </td>
                                            <td>
                                                £3,749.85
                                            </td>
                                            <td>
                                                £499.9 plus 100% write off your remaining balance
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                £24,999.50
                                            </td>
                                            <td>
                                                £12,499.75
                                            </td>
                                            <td>
                                                £12,499.75
                                            </td>
                                            <td>
                                                £5,999.88
                                            </td>
                                            <td>
                                                £6499.87 plus 100% write off your remaining balance
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                £50,000.00
                                            </td>
                                            <td>
                                                £25,000.00
                                            </td>
                                            <td>
                                                £25,000.00
                                            </td>
                                            <td>
                                                £9,000.00
                                            </td>
                                            <td>
                                                £16000 plus 100% write off your remaining balance
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                £1,499
                                            </td>
                                            <td>
                                                £149.90
                                            </td>
                                            <td>
                                                £1,349.10
                                            </td>
                                            <td>
                                                £149.90
                                            </td>
                                            <td>
                                                £0 plus 100% write off your remaining balance
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <!-- ====== page 3 ==== --> */}
                    <div className="form-wrap">
                        <div className="container pb-4">
                            <table className="main-table" aria-describedby="table1">
                                <th className="d-none"></th>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table aria-describedby="table1" style={{ borderCollapse: 'unset' }}>
                                                <th className="d-none"></th>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>
                                                                <b>Commission Payments</b>
                                                            </p>
                                                            <p>
                                                                Credit Reporting Agency Limited will pay Clear Legal Marketing Limited a fee of £12
                                                                for each validated subscription to their 30 day free trial. In addition, they also
                                                                offer an additional tiered bonus structure based on the number of leads we send to
                                                                them in a calendar month. Please see a breakdown below.
                                                            </p>
                                                            <p>
                                                                <ul className="bullet-points">
                                                                    <li>&nbsp; Tier 1: 50 - 99 leads = £200</li>
                                                                    <li>&nbsp; Tier 2: 100 - 249 leads = £400</li>
                                                                    <li>&nbsp; Tier 3: 250 - 499 leads = £1000</li>
                                                                    <li>&nbsp; Tier 4: 500 - 999 leads = £2000</li>
                                                                    <li>&nbsp; Tier 5: 1000 &gt; = £6000</li>
                                                                </ul>
                                                            </p>
                                                            <p>
                                                                For example, if we were to send them 100 validated trials in a single month, we
                                                                would receive £1,200 plus the £400 bonus.
                                                            </p>
                                                            <p>
                                                                If you want to know more about this arrangement, please contact us to discuss.
                                                            </p>
                                                            <p>
                                                                We ask that you send us a copy of your credit report so that it can assist in the
                                                                formulation of your complaint and so we can highlight any additional claims you
                                                                might have.
                                                            </p>
                                                            <p>
                                                                You do not need to use Credit Report Agency Limited&#39;s product. There are a
                                                                number of companies providing credit reports which you could use instead.
                                                            </p>
                                                            <p>
                                                                This provides you with worked examples where you receive 100% of your Total
                                                                Compensation in cash.
                                                            </p>

                                                            <p>
                                                                <b>Alternative Ways to Claim</b>
                                                            </p>
                                                            <p>
                                                                You do not need to use our services to make a claim against your lender(s). It’s
                                                                free to claim compensation from financial companies if you have lost money from a
                                                                mis-sold financial product. You can do this yourself directly with the lender(s) and
                                                                even if they reject your claim you can still take this to the Financial Ombudsman
                                                                Service
                                                                <a href="http://www.financial-ombudsman.org.uk/" target="_blank"> http://www.financial-ombudsman.org.uk/</a>.
                                                                If you aren’t sure how to make the claim, you can get free, impartial advice from
                                                                consumer organisations.
                                                            </p>

                                                            <p>
                                                                <b>Overview of key stages of a claim</b>
                                                            </p>

                                                            <table className="table_123" aria-describedby="table1">
                                                                <th className="d-none"></th>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <p>Stage 1</p>
                                                                        </td>
                                                                        <td style={{ textAlign: "left", paddingLeft: '5px' }}>
                                                                            <p>Initial online assessment of potential claim</p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            Stage 2
                                                                        </td>
                                                                        <td style={{ textAlign: "left", paddingLeft: '5px' }}>
                                                                            <p>Request for information from Lender or Claim Submission</p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <p>Stage 3</p>
                                                                        </td>
                                                                        <td style={{ textAlign: "left", paddingLeft: '5px' }}>
                                                                            <p>Lender response</p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <p>Stage 4</p>
                                                                        </td>
                                                                        <td style={{ textAlign: "left", paddingLeft: '5px' }}>
                                                                            <p>Advice on Lender Response</p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <p>Stage 5</p>
                                                                        </td>
                                                                        <td style={{ textAlign: "left", paddingLeft: '5px' }}>
                                                                            <p>Complaint Upheld – Recommend acceptance</p>
                                                                            <p>Complaint Upheld - Recommend rejection and Referral to Financial</p>
                                                                            <p>Ombudsman Service (FOS)</p>
                                                                            <p>Complaint Rejected – No Further Action</p>
                                                                            <p>Complaint Rejected - Referral to FOS</p>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <p>Stage 6</p>
                                                                        </td>
                                                                        <td style={{ textAlign: "left", paddingLeft: '5px' }}>
                                                                            <p>Redress Received</p>
                                                                            <p>Case Rejected by FOS</p>
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* <!-- ====== page 4 ==== --> */}
                    <div className="form-wrap">
                        <div className="container pb-4">
                            <table className="main-table" aria-describedby="table2">
                                <th className="d-none"></th>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table aria-describedby="table2" style={{ bordercollapse: 'unset' }}>
                                                <th className="d-none"></th>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>
                                                                <b>Keeping you updated</b>
                                                            </p>
                                                            <p>
                                                                We will keep you updated at each key stage of the process.
                                                            </p>
                                                            <p>
                                                                Our primary form of communication will be through digital formats. We limit methods of communication to digital formats so that we can adopt a streamlined process which allows us to limit our fees as explained below.
                                                            </p>
                                                            <p>
                                                                However, in exceptional circumstances we will do our best to accommodate alternative methods of communication. Please contact us to discuss further.
                                                            </p>
                                                            <p>
                                                                <b>What you have to do</b>
                                                            </p>
                                                            <p>
                                                                You will need to provide sufficient detail about your loans or credit facilities,
                                                                that enables your Lender to locate your account. When we have located your loans
                                                                and/or credit facilities, we may ask you for further information about the
                                                                loans/credit facilities and your circumstances at the time they were given to you.
                                                            </p>

                                                            <p>
                                                                <b>Will I still be able to borrow money?</b>
                                                            </p>
                                                            <p>
                                                                As you are raising a complaint based on your loan or credit facility being
                                                                unaffordable, the lenders you are bringing a complaint against might suspend further
                                                                lending on receipt of your complaint.
                                                            </p>

                                                            <p>
                                                                <b>Cancellation</b>
                                                            </p>
                                                            <p>
                                                                If you wish to cancel this contract you can do so within 14 days at no cost. Just
                                                                let us know. If you cancel our service after 14 days and after we submit your claim
                                                                you will be liable to pay our full fee (calculated as shown above) should your claim
                                                                be successful.
                                                            </p>

                                                            <p>
                                                                <b>IVA’s, Debt Relief Orders, Bankruptcy and more</b>
                                                            </p>
                                                            <p>
                                                                If you are:-
                                                            </p>

                                                            <p>
                                                                <ol>
                                                                    <li> &nbsp; have every been subject to an IVA or</li>
                                                                    <li> &nbsp; awaiting the approval of an IVA proposal</li>
                                                                    <li> &nbsp; have ever been declared bankrupt or</li>
                                                                    <li> &nbsp; are subject to a bankruptcy petition</li>
                                                                    <li> &nbsp; have ever been subject to a Debt Relief Order</li>
                                                                    <li> &nbsp; or have even been subject to a similar process</li>
                                                                </ol>
                                                            </p>
                                                            <p>
                                                                you must inform us immediately. If we proceed with a claim for you then you must
                                                                provide us with the IVA Supervisor / Trustee in Bankruptcy’s written consent to
                                                                pursue the claim for you prior to instructing us. You also authorise us to pay
                                                                settlement monies to your IVA Supervisor / Trustee in Bankruptcy after our fees have
                                                                been deducted.
                                                            </p>

                                                            <p>
                                                                <b>Payments Made by Your Guarantor</b>
                                                            </p>
                                                            <p>
                                                                We need to make you aware that if your claim is successful, and your guarantor has
                                                                made payments towards your loan, some lenders which will contact your Guarantor and
                                                                offer to return the payments they made. If they accept the offer, they will be
                                                                released from their obligation to pay and the amount they are refunded could be
                                                                added back to your loan account meaning you would be responsible for repaying it.
                                                                All interest and charges are still likely to be waived and as a result it is only
                                                                the capital amount you will be required to pay back.
                                                            </p>

                                                            <p>
                                                                <b>Complaints</b>
                                                            </p>
                                                            <p>
                                                                Should you have a complaint in regards to the service we have provided, we have a
                                                                complaints procedure which you should follow. The full process can be found on our
                                                                website or can be provided on request.
                                                            </p>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Footer />

                    {/* <!---------------------------------> */}
                    <script src="dist/js/app.js"></script>

                </body>
            </div>
        </>
    )
}
export default PreContractInfo;