import React, { useEffect, useState } from "react";
import { queryString } from "../../../Utility/QueryString";
import { Api } from "../../../api/Api";
import Header from "../../Includes/Layouts/common/Header";
import Accordion from 'react-bootstrap/Accordion';
import Footer from "../../Includes/Layouts/common/Footer";
import FollowupSignatureModal from "./FollowupSignatureModal";
import { useFollowUpPendingDetails } from "../../../Hooks/useFollowUpPendingDetails";
const FollowupSignature = () => {
    const [show, setShow] = useState(false);
    const { followUpPendingDetails } = useFollowUpPendingDetails();
    const handleSignatureModal = () => {
        setShow(false);
    }
    useEffect(() => {
        let followupData = localStorage.getItem('followup_data');
        if(followupData == undefined || followupData == null){
            (async () => {
                const response = await followUpPendingDetails(queryString, null);
                let formData = response.data.response.followup_data;
                localStorage.setItem('followup_data',JSON.stringify(formData));
                localStorage.setItem("user_info",JSON.stringify(response.data.response.user_info));
            })();
            
        }
    },[]);
 return(
    <>
    <div className="signaturev2">
                <div className="clearfix" />
                <Header />
                <section className="main-start bg text-center " />
                <section className="colouredBox">
                    <div className="container">
                        <div className="row  mt-3 mb-2 position-relative">
                            <div className="col-md-12  stepProgress text-center">
                                <div className="progressLine" />
                                <button className="btn btn-circle btn-custom active">1</button>
                                <button className="btn btn-circle btn-custom active">2</button>
                                <button className="btn btn-circle btn-custom active">3</button>
                                <button className="btn btn-circle btn-custom" disabled="disabled">4</button>
                            </div>
                            <div className="col-md-12">
                                <h2 className="mb-0 text-center pt-2">You're almost there ...</h2>
                            </div>
                            <div className="col-md-12 text-right mt-3 ">
                                <button type="button" className="btn btn-custom1 btn-lg w-100 " onClick={() => setShow(true)}>Start My Claim</button>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-sm-12">
                                <p>All that is left for you to do is sign the documents below. Please ensure you review these by clicking on
                                    the sections below. When you are happy, please press 'Start My Claim' to complete the sign-up process.</p>
                                <p>Remember, our service is 100% No Win No Fee so you have nothing to lose. You will only ever pay us a fee if
                                    your claim is successful. If your claim is not successful then we will not charge you a penny. The amount
                                    you pay is based on the Total Compensation you receive and is detailed <a href="/PreContractInfo" target="_blank">here</a>. </p>
                                <p>You do not need to use our services to make a claim against your lender(s). Its free to claim
                                    compensation from financial companies if you have lost money from a mis-sold financial product.
                                    You can do this yourself directly with the lender(s) and even if they reject your claim you can still
                                    take this to the Financial Ombudsman Service <a href="https://www.financial-ombudsman.org.uk" target="_blank">https://www.financial-ombudsman.org.uk</a>. If you
                                    aren't sure how to make the claim, you can get free, impartial advice from consumer organisations.
                                </p>
                                <p>Please note: your lender may suspend further lending on receipt of your claim.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ========== SECOND ACCORDION START ========== */}
                <Accordion>
                    <section className="acordn-sec1">
                        <div className="container">
                            {/* ========== ACCORDION-1 ========== */}
                            <Accordion.Item className="accordion-item " eventKey="5">
                                <Accordion.Header className="accordion-header ">
                                    Contingency Fee Agreement
                                </Accordion.Header>
                                <Accordion.Collapse eventKey="5" id="accor-1" className="accordion-collapse collapse">
                                    <Accordion.Body className="accordion-body">
                                        <section className="container-1">
                                            <div className="bdy_pading">
                                                <div className="mt-3">
                                                    <h4> Non-Contentious Business Agreement </h4>
                                                    <h4> Irresponsible Lending Claims </h4>
                                                    <p>This is your agreement with Clear Legal Marketing Limited in relation to your Irresponsible Lending
                                                        claims. </p>
                                                    <p>What we will do for you </p>
                                                    <ol type={1}>
                                                        <li className="lst_1"> We will work diligently on your behalf, no matter how straightforward or
                                                            difficult your claim, from the day we first discuss it with you until your compensation and fees
                                                            have been paid. This work will be limited to pursuing your claim for all losses suffered by you as
                                                            a result of the mis-sale of your loan or credit facility. </li>
                                                        <li className="lst_1"> If you win your claim, we are entitled to deduct an amount from your
                                                            Compensation, in respect of our fees </li>
                                                        <li className="lst_1"> The amount we are entitled to deduct will be based on the Total Compensation you
                                                            receive and will be calculated by reference to the Charging Table in Clause 5 and will either be a
                                                            sum equivalent to the ‘Maximum % Rate of Charge’ or a sum equivalent to the ‘Maximum Total Fee’,
                                                            which ever is the lesser. However, the amount you pay us will never exceed the total cash
                                                            compensation you receive. </li>
                                                        <li className="lst_1"> If you lose your claim, and this agreement has been complied with, you do not
                                                            have to pay us anything. </li>
                                                        <li className="lst_1"> Total Compensation’ means any direct monetary payment or balance reduction made
                                                            by your opponent in respect of your claim. It includes both the cash and balance reduction
                                                            elements of a refund. For Example, if you are award £1000 refund but have a £500 balance and as a
                                                            result receive £500 cash plus a reduction in your balance of £500, we will charge our fee based on
                                                            £1000. </li>
                                                        <li className="lst_1"> Charging Table: </li>
                                                    </ol>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <th> Band </th>
                                                                <th> Total Compensation</th>
                                                                <th> Maximum % rate of charge (Inc VAT)</th>
                                                                <th> Maximum total fee (Inc VAT) </th>
                                                            </tr>
                                                            <tr>
                                                                <td> 1</td>
                                                                <td> £1 - £1,499 </td>
                                                                <td>36% </td>
                                                                <td>£504.00 </td>
                                                            </tr>
                                                            <tr>
                                                                <td> 2</td>
                                                                <td> £1,500 - £9,999</td>
                                                                <td>33.6% </td>
                                                                <td> £3,000.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td> 3</td>
                                                                <td> £10,000 - £24,999 </td>
                                                                <td> 30% </td>
                                                                <td>£6,000.00 </td>
                                                            </tr>
                                                            <tr>
                                                                <td> 4</td>
                                                                <td>£25,000 - £49,999 </td>
                                                                <td> 24% </td>
                                                                <td> £9,000.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td> 5</td>
                                                                <td> £50,000 or above </td>
                                                                <td> 18% </td>
                                                                <td> £12,000.00 </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p className="mt-3"> Please note: both the ‘Maximum % Rate’ and the ‘Maximum Total Fee’ include VAT at the
                                                        prevailing rate of 20%. </p>
                                                </div>
                                                {/* page-2 */}
                                                <div className="py-3">
                                                    <p> What you must do for us </p>
                                                    <ol>
                                                        <li value={7} className="lst_1"> You must do all you can to help us to progress your claim. You must
                                                            provide us with honest instructions that enable us to do our work properly and to run your claim
                                                            efficiently. <br />
                                                            <br /> Please sign, to confirm you understand the contents of this document, that you agree to be
                                                            bound by this Agreement, and in the event your claim is successful you will pay us an amount of
                                                            your Compensation in line with the Charging Table:
                                                        </li>
                                                    </ol>
                                                    <table className="border-0 ">
                                                        <tbody>
                                                            <tr style={{ width: '100%' }}>
                                                                <td className="border-0 text-start fw-normal" style={{ width: '30%' }}> Signature:</td>
                                                                <td className="border-0 text-start" style={{ width: '70%' }}>
                                                                    <img src="{{user_signature_image}}" className="w-25" alt="" />
                                                                </td>
                                                            </tr>
                                                            <tr style={{ width: '100%' }}>
                                                                <td className="border-0 text-start" style={{ width: '30%' }}> Date: </td>
                                                                <td className="border-0 text-start" style={{ width: '70%' }}> {'{'}current date{'}'}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* page-3 */}
                                                <h4 className="text-start"> And now for the (not so) small print </h4>
                                                <p>When you instruct Clear Legal Marketing, we will agree to act for you on a risk-free basis. If you
                                                    win your claim: </p>
                                                <ul className="mrgn_1">
                                                    <li className="lst_2">we will charge you a percentage from your Total Compensation in respect of our fees,
                                                        irrespective of the amount of work we have done; </li>
                                                    <li className="lst_2"> the total amount we charge will be based on the Total Compensation you receive and
                                                        it will be calculated by reference to the Charging Table above. We will only charge you the 'Maximum
                                                        % rate of Charge’ or the ‘Maximum Total Fee’, whichever is the lesser. </li>
                                                    <li className="lst_2"> In addition, the amount we will charge you will never exceed the total amount of
                                                        cash compensation you receive.</li>
                                                    <li className="lst_2"> we will pay any money we receive on your behalf into a designated account, to make
                                                        sure that your Compensation is secure; </li>
                                                </ul>
                                                <h4 className="text-start"> Possible Outcomes</h4>
                                                <p> There are 4 potential outcomes on your case. The amount we charge will be dependent on the outcome:-
                                                </p>
                                                <ul className="mrgn_1">
                                                    <li className="lst_2">
                                                        <b>Full Cash Refund</b> – this means that you receive 100% of the Total Compensation from the Lender
                                                        in cash. We will charge you based on the Total Compensation in accordance with the Charging Table
                                                        above.
                                                    </li>
                                                    <li className="lst_2">
                                                        <b> Cash and Offset Refund</b> – this means that you have an outstanding balance with the lender
                                                        which is less than the Total Compensation awarded. As a result, you receive both a balance reduction
                                                        AND a cash award. We will charge you on the Total Compensation in accordance with the Charging Table
                                                        above, however, we will cap our charges at no more than 100% of the Cash Refund element of your
                                                        award.
                                                    </li>
                                                    <li className="lst_2">
                                                        <b>Offset Refund</b> – this means that you have an outstanding balance with the lender which is
                                                        greater than the Total Compensation awarded. As a result, the full amount of the Total Compensation
                                                        reduces your balance and you receive no physical cash. We will NOT charge you in this scenario.
                                                    </li>
                                                    <li className="lst_2">
                                                        <b>Failed complaint</b> – this means your complaint has not been upheld and as a result no
                                                        Compensation is awarded. We will NOT charge you in this scenario.
                                                    </li>
                                                </ul>
                                                <h4 className="text-start"> Worked Examples</h4>
                                                <p className="text-decoration-underline"> Full Cash Refund</p>
                                                <table className="border-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th> Cash Refund Received</th>
                                                                            <th> Our Fee </th>
                                                                            <th> You Receive</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> £749.50 </td>
                                                                            <td> £269.82 </td>
                                                                            <td> £479.68 </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>£4,999.50</td>
                                                                            <td> £1,679.83 </td>
                                                                            <td> £3,319.67</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> £12,499.50</td>
                                                                            <td> £3,749.85</td>
                                                                            <td> £8,749.65</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> £24,999.50</td>
                                                                            <td> £5,999.88</td>
                                                                            <td> £18,999.62</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>£50,000.00</td>
                                                                            <td> £9,000.00 </td>
                                                                            <td> £41,000.00</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* page-4 */}
                                                <p className="text-decoration-underline"> Cash and Offset Refund</p>
                                                <div className="tbl_ovrflw">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <th> Total Compensation</th>
                                                                <th> Cash Refund Elemen</th>
                                                                <th> Balance Reduction Element</th>
                                                                <th> Our Fee</th>
                                                                <th> You Receive</th>
                                                            </tr>
                                                            <tr>
                                                                <td> £749.50 </td>
                                                                <td> £374.75</td>
                                                                <td> £374.75</td>
                                                                <td> £269.82 </td>
                                                                <td> £104.93 plus 100% write off your remaining balance </td>
                                                            </tr>
                                                            <tr>
                                                                <td> £4,999.50</td>
                                                                <td> £2,499.75</td>
                                                                <td> £2,499.75</td>
                                                                <td> £1,679.83</td>
                                                                <td> £819.918 plus 100% write off your remaining balance</td>
                                                            </tr>
                                                            <tr>
                                                                <td>£12,499.50 </td>
                                                                <td> £6,249.75</td>
                                                                <td> £6,249.75</td>
                                                                <td> £3,749.85</td>
                                                                <td>£499.9 plus 100% write off your remaining balance</td>
                                                            </tr>
                                                            <tr>
                                                                <td>£24,999.50</td>
                                                                <td>£12,499.75</td>
                                                                <td>£12,499.75</td>
                                                                <td> £5,999.88</td>
                                                                <td> £6499.87 plus 100% write off your remaining balance</td>
                                                            </tr>
                                                            <tr>
                                                                <td>£50,000.00</td>
                                                                <td> £25,000.00</td>
                                                                <td> £25,000.00</td>
                                                                <td> £9,000.00</td>
                                                                <td> £16000 plus 100% write off your remaining balance</td>
                                                            </tr>
                                                            <tr>
                                                                <td> £1,499</td>
                                                                <td> £149.90</td>
                                                                <td> £1,349.10</td>
                                                                <td> £149.90</td>
                                                                <td> £0 plus 100% write off your remaining balance</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <p className="mt-5"> If you lose your claim:</p>
                                                <ul className="mrgn_1">
                                                    <li className="lst_2">you do not have to pay us anything</li>
                                                </ul>
                                                <p> For the definitions of “Win”, “Lose”, and “Compensation” please refer to your client care letter
                                                    that will be sent to you once you have signed and returned this agreement. </p>
                                                <p> What you must do for us </p>
                                                <ul className="mrgn_1">
                                                    <li className="lst_2">You must give us full, honest and timely instructions; </li>
                                                    <li className="lst_2"> You must locate and provide to us any relevant documentation that you may have (if
                                                        any);</li>
                                                    <li className="lst_2"> You must undertake enquiries and provide information reasonably required by us;
                                                    </li>
                                                    <li className="lst_2"> You must always co-operate with us;</li>
                                                    <li className="lst_2"> You must not ask us to work in an improper or unreasonable way; </li>
                                                    <li className="lst_2"> You must not deliberately mislead us;</li>
                                                    <li className="lst_2"> You must keep us updated if your contact details change.</li>
                                                </ul>
                                                <h4> Ending the agreement </h4>
                                                <p>We can end the agreement: </p>
                                                <ul className="mrgn_1">
                                                    <li className="lst_2">if you reject our advice in relation to an offer of settlement, or if you do not
                                                        comply with your obligations under the agreement and subsequently go to settle your case directly
                                                        with the lender. In this situation you will be liable to pay our full fee in line with the Charging
                                                        Table should your claim be successful.</li>
                                                    <li className="lst_2"> if we advise that your claim is unlikely to succeed; You can end the agreement:
                                                    </li>
                                                    <li className="lst_2"> within fourteen days by confirming this to us in writing using the Model
                                                        Cancellation Form below; </li>
                                                </ul>
                                                {/* page-5 */}
                                                <ul className="mrgn_1">
                                                    <li className="lst_2">at any time before your claim is settled, but if you do you will be liable to pay
                                                        our full fee in line with the Charging Table should your claim be successful. </li>
                                                </ul>
                                                <p> If either we or you end the agreement, we have the right to keep all documents, money and other
                                                    property we hold on your behalf until all money due to us is paid. </p>
                                                {/* page-6 */}
                                                <h4> Model Cancellation Form</h4>
                                                <p>To Clear Legal Marketing</p>
                                                <p>I hereby give notice that I cancel the Agreement for Clear Legal Marketing to pursue my mis-sold Loan
                                                    Refund Complaints on my behalf signed on [&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                                                    &nbsp; ]. </p>
                                                <p className="mt-5"> Name: </p>
                                                <p className="mt-5">Address:</p>
                                                <p className="mt-5"> Signature: </p>
                                                <p className="mt-5"> Date: </p>
                                                <p className="mt-5">Change of mind </p>
                                                <p className="mt-5"> It is important that you are happy with this agreement before you sign it as we cannot
                                                    cancel it once it has been signed. </p>
                                            </div>
                                        </section>
                                    </Accordion.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>

                            <div>
                                {/* ========== ACCORDION-2 ========== */}
                                <Accordion.Item className="accordion-item" eventKey="1">
                                    <Accordion.Header className="accordion-header">
                                        Form of Authority
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey="1" id="accor-1" className="accordion-collapse collapse">
                                        <Accordion.Body className="accordion-body">
                                            <section className="container-1">
                                                <div className="mt-3">
                                                    <h4> FORM OF AUTHORITY </h4>
                                                    <p>I appoint and authorise Clear Legal Marketing Limited to consider my claim for the mis-selling of
                                                        my loans/credit facility in accordance with DISP 2.2 of the FCA Handbook. Clear Legal Marketing
                                                        Limited is regulated by the FCA under FCA Number 833090.</p>
                                                    <p>I also authorise and insist that you, The Lender, release to Clear
                                                        Legal Marketing without delay any information or records they ask for, in accordance with the
                                                        'Rights of Data Subjects and Others' under the Data Protection Act 2018. </p>
                                                    <p>In the event that my complaint is successful and compensation is awarded to me, I authorise and
                                                        insist that you, The Lender , release the compensation payment funds to
                                                        Clear Legal Marketing Limited without delay. </p>
                                                    <p>I request that all responses are returned directly to Clear Legal Marketing.</p>
                                                    
                                                </div>
                                            </section>
                                        </Accordion.Body>
                                    </Accordion.Collapse>
                                </Accordion.Item>




                            </div>
                            <div>
                                {/* ========== ACCORDION-3 ========== */}

                                <Accordion.Item className="accordion-item" eventKey="2">
                                    <Accordion.Header className="accordion-header">
                                        Confirmation Statement
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey="2" id="accor-1" className="accordion-collapse collapse">
                                        <Accordion.Body className="accordion-body">
                                            <section className="container-1">
                                                <div className="mt-3">
                                                    <h4> Confirmation Statement </h4>
                                                    <p>I, Client Full Name, confirm that I have read and understand that:-
                                                    </p>
                                                    <ul className="mrgn_2">
                                                        <li className="lst_3"> I do not need to use Clear Legal Marketing Limited to make a claim against my
                                                            lender(s).</li>
                                                        <li className="lst_3">It is possible for me to present a claim directly to a lender or to the Financial
                                                            Services Ombudsman for free.</li>
                                                    </ul>
                                                    <p>However, having considered these options, I still wish to instruct Clear Legal Marketing Limited to
                                                        present the claim(s) on my behalf.</p>
                                                </div>
                                            </section>
                                        </Accordion.Body>
                                    </Accordion.Collapse>
                                </Accordion.Item>


                            </div>
                            <div className="col-md-12">
                                <input type="hidden" name="user[subid]" defaultValue />
                                <button type="button" id="submitCase" className="btn btn-custom1 btn-lg w-100 mt-3 showSignature" onClick={() => setShow(true)}>Start My Claim</button>
                            </div>
                        </div>
                    </section>
                </Accordion>
                {/* ========== SECOND ACCORDION END ========== */}
                <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next" />
                {/* ========== FOOTER ========== */}
                <Footer />
                {/* ========== FOOTER ========== */}
                {/* ========== MODAL ========== */}
                <FollowupSignatureModal
                    modalDisplay={show}
                    handleClose={handleSignatureModal}
                />
                {/* ========== MODAL ========== */}
            </div >
    </>
 )
}
export default FollowupSignature;