const questionnaire1 = {
      questionId: 1,
      question: "How many loans have you had with Lending Stream?",
      sub_question:"If you had to roll over your loans with Lending Stream in order to repay the loan before. Please count each roll over as a separate loan. ",
      answers: [
            {
                  label: "1-4",
                  value: "1"
            },
            {
                  label: "5-10",
                  value: "2"
            }
            ,
            {
                  label: "10+",
                  value: "3"
            }

      ]
};
const questionnaire2 = {
      questionId: 2,
      question: "Have you made a claim against Lending Stream previously?",
      answers: [
            {
                  label: "Yes",
                  value: "4"
            },
            {
                  label: "No",
                  value: "5"
            }

      ]
};
const questionnaire3 = {
      questionId: 3,
      question: "Please select the year you took your first loan  and the year your loans ended ",
      question_yes: "Please select the year you took your first loan ",
      answers: [],
};
const questionnaire4 = {
      questionId: 4,
      question: "If you have your Loan Account number it will speed up your claim. If you don't have it you can still continue by clicking SKIP below.",
      answers: [
            {
                  label: "",
                  value: ""
            }
      ]
};
const questionnaire5 = {
      questionId: 5,
      question: "Please TICK AT LEAST ONE that applies to you",
      answers: [
            {
                  label: "I already had multiple other loans at the time I applied for this loan",
                  value: "6"
            },
            {
                  label: "The loan was extended without reassessing my situation",
                  value: "7"
            }
            ,
            {
                  label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
                  value: "8"
            },
            {
                  label: "I had to borrow money from another lender to pay back a loan or cover essentials",
                  value: "9"
            },
            {
                  label: " My income changed between taking out and repaying the loan but the lender didn’t check",
                  value: "10"
            },
            {
                  label: "The lender didn’t ask me any questions to confirm that I would be able to repay the loan in a sustainable way",
                  value: "11"
            },
            {
                  label: "I rolled over my loans in order to keep up with re-payments",
                  value: "12"
            },
            {
                  label: "I used my loan to pay for gambling",
                  value: "13"
            },
            {
                  label: " My overall debt amount had increased since I was given my first loan but they still lent me more money",
                  value: "14"
            },
            {
                  label: "I regularly exceeded my credit limit but they still lent me more money",
                  value: "15"
            },
            {
                  label: "I regularly missed my monthly repayments",
                  value: "16"
            },

      ],
};
const questionnaire6 = {
      questionId: 6,
      question: "Please tell why you believe the lending was unaffordable",
      answers: [
            {
                  label: "",
                  value: ""
            },

      ],
};
const questionnaire8 = {
      questionId: 8,
      question: "Employment Status",
      answers: [
            {
                  label: "Full Time",
                  value: "22"
            },
            {
                  label: "Self Employed",
                  value: "23"
            },
            {
                  label: "Part Time",
                  value: "24"
            },
            {
                  label: "Temporary",
                  value: "25"
            },
            {
                  label: "Zero Hours",
                  value: "26"
            },
            {
                  label: "Unemployed",
                  value: "27"
            },

      ],
};
const questionnaire9 = {
      questionId: 9,
      question: "Is your loan with Lending Stream still active?",
      answers: [
            {
                  label: "Yes",
                  value: "28"
            },
            {
                  label: "No",
                  value: "29"
            }

      ]
};
export {
      questionnaire1,
      questionnaire2,
      questionnaire3,
      questionnaire4,
      questionnaire5,
      questionnaire6,
      questionnaire8,
      questionnaire9,
};