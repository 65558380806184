import React, { useRef, useState, useContext, useEffect, } from "react";
import noteRight from "../../../../assets/img/note-right.png";
const QuestionRadioSlide1 = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const [showError, setShowError] = useState('d-none');
  const popUpVal = localStorage.getItem('popVal');
  const validateRadio = async (e) => {
    e.preventDefault();
    let radioChecked = await props.trigger(radioName);
    if (radioChecked) {
      props.slideChange(e, radioName);
    } else {
      setShowError('d-block');
    }
  };
  return (
    <>
      <div className={`slide-top  ${props.showQuestionSlide}`}>
        <h1 className="title">
          {props.questionnaire.question}
        </h1>
        <p>
          {(props.questionnaire.sub_question != "") ? props.questionnaire.sub_question : ""}
        </p>
        {props.questionnaire.answers.map((item, index) => {
          return (
            <div className={(index === 0 ? 'option-btn loanbtn btn1to4' : 'option-btn loanbtn')} key={index}>
              <input type="radio"
                id={'question_' + question_id + '_' + item.value}
                name={(index === 0 ? 'pop_question_1' : 'question_' + question_id)}
                value={item.value} data-answertext={item.label}
                data-question_id={question_id}
                data-nextquestion={props.nextQuestion}
                ref={props.validation({ required: "Please Select" })}
                onClick={(e) => { props.slideChange(e, e.target.name); localStorage.setItem('popVal', e.target.value) }}
                checked={popUpVal != null && popUpVal == item.value ? true : ""}
              />

              <label htmlFor={'question_' + question_id + '_' + item.value}
                className="btn-upload slide_next"
              >
                {item.label}
                <span class="radio1_text">Loans (Including Roll Overs)</span>
              </label>
            </div>
          );
        })}
        <div className="d-flex justify-content-center">
          <span className={`errormsg1 ${showError}`}>Please Select</span>
        </div>

        <div className="loanbtn">
          {props.backBtn && <button type="button" className="com_bnt me-3 back1 " name={`back${question_id}`} onClick={props.previousSlide}>
            <i className="bi bi-arrow-left" /> Back{" "}
          </button>}
          {/* <button className="com_bnt next1" name={`next${question_id}`} 
          data-question_id={question_id} onClick={validateRadio}>
            {" "}
            Next <i className="bi bi-arrow-right" />
          </button> */}
        </div>
        {/* <div className="col-1 anim-desk d-lg-block d-md-block d-sm-block d-none">
          <p>Most popular amongst our clients</p>
        </div>
        <div class="col-1 anim-mob d-block d-md-none d-sm-none d-lg-none">

          <p><img src={noteRight} alt=""/></p>
        </div> */}
      </div>
    </>
  )
};

export default QuestionRadioSlide1;
