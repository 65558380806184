import React from "react";
import { DobDay, DobMonth, DobYearNew } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const DateOfBirth = ({ txtDob, validation, validationMsg }) => {
  return (
    <>
      <div className="col-lg-4 col-md-12 col-sm-12 ">
        <div className="form-group row mb-3">
          <label className="text-custom col-md-12">
            <h5>{txtDob}</h5>
          </label>
          <div className="col-4 mb-3">
            <SelectBox
              className="form-style form-select  form-select-md"
              OptionValue={DobDay}
              id="dobDay"
              name="dobDay"
              myRef={validation({
                required: "Please Select Date",
              })}
            />
            {validationMsg.dobDay && (
              <div className="mb-3 error1">
                <span className="" id="dobday_err">
                  {validationMsg.dobDay.message}
                </span>
              </div>
            )}
          </div>
          <div className="col-4 mb-3">
            <SelectBox
              className="form-style form-select  form-select-md"
              OptionValue={DobMonth}
              id="dobMonth"
              name="dobMonth"
              myRef={validation({
                required: "Please Select Month",
              })}
            />

            {validationMsg.dobMonth && (
              <div className="mb-3 error1">
                <span className="" id="dobmonth_err">
                  {validationMsg.dobMonth.message}
                </span>
              </div>
            )}
          </div>
          <div className="col-4 mb-3">
            <SelectBox
              className="form-style form-select  form-select-md"
              OptionValue={DobYearNew}
              id="dobYear"
              name="dobYear"
              myRef={validation({
                required: "Please Select Year",
              })}
            />
            {validationMsg.dobYear && (
              <div className="mb-3 error1">
                <span className="" id="dobyear_err">
                  {validationMsg.dobYear.message}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DateOfBirth;
