import React, { useEffect } from 'react';
import "../../assets/css/NotFound.scss";
import { useErrorWebhook } from '../../Hooks/useErrorWebhook';

const TestService = () => {
    const { ErrorWebhookTrigger } = useErrorWebhook();
    useEffect(() => {
        const erroMessage = {
            serviceName: 'TESTING WEBHOOK',
            errorReason: "Test Message",
            errorCode: "Test Code",
            uuid: null
        }
        ErrorWebhookTrigger(erroMessage);
    }, []);
    return (
        <>
            <div className="NotFound">
                <div className="flex-center position-ref full-height">
                    <div className="">
                        Test Service
                    </div>

                </div>
            </div>
        </>
    )
}

export default TestService;