import React from "react";
import Header from "../../Includes/Layouts/common/Header";
import Footer from "../../Includes/Layouts/common/Footer";
import FormSplitCL_LS_V6 from "../../Forms/CL_LS_V6/FormSplitCL_LS_V6";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

const CL_LS_V6 = () => {
    const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('CL_LS_V6', 'CL_LS_V6');
    return (
        <>
            <AdtopiaLoadLP pageType="LP" splitName="CL_LS_V6" />
            <FbViewPixel />
            <div className="CL_LS_V3">
                <div className="bg">
                    <div className="clearfix"></div>
                    <Header />
                    <section className="main-start bg text-center mb-5">
                        <h1>Start your Lending Stream Claim Today</h1>
                        <p>Enter your details below to begin. You could be owed £££thousands* if you were mis-sold!</p>
                    </section>
                    <FormSplitCL_LS_V6 splitName={DynamicRouteSplitName} nextPage={DynamicRouteNextPage} />
                    <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next">
                        <div className="container ">
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default CL_LS_V6;
