export const split_Flow = {
    default:{
        1:'Split_1', 
        2:'Signature',
        3:'Proceed',
        4:'Thankyou'
    },
    CL_LS_V3:{
        1:'CL_LS_V3', 
        2:'CL_LS_V3/questionnaire',
        3:'Signature_V2',
        //4:'id-upload',
        // 4:'preview',
        4:'thankyou',
    
    },
    CL_LS_V4:{
        1:'CL_LS_V4', 
        2:'CL_LS_V4/questionnaire',
        3:'Signature_V2',
        // 4:'id-upload',
        // 4:'preview',
        4:'thankyou',
    },
    CL_LS_V5:{
        1:'CL_LS_V5', 
        2:'Signature_V5',
        3:'CL_LS_V5/questionnaire',
        // 4:'id-upload',
        // 4:'preview',
        4:'thankyou',
    },
    CL_LS_V6:{
        1:'CL_LS_V6', 
        2:'CL_LS_V6/questionnaire',
        // 4:'id-upload',
        // 4:'preview',
        3:'thankyou',
    },
    CL_LS_V7:{
        1:'CL_LS_V7', 
        2:'CL_LS_V7/questionnaire',
        3:'Signature_V2',
        //4:'id-upload',
        // 4:'preview',
        4:'thankyou',
    
    },
    CL_LS_V8:{
        1:'CL_LS_V8', 
        2:'Signature_V8',
        3:'CL_LS_V8/questionnaire',
        // 4:'id-upload',
        // 4:'preview',
        4:'thankyou',
    },
}