import React, { useRef, useState, useContext, useEffect, } from "react";
import TextField from "../../../UI/TextField";
import { NumberValidation } from "../../../../Utility/NumberValidation";

const QuestionInputSlide = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const [showError, setShowError] = useState('d-none');
  const validateSlide = async (e) => {
    e.preventDefault();
    let accountNumber = await props.trigger('txtAccountNumber');
    if (accountNumber) {
      let form = props.userQuestionnire.current;
      // if (form["txtAccountNumber"].value.length != 8) {
      //   props.setError("txtAccountNumber", {
      //     type: "manual",
      //     message: "Please Enter Valid Account Number",
      //   });
      // }
      props.slideChange(e, 'txtAccountNumber');
    }
  };
  return (
    <>
      <div className={`slide-top ${props.showQuestionSlide}`}>
        <h1 className="title">  Loan Account Number</h1>
        <p>
          {props.questionnaire.question}
        </p>
        <div className="select-filed    mt-4">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="mb-3 ">
                <TextField
                  className="form-style form-control-lg w-100"
                  type="text"
                  placeholder="Account Number"
                  name="txtAccountNumber"
                  id="txtAccountNumber"
                  maxlength="20"
                  keypress={NumberValidation}
                  validation={props.validation({
                    required: "Please Enter Account Number",
                  })}
                />
                
                  {props.validationMsg.txtAccountNumber && (
                    <div className="mb-3 errormsg">
                    <span className="error " id="email_err">
                      {props.validationMsg.txtAccountNumber.message}
                    </span>
                    </div>
                  )}
                
              </div>
            </div>
          </div>
        </div>
        {/* <input type="hidden" name={`question_${question_id}`} id={`question_${question_id}`} value ={accountNumber}/> */}
        <div className="mt-4 d-flex justify-content-center controle_btn">
          {props.backBtn && <button type="button" className="com_bnt me-3 back3 " name={`back${question_id}`} onClick={props.previousSlide}>
            <i className="bi bi-arrow-left" /> Back{" "}
          </button>}
          <button type="button" className="com_bnt next4" value={question_id} name={`next${question_id}`} onClick={validateSlide} data-question_id={question_id}>
            {" "}
            Next <i className="bi bi-arrow-right" />
          </button>
        </div>
        {/* <div className="text-center mb-3">
          <button className="skip-here fs-5 fw-semibold" value={question_id} name={`next${question_id}`}
           data-question_id={question_id} onClick={(e) => props.slideChange(e, radioName)}>
            <u>Skip for now 3 {">>"}</u>
          </button>
        </div> */}
        <div className="mt-4 d-flex justify-content-center controle_btn">
          <button type="button" className="skip_button" value={`skip${question_id}`} name={`next${question_id}`}
           onClick={(e)=>props.slideChange(e, radioName)} data-question_id={question_id}>
            Skip
            {/* {" "}
            Skip for now {">>"} */}
          </button>
        </div>
      </div>
    </>
  )
};

export default QuestionInputSlide;
