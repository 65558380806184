import React, { useState } from "react";
import { useReducer } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import Button from "../../../UI/Button";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import {PostcodeValidation} from "../../../../Utility/PostcodeValidation";

const initialState = {
    lookUp: true,
    nextClick: false,
    checkSelect: false,
    getDetails: [],
    getAddress: [],
    showManualAddress: "hide",
    getPostcode: "#",
    clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
    switch (action.type) {
        case "validationSucess": {
            return {
                ...state,
                lookUp: action.payload.lookup,
                nextClick: action.payload.next_click,
                getPostcode: action.payload.postcode,
                getAddress: action.payload.get_address,
                checkSelect: action.payload.check_select,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "getExtraAddressDetails": {
            return {
                ...state,
                getDetails: action.payload.get_details,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "showManualDiv": {
            return {
                ...state,
                showManualAddress: "show",
                lookUp: false,
                nextClick: true,
                checkSelect: false,
                clickManualLink: action.payload.manual_click,
            };
        }
        case "resetaddress": {
            return {
                ...state,
                getDetails: [],
                getAddress: [],
                showManualAddress: "hide",
                lookUp: true,
                nextClick: false,
                checkSelect: false,
            };
        }
        default:
            return state;
    }
};
const PreviousAddress = ({
    txtPrevaddress,
    txtAddress,
    validation,
    validationMsg,
    clearErrors,
    setError,
    slideChange,
    trigger,
    className,
    backClick,
    splitForm,
    getValues,
    setPrevAddressShow1,
    setPrevAddressShow2,
    blinkEffect1,
    setBlinkEffect1
}) => {
    const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
        usePostCodeAddress();
    const [state, dispatch] = useReducer(postcodeReducer, initialState);
    const [postCodeAddress, setPostCodeAddress] = useState([]);
    const [postCodeEntry, setPostCodeEntry] = useState("0");
    const [lookUpAddress1, setLookUpAddress1] = useState("");
    const [blinkEffect, setBlinkEffect] = useState("");
    const [postCode, setPostCode] = useState();

    const checkValidation = async (e) => {
        e.preventDefault();
        setPostCode(e.target.value);
        dispatch({
            type: "resetaddress",
            get_address: [],
        });
        if(!PostcodeValidation(e.target.value)){
            setError("txtPrevPostCode_1", {
                type: "manual",
                message: "Please Enter Valid Postcode",
            });
        }else{
            const txtPostCode = await trigger("txtPrevPostCode_1");
            let textpostval = e.target.value;
            console.log("val" + textpostval);
            if (txtPostCode && textpostval != "") {
                console.log("ffff");
                await getPostcodeAddress(e.target.value).then(res => {
                    console.log("d" + res.data.status);
                    if (res.data.status === "0") {
                        setError("txtPrevPostCode_1", {
                            type: "manual",
                            message: "Please Enter Valid Postcode",
                        });
                    }
                    if (res.data.length > 0) {
                        dispatch({
                            type: "validationSucess",
                            payload: {
                                lookup: false,
                                next_click: true,
                                postcode: e.target.value,
                                get_address: res.data,
                                check_select: true,
                                manual_address: "hide",
                            },
                        });
                        setBlinkEffect1("");
                        setBlinkEffect('efct_filed');
                    }
                }).catch(
                    function () {
                        setError("txtPrevPostCode_1", {
                            type: "manual",
                            message: "Please Enter Valid Postcode",
                        });
                    }
                );
            }
        }
    };
    const getValue = async (e) => {
        e.preventDefault();
        // document.getElementById("address1").classList.remove("anim_ylw");
        // document.getElementById("NextButton").classList.add("anim_ylw");
        clearErrors([
            "txtPrevHouseNumber_1",
            "txtPrevAddress3_1",
            "txtPrevCounty_1",
            "txtPrevTown_1",
            "txtPrevCountry_1",
            "txtPrevaddress1_1",
        ]);
        if (e.target.value != "") {
            const getData = await getSplitPostcodeAddress(
                e.target.value,
                state.getPostcode
            );
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: getData.data, manual_address: "show" },
            });
            setPostCodeAddress(getData.data);
            clearErrors([
                "txtPrevHouseNumber_1",
                "txtPrevAddress3_1",
                "txtPrevCounty_1",
                "txtPrevTown_1",
                "txtPrevCountry_1",
                "txtPrevaddress1_1",
            ]);
            setPrevAddressShow1('hide');
            setPrevAddressShow2('show');
            setBlinkEffect("");
            document.getElementById("addMore").focus();
        }
        else {
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
            });
            setPostCodeAddress([]);
        }

    };
    const preHandleLookUp = () =>{
        setLookUpAddress1("efct_filed");
    }

    const lookupValidation = async (e) => {
        if(!PostcodeValidation(postCode)){
            setError("txtPrevPostCode_1", {
                type: "manual",
                message: "Please Enter Valid Postcode",
            });
        }else{
            await trigger("txtPrevPostCode_1");
        }
    };

    const checkPostCodeEntry = () => {
        const addressLine1 = document.getElementById("txtPrevHouseNumber_1").value;
        const addressLine2 = document.getElementById("txtPrevAddress3_1").value;
        const town = document.getElementById("txtPrevTown_1").value;
        const county = document.getElementById("txtPrevCounty_1").value;
        const country = document.getElementById("txtPrevCountry_1").value;

        if (
            postCodeAddress.length === 0 ||
            postCodeAddress.Country !== country ||
            postCodeAddress.County !== county ||
            postCodeAddress.Line1 !== addressLine1 ||
            postCodeAddress.Line2 !== addressLine2 ||
            postCodeAddress.Town !== town
        ) {
            setPostCodeEntry("1");
        }
    };

    const HiddenFields = () => (
        <>
            <HiddenField
                name="txtPrevUdprn_1"
                inputId="txtPrevUdprn_1"
                inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
            />
            <HiddenField
                name="txtPrevDeliveryPointSuffix_1"
                inputId="txtPrevDeliveryPointSuffix_1"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
                }
            />
            <HiddenField
                name="txtPrevPz_mailsort_1"
                inputId="txtPrevPz_mailsort_1"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
                }
            />
            <HiddenField
                name="txtPrevStreet_1"
                inputId="txtPrevStreet_1"
                inputValue={
                    state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
                }
            />
            <HiddenField
                name="txtPrevCountry_1"
                inputId="txtPrevCountry_1"
                inputValue={
                    state.getDetails.country !== "#" ? state.getDetails.country : ""
                }
            />
        </>
    );
    return (
        <div className={className}>
            <h5>{txtPrevaddress}</h5>
            <div className="row">
                <div className="col-lg-12   form-group mb-3">
                    <div className="input-group lookup-filed">
                        <AddressTextField
                            type="text"
                            placeholder="Enter Your Postcode"
                            name="txtPrevPostCode_1"
                            id="txtPrevPostCode_1"
                            className="form-control-lg form-style flex-fill   ui-autoComplete-input pac-target-input"
                            dataId="txtPrevPostCode_1"
                            onChange={preHandleLookUp}
                            onBlur={checkValidation}
                            autoComplete="off"
                            validation={validation({
                                minLength: {
                                    value: 5,
                                    message: "Please Enter a Valid Postcode",
                                },
                            })}
                        />
                        <Button
                            type="button"
                            className={`com_bnt flex-fill lookup-btn ${blinkEffect1}`}
                            id="prevPostCode_1"
                            buttonText="Lookup Address"
                            onClick={lookupValidation}
                        />
                    </div>
                    {validationMsg.txtPrevPostCode_1 && (
                        <div className="mb-3 error">
                            <span className="" id="">
                                {validationMsg.txtPrevPostCode_1.message}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div
                style={state.checkSelect ? { display: "block" } : { display: "none" }}
            >
                <AddressSelectBox
                    className={`form-control select-box ${blinkEffect}`}
                    OptionValue={state.getAddress}
                    name="txtPrevaddress1_1"
                    id="txtPrevaddress1_1"
                    onChange={getValue}
                    myRef={validation({ required: "Please select address" })}
                    validationMsg={validationMsg.txtPrevaddress1_1 && (
                        <div className="mb-3 error select1">
                            <span className="" id="">
                                {validationMsg.txtPrevaddress1_1.message}
                            </span>
                        </div>
                    )}
                />
            </div>
            <div
                className="col-12 text-center"
                style={
                    state.getDetails.length === 0
                        ? { display: "block" }
                        : { display: "none" }
                }
            ></div>
            <div className={`row mb-3 ${state.showManualAddress}`}>
                <div className="col-md-12 mb-3 currentAddress" >
                    <div className="form-group row">
                        <div className="col-md-6 mb-3 ">
                            <AddressTextField
                                type="text"
                                className="form-control  form-style"
                                id="txtPrevHouseNumber_1"
                                placeholder="House Name / Number"
                                name="txtPrevHouseNumber_1"
                                autoComplete="off"
                                readonly={true}
                                validation={validation({
                                    required: "Please Enter Address Line 1",
                                })}
                                validationMsg={
                                    validationMsg.txtPrevHouseNumber_1 &&
                                    validationMsg.txtPrevHouseNumber_1.message
                                }
                                value={state.getDetails.line_1}

                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <AddressTextField
                                type="text"
                                className="form-control  form-style"
                                id="txtPrevroute_1"
                                placeholder="Street"
                                name="txtPrevroute_1"
                                autoComplete="off"
                                readonly={true}
                                validation={validation()}
                                validationMsg=""
                                value={state.getDetails.line_2}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6 mb-3  ">
                            <AddressTextField
                                type="text"
                                className="form-control form-style"
                                id="txtPrevTown_1"
                                placeholder="Town"
                                name="txtPrevTown_1"
                                autoComplete="off"
                                readonly={true}
                                validation={validation({
                                    required: "Please Enter Address Line 3",
                                })}
                                validationMsg={
                                    validationMsg.txtPrevTown_1 && validationMsg.txtPrevTown_1.message
                                }
                                value={state.getDetails.town}
                            />
                        </div>
                        <AddressTextField
                            type="hidden"
                            placeholder="Country *"
                            name="txtPrevCountry_1"
                            id="txtPrevCountry_1"
                            className="form-control mb-3 hero-input"
                            dataId="txtPrevCountry_1"
                            autoComplete="off"
                            readonly={true}
                            validation={validation({ required: "Please Enter Country" })}
                            validationMsg={
                                validationMsg.txtPrevCountry_1 && validationMsg.txtPrevCountry_1.message
                            }
                            value={state.getDetails.country}
                        />
                        <div className="col-md-6 mb-3">
                            <AddressTextField
                                type="text"
                                className="form-control  form-style"
                                id="txtPrevcounty_1"
                                placeholder="County"
                                name="txtPrevcounty_1"
                                required=""
                                autoComplete="off"
                                readonly={true}
                                validation={validation({ required: "Please Enter Country" })}
                                validationMsg={
                                    validationMsg.txtPrevcounty_1 && validationMsg.txtPrevcounty_1.message
                                }
                                value={state.getDetails.county}
                            />
                        </div>
                    </div>
                </div>
                {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
            </div>
        </div>
    )
}
export default PreviousAddress;