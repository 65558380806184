import React from "react";
import review from "../../../../../src/assets/img/review.gif"
const Loader = ({show1,show2,show3,show4}) => {
    return (
        <>
            <div className="clearfix " />
            <div id="myDiv" className={`space text-center ${show4}`} style={{}}>
                <img src={review} alt="" style={{ width: '100px', marginTop: '30px' }} />
                <h3 className={`p-0 text11 ${show1}`}>Analysing your application....</h3>
                <div  className={`text12 ${show2} `}>
                    <h5 className="mb-0 pb-0  ">Claim Status: Pending</h5>
                </div>
                <div  className={`text13 ${show3}`}>
                    <h6 className="mb-0 pb-0 mt-3 ">We need a few more details to being processing your claim.</h6>
                </div>
            </div>
        </>
    )
}
export default Loader;