import React from "react";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import { useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParamsContext";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";

const ContactDetails = ({
    title,
    validation,
    validationMsg,
    setError,
    className,
    trigger,
    getValues,
    formSubmit,
    showSign,
    setEmailCheck,
    setPhoneCheck,
}) => {
    const { emailValidation } = useEmailValidation();
    const { phoneValidation } = usePhoneValidation();
    const history = useHistory();
    const { visitorParameters } = useContext(VisitorParamsContext);

    const phoneValidCheck = async () => {
        const values = getValues();
        const phone = values['txtPhone'];
        const email = values['txtEmail'];
        if (phone !== "") {
            const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid);
            setPhoneCheck(getPhoneValidation.data.status_code);
            if (getPhoneValidation.data.status_code === 0) {
                setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Working Phone Number",
                });
                return 0;
            } else if (getPhoneValidation.data.status_code === 2) {
                setError("txtPhone", {
                    type: "manual",
                    message: "Phone Number Already Exist",
                });
                return 0;
            } else if (getPhoneValidation.data.status_code === 3) {
                setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Phone Number",
                });
                return 0;
            }
            else {

                return 1;
            }
        }
    };

    const checkPhoneValid = async () => {
        const values = getValues();
        const phone = values['txtPhone'];
        if (phone.length >= 11) {
            if (phone !== "") {
                const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid);
                setPhoneCheck(getPhoneValidation.data.status_code);
                if (getPhoneValidation.data.status_code === 0) {
                    setError("txtPhone", {
                        type: "manual",
                        message: "Please Enter Valid Working Phone Number",
                    });
                    return 0;
                } else if (getPhoneValidation.data.status_code === 2) {
                    setError("txtPhone", {
                        type: "manual",
                        message: "Phone Number Already Exist",
                    });
                    return 0;
                } else if (getPhoneValidation.data.status_code === 3) {
                    setError("txtPhone", {
                        type: "manual",
                        message: "Please Enter Valid Phone Number",
                    });
                    return 0;
                }
                else {

                    return 1;
                }
            }
        } else {
            setPhoneCheck(0);
        }
    }

    const emailValidCheck = async () => {
        const values = getValues();
        const email = values['txtEmail'];
        const phone = values['txtPhone'];
        if (email !== "") {
            const getEmailValidation = await emailValidation(email,visitorParameters.visitor_parameters.uuid)
            setEmailCheck(getEmailValidation.data.status_code);
            if (getEmailValidation.data.status_code === 1) {
                return 1;
            } else {
                setError("txtEmail", {
                    type: "manual",
                    message: "Please Enter Valid Email",
                });
            }
        }
    };
    return (
        <>
            <div className="col-lg-8  col-md-12 col-sm-12  ">
                <div className="form-row ">
                    <div className="col-md-12">
                        <label htmlFor="inputEmail4" className="text-custom ">
                            <h5>{title}</h5>
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="input-group mb-3">
                            <Email
                                className="form-style form-control form-control-lg frm-img"
                                id="txtEmail"
                                name="txtEmail"
                                placeholder="Email Address"
                                describedby="basic-addon3"
                                defaultValue=""
                                onBlur={emailValidCheck}
                                onPaste={(e) => {
                                    e.preventDefault()
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault()
                                    return false;
                                }}
                                onKeyPress={(e) => e.key === " " && e.preventDefault()}
                                validation={validation({
                                    required: "Please Enter Valid Email Address",   
                                })}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text inpt-img" id="basic-addon3">
                                    <img className="img-height" src="assets/img/privacy.png" alt="" />
                                </span>
                            </div>
                            {validationMsg.txtEmail && (
                                <div className="mb-3 error">
                                    <span className="" id="">
                                        {validationMsg.txtEmail.message}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className=" col-lg-6 col-md-12 col-sm-12">
                        <div className=" input-group mb-3">
                            <Telephone
                                className="form-style form-control form-control-lg frm-img"
                                name="txtPhone"
                                id="txtPhone"
                                placeholder="Telephone"
                                describedby="basic-addon2"
                                defaultValue=""
                                onPaste={(e) => {
                                    e.preventDefault()
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault()
                                    return false;
                                }}
                                onKeyUp={checkPhoneValid}
                                onBlur={phoneValidCheck}
                                onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                                maxlength="11"
                                validation={validation({
                                    pattern: {
                                        value: /^[0-9]*$/i,
                                        message: "Please Enter Valid Phone Number",
                                    },
                                    required: "Please Enter Phone Number",
                                })}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text inpt-img" id="basic-addon2">
                                    <img className="img-height" src="assets/img/privacy.png" alt="" />
                                </span>
                            </div>
                            {validationMsg.txtPhone && (
                                <div className="mb-3 error">
                                    <span className="" id="">
                                        {validationMsg.txtPhone.message}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default ContactDetails;