import React, { useEffect, useReducer, useState, useRef } from "react";
import ModalQuestionnaire from "../../Includes/Layouts/common/ModalQuestionnaire";
import {
    questionnaire1,
    questionnaire2,
    questionnaire3,
    questionnaire4,
    questionnaire5,
    questionnaire6,
    questionnaire8,
    questionnaire9,
} from "../../../Constants/Questions";
import { useContext } from "react";
import logo from "../../../assets/img/Clear-LegalMarketingCMYK.png";
import VisitorParamsContext from "../../../Contexts/VisitorParamsContext";
import { useUserInfo } from "../../../Hooks/useUserInfo";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CheckUUID } from "../../../Utility/CheckUUID";
import QuestionRadioSlide from "../../Includes/Layouts/CL_LS_V3/QuestionRadioSlide";
import QuestionSelectSlide from "../../Includes/Layouts/CL_LS_V3/QuestionSelectSlide";
import QuestionInputSlide from "../../Includes/Layouts/CL_LS_V3/QuestionInputSlide";
import QuestionnaireCheckboxSlide from "../../Includes/Layouts/CL_LS_V3/QuestionnaireCheckboxSlide";
import QuestionTextAreaSlide from "../../Includes/Layouts/CL_LS_V3/QuestionTextAreaSlide";
import Footer from "../../Includes/Layouts/common/Footer";
import { useQuestionnaire } from "../../../../src/Hooks/useQuestionnaire";
import { queryString } from "../../../Utility/QueryString";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import GTMDataLayer from "../../Includes/Layouts/common/GTMDataLayer";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

function Questionnaire() {
    const { isCheck } = CheckUUID();
    isCheck();
    const { register, errors, clearErrors, setError, trigger, getValues } =
        useForm({
            mode: "onSubmit",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });
    const initialState = {
        showQuestionSlide1: "d-none",
        showQuestionSlide2: "d-none",
        showQuestionSlide3: "d-none",
        showQuestionSlide4: "d-none",
        showQuestionSlide5: "d-none",
        showQuestionSlide6: "d-none",
        showQuestionSlide8: "d-block",
        showQuestionSlide9: "d-none",
    };
    const QuestionReducer = (state, action) => {
        switch (action.type) {
            case "nextQuestionSlide": {
                if (action.payload.clickedSlide.slide == "question_1") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_2") {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide9: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_9") {
                    return {
                        ...state,
                        showQuestionSlide9: "d-none",
                        showQuestionSlide3: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "next3") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide4: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "next4") {
                    return {
                        ...state,
                        showQuestionSlide4: "d-none",
                        showQuestionSlide5: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "next5") {
                    return {
                        ...state,
                        showQuestionSlide5: "d-none",
                        showQuestionSlide6: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "questionnaire6") {
                    return {
                        ...state,
                        showQuestionSlide6: "d-none",
                        showQuestionSlide7: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_8") {
                    return {
                        ...state,
                        showQuestionSlide8: "d-none",
                        showQuestionSlide1: "d-block",
                    };
                } else {
                    return {
                        ...state,
                    };
                }
            }
            case "backQuestionSlide": {
                if (action.payload.prevSlide.slide == "back1") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-none",
                        showQuestionSlide8: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back2") {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide1: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back3") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide9: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back4") {
                    return {
                        ...state,
                        showQuestionSlide4: "d-none",
                        showQuestionSlide3: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back5") {
                    return {
                        ...state,
                        showQuestionSlide5: "d-none",
                        showQuestionSlide4: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back6") {
                    return {
                        ...state,
                        showQuestionSlide6: "d-none",
                        showQuestionSlide5: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back7") {
                    return {
                        ...state,
                        showQuestionSlide7: "d-none",
                        showQuestionSlide6: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back9") {
                    return {
                        ...state,
                        showQuestionSlide9: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                } else {
                    return {
                        ...state,
                    };
                }
            }
        }
    };

    const [clickedSlide, setClickedSlide] = useState();
    const [popShow, setPopShow] = useState(false);
    const history = useHistory();
    const userQuestionnire = useRef(null);
    const [prevSlide, setPrevSlide] = useState();
    const [quest1Ans, setQuest1Ans] = useState(null);
    const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('CL_LS_V6', 'CL_LS_V6/questionnaire');
    const { visitorParameters } = useContext(VisitorParamsContext);
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const [completedQuestArr, setCompletedQuestArr] = useState([]);
    const [state, dispatch] = useReducer(QuestionReducer, initialState);
    const { updateUserQualifiedStatus } = useUserInfo();
    const [completePercentage, setCompletePercentage] = useState("15");
    const { isLoading, SaveQuestionnaire, SaveQuestionnaireSkip } =
        useQuestionnaire();
    const [showEndYear, setShowEndYear] = useState(false);
    useEffect(() => {
        if (clickedSlide) {
            clickedSlide.slide == "question_8"
                ? setCompletePercentage("30")
                : clickedSlide.slide == "question_1"
                    ? setCompletePercentage("40")
                    : clickedSlide.slide == "question_2"
                        ? setCompletePercentage("50")
                        : clickedSlide.slide == "question_9"
                            ? setCompletePercentage("60")
                            : clickedSlide.slide == "next3"
                                ? setCompletePercentage("70")
                                : clickedSlide.slide == "next4"
                                    ? setCompletePercentage("100")
                                    : setCompletePercentage("100");
            dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
        }
    }, [clickedSlide]);
    useEffect(() => {
        if (prevSlide) {
            prevSlide.slide == "back1"
                ? setCompletePercentage("15")
                : prevSlide.slide == "back2"
                    ? setCompletePercentage("30")
                    : prevSlide.slide == "back9"
                        ? setCompletePercentage("40")
                        : prevSlide.slide == "back3"
                            ? setCompletePercentage("50")
                            : prevSlide.slide == "back4"
                                ? setCompletePercentage("60")
                                : prevSlide.slide == "back5"
                                    ? setCompletePercentage("70")
                                    : prevSlide.slide == "back6"
                                        ? setCompletePercentage("80")
                                        : setCompletePercentage("100");
            dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
        }
    }, [prevSlide]);

    const getLabelValue = (questionId, value) => {
        let questionnaire = {};
        switch (questionId) {
            case "1":
                questionnaire = questionnaire1;
                break;
            case "2":
                questionnaire = questionnaire2;
                break;
            case "3":
                questionnaire = questionnaire3;
                break;
            case "4":
                questionnaire = questionnaire4;
                break;
            case "5":
                questionnaire = questionnaire5;
                break;
            case "6":
                questionnaire = questionnaire6;
                break;
            case "8":
                questionnaire = questionnaire8;
                break;
            case "9":
                questionnaire = questionnaire9;
                break;
        }

        let labelValue = questionnaire.answers
            .filter((obj) => obj.value === value)
            .map((obj) => obj.label);

        return labelValue;
    };

    const storeQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            queryString,
            formdata
        );
    };
    const handleCloseQuestionModal = () => {
        setPopShow(false);
    }

    const storeQuestionnireSkip = async (type) => {
        const response = await SaveQuestionnaireSkip(
            visitorParameters.visitor_parameters.uuid,
            type
        );
    };
    const slideChange = async (e, fieldName) => {
        gtmDataLayer(e.target.name, e.target);
        // e.preventDefault();
        window.scrollTo(0, 0);
        if (e.target.value == "4" && fieldName == "question_2") {
            setPopShow(true);
        } else {
            if (e.target.name == "question_9" && e.target.value == "29") {
                setShowEndYear(true);
            }
            if (e.target.name == "question_9" && e.target.value == "28") {
                setShowEndYear(false);
            }

            if (e.target.value === "131") {
                var currentSlide = "questionnaire6";
            } else if (e.target.value === "skip4") {
                var currentSlide = e.target.name;
                setClickedSlide({ slide: currentSlide });
                storeQuestionnireSkip("account_number");
                return;
            }
            else if (e.target.value === "skip6") {
                storeQuestionnireSkip('question_6');
                history.push("/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName);
                return;
            }
            else {
                var currentSlide = e.target.name;
            }
            const form = userQuestionnire.current;
            const values = getValues();
            const formData = values;
            setClickedSlide({ slide: currentSlide });
            var question_id = e.target.dataset.question_id;
            var input_questions = ["3", "4", "6"];
            var optionId = input_questions.includes(question_id) ? "" : question_id == "5" ? fieldName : form[fieldName].value;
            let inputAnswer = input_questions.includes(question_id) ? form[fieldName].value : "";
            let labelValue = "";
            let isQualified = (e.target.name == "next5" && formData.question_1 == "1") ? "0" : '';
            if (question_id == "5") {
                labelValue = getLabelValue(question_id, fieldName);
            } else {
                labelValue = getLabelValue(question_id, form[fieldName].value);
            }
            let question_data = {
                question_id: question_id,
                option_id: optionId,
                answer_text: labelValue.toString(),
                input_answer: inputAnswer,
                is_qualified: isQualified,
            };
            ///save questionData in localstorage
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = {};
            var newEntries =
                existingEntries == null ? (existingEntries = {}) : existingEntries;
            Object.assign(newEntries, {
                [question_id]: optionId ? optionId : inputAnswer,
            });
            localStorage.setItem("questionData", JSON.stringify(newEntries));
            ///send questionData to api
            storeQuestionnire(question_data);
            if (e.target.name == "next5") {
                if (formData.question_1 != "1") {
                    history.push(
                        "/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName
                    );
                } else {
                    history.push(
                        "/unqualified?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=CL_LS_V4"
                    );
                }

            }
        }
    };

    const gtmDataLayer = (current_question, element = null) => {
        let question = "";
        let answer = "";
        switch (current_question) {
            case "question_8":
                question = "Employment Status";
                break;
            case "question_1":
                question = "How many times increased";
                break;
            case "question_2":
                question = "Claim against lender";
                break;
            case "question_9":
                question = "Still active";
                break;
            case "next3":
                question = "received years";
                break;
            case "next4":
                question = "Account number";
                break;
            case "next5":
                question = "Tick that applies";
                break;
            case "next6":
                question = "lending was unaffordable";
                break;
        }
        GTMDataLayer({
            question: question,
            answer: answer,
        });
    };

    const previousSlide = (e) => {
        var previous_slide = e.target.name;
        console.log(e.target.name);
        setPrevSlide({ slide: previous_slide });
    };
    const formOnSubmit = (e) => {
        e.preventDefault();
    };
    return (
        <>
            <GetVisitorsParams />
            <div className="Questionnaire CL_LS_V3">
                <div className="clearfix" />
                <section className="sec-nav-bars">
                    <div className="container text-center">
                        <a href="" className="logo">
                            <img src={logo} alt="" />
                        </a>
                    </div>
                </section>
                <section className="main-start bg text-center " />
                <section>
                    <div className="container">
                        {/* SLIDE 1 */}
                        <div className={`section-slides form-wraper text-center slide1`}>
                            <div>
                                <div
                                    className="progress progrss"
                                    data-percentage={completePercentage}
                                >
                                    <span className="progress-left">
                                        <span className="progress-bar" />
                                    </span>
                                    <span className="progress-right">
                                        <span className="progress-bar" id="progressBar" />
                                    </span>
                                    <div className="progress-value">
                                        <div id="progressBar_content">
                                            <span className="perce">{completePercentage}%</span>
                                            <br />
                                            <span>completed</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form ref={userQuestionnire} onSubmit={formOnSubmit}>
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide1}
                                    questionnaire={questionnaire1}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={true}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide2}
                                    questionnaire={questionnaire2}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={true}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                <QuestionSelectSlide
                                    showQuestionSlide={state.showQuestionSlide3}
                                    questionnaire={questionnaire3}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={true}
                                    showEndYear={showEndYear}
                                />
                                <QuestionInputSlide
                                    showQuestionSlide={state.showQuestionSlide4}
                                    questionnaire={questionnaire4}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={true}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                    userQuestionnire={userQuestionnire}
                                    setError={setError}
                                />
                                <QuestionnaireCheckboxSlide
                                    showQuestionSlide={state.showQuestionSlide5}
                                    questionnaire={questionnaire5}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={true}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                {/* <QuestionTextAreaSlide
                  showQuestionSlide={state.showQuestionSlide6}
                  questionnaire={questionnaire6}
                  previousSlide={previousSlide}
                  slideChange={slideChange}
                  backBtn={true}
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  userQuestionnire={userQuestionnire}
                /> */}
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide8}
                                    questionnaire={questionnaire8}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide9}
                                    questionnaire={questionnaire9}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={true}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                            </form>
                        </div>
                    </div>
                </section>
                <ModalQuestionnaire
                    content="Unfortunately we cannot process any cases based on this criteria"
                    popDisplay={popShow}
                    handleClose={handleCloseQuestionModal}
                />
                <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next" />
                <Footer />
            </div>
            {/*-----------------------------*/}
        </>
    );
}

export default Questionnaire;
