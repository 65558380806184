import React, { useContext, useState, useRef,useEffect } from "react";
import { CheckUUID } from "../../../Utility/CheckUUID";
import VisitorParamsContext from "../../../Contexts/VisitorParamsContext";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import InputButton from "../../UI/InputButton";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import { queryString } from "../../../Utility/QueryString";
import { Link, useHistory } from "react-router-dom";
import Header from "../../Includes/Layouts/common/Header";
import Footer from "../../Includes/Layouts/common/Footer";
import logoWhite from "../../../assets/img/Clear-LegalMarketingCMYK.png";
import { useQuestionnaire } from "../../../Hooks/useQuestionnaire";
import GTMDataLayer from "../../Includes/Layouts/common/GTMDataLayer";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

const DocumentUpload = () => {
  const { isCheck } = CheckUUID();
  const history = useHistory();
  const {DynamicRouteSplitName,DynamicRouteNextPage}   = DynamicRoutingSplit('','id-upload');
  const isCheckResponse = isCheck();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { saveIdUpload, isLoading } = useIdUpload();
  const [selectedFile, setSelectedFile] = useState();
  const [isInValidFile, setIsInValidFile] = useState(false);
  const [isInValidCredit, setIsInValidCredit] = useState(false);
  const [isInValidBank, setIsInValidBank] = useState(false);
  const [isInValidLending, setIsInValidLending] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [base64Data, setBase64Data] = useState([]);
  const [creditFile,setCreditFile] = useState('');
  const [bankStatement,setBankStatement] = useState('');
  const [lendingProof,setLendingProof] = useState('');
  const { SaveQuestionnaireSkip } = useQuestionnaire();
   
  const uploadRef = useRef();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

	const changeFilesHandler = (e) => {
		setSelectedFile(e.target.files[0]);
		let imageFile = e.target.files[0];
		if (imageFile) {
      setIsInValidFile(false);
      if(e.target.name == "credit_report"){
        setCreditFile(imageFile.name);
      }else if(e.target.name == "bank_statement"){
        setBankStatement(imageFile.name);
      }else if(e.target.name == "lending_proof"){
        setLendingProof(imageFile.name);
      }
			setIsFilePicked(true);
			let extension = e.target.files[0].type;
			let imgsize = e.target.files[0].size;

			// let allowedExtensions = ['image/jpeg', 'image/png'];
			// if (allowedExtensions.indexOf(extension) === -1) {
			// 	if(e.target.name == "credit_report"){
      //     setIsInValidCredit(true);
      //   }else if(e.target.name == "bank_statement"){
      //     setIsInValidBank(true);
      //   }else if(e.target.name == "lending_proof"){
      //     setIsInValidLending(true);
      //   }
			// 	setErrorMessage({ txt: 'The uploaded ID type should be an image in jpeg,png or jpg' });
			// } else 
      if (imgsize > 1024 * 1024 * 20) {
				if(e.target.name == "credit_report"){
          setIsInValidCredit(true);
        }else if(e.target.name == "bank_statement"){
          setIsInValidBank(true);
        }else if(e.target.name == "lending_proof"){
          setIsInValidLending(true);
        }
				setErrorMessage({ txt: 'Please choose a file less than 20mb' });
			} else {
        setIsInValidCredit(false);
        setIsInValidBank(false);
        setIsInValidLending(false);
			}

			if (imageFile) {
        let docName= e.target.name;
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (event) => {
          let data = {documentName : docName , fileData: event.target.result ,docType : extension }
          setBase64Data(uploads => [...uploads,data] );
        }
			}
		} else {
			setIsInValidFile(true);
			setIsFilePicked(false);
		}
	};

  const skipDocumentUpload=()=>{
    storeQuestionnireSkip('id_upload');
    history.push("/"+DynamicRouteNextPage+"?uuid="+visitorParameters.visitor_parameters.uuid +  "&splitName="+ DynamicRouteSplitName);
  }

  const storeQuestionnireSkip = async (type) => {
    const response = await SaveQuestionnaireSkip(
      visitorParameters.visitor_parameters.uuid,
      type
    );
    
  };

  const handleSubmit = async (event) => { 
    event.preventDefault();
    let uploadData = base64Data;
    if(isFilePicked){
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        'user_docs_store'
      );
      
      GTMDataLayer({
        question: "Documents"
      });

      if(response.data.status == "Success"){
        history.push("/"+DynamicRouteNextPage+"?uuid="+visitorParameters.visitor_parameters.uuid +  "&splitName="+ DynamicRouteSplitName);
      }
    }else{
      setIsInValidFile(true);
      setErrorMessage({ txt: 'Please upload a file' });
    }
  }
  // const uuid = getUuid();
  return (
    <>
      <GetVisitorsParams />
      <div className="CL_LS_V3 thankyou">
        <div className="bg">
          <div className="clearfix"></div>
          <Header />
          <section className="main-start bg text-center "></section>
          <section>
            <div className="container thankyou">
              <div className="text-center">
                <h1>
                  Congratulations! We have received your claim(s) and were
                  nearly ready to submit your complaints!
                </h1>
                <h1>What happens next?</h1>
                <p>
                  In most cases we will now request further details of your loan
                  or credit facility from your lender to help us put together
                  your complaint.
                </p>
                <p>
                  However, in the meantime, we urgently need some supporting
                  documents from you. This information will greatly improve your
                  chances of success! See below what we need. The quicker you
                  send us as much of this information as possible, the quicker
                  we can get your complaint submitted.
                </p>
                <h1 className="mb-5">What documents do we need from you?</h1>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                  <div className="card border-0 card-dx h-100  ">
                    <div className="card-header">
                      <h3> A copy of your credit report</h3>
                    </div>
                    <div className="card-body text-center">
                      <p>
                        If you don't already have access to your credit file,
                        this can be obtained by signing up to the following
                        company's free trial:
                      </p>
                      <ul className="  my-4 text-start ms-3">
                        <li>
                          {" "}
                          Check My File* - <br />
                          <a target="_blank" href="https://www.checkmyfile.com">
                            https://www.checkmyfile.com
                          </a>
                        </li>
                      </ul>
                      <p>
                        {" "}
                        This report provides us with all the information we
                        require. They offer a 30 day free trial but you can
                        cancel at any time to avoid being charged thereafter.{" "}
                      </p>
                      <h4>Click below to upload your Credit Report</h4>
                    </div>
                    <div className="card-footer">
                      <div className="input-group my-3 justify-content-between">
                        <div className="choosefile flex-fill">
                          {creditFile != '' ? creditFile : "choose File"}
                          <input
                            type="file"
                            name="credit_report"
                            className="form-control"
                            placeholder=" "
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            onChange={changeFilesHandler}
                            onClick={e => (e.target.value = null)}
                          />
                        </div>
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect02"
                        >
                          Browse
                        </label>
                      </div>
                      { isInValidCredit && <span className="error_msg">{errorMessage.txt}</span>}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                  <div className="card border-0 card-dx h-100 ">
                    <div className="card-header">
                      <h3> Bank statements</h3>
                    </div>
                    <div className="card-body text-center">
                      <p>
                        {" "}
                        These should cover the 3 months prior to you first
                        taking the credit/loan(s). This is to review your
                        affordability leading up to and during the application
                        process.
                      </p>
                      <p>
                        {" "}
                        For example if you first took the loan or credit
                        facility in May 2022 then we need to see bank statements
                        for February, March and April 2022.{" "}
                      </p>
                      <h4>Click below to upload your Bank Statements</h4>
                    </div>
                    <div className="card-footer">
                      <div className="input-group my-3 justify-content-between">
                        <div className="choosefile flex-fill">
                          {bankStatement != '' ? bankStatement : "choose File"}
                          <input
                            type="file"
                            className="form-control"
                            name="bank_statement"
                            placeholder=" "
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            onChange={changeFilesHandler}
                            onClick={e => (e.target.value = null)}
                          />
                        </div>
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect02"
                        >
                          Browse
                        </label>
                        { isInValidBank &&	<span className="error_msg">{errorMessage.txt}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                  <div className="card border-0 card-dx  h-100 ">
                    <div className="card-header">
                      <h3> Proof of lending</h3>
                    </div>
                    <div className="card-body text-center">
                      <p>
                        {" "}
                        The best form of proof is your signed finance agreement.
                        If you cannot provide this, please look for emails,
                        letters or screen shots containing any account
                        numbers/agreement numbers. We recommend putting your
                        lender name into the search function on your inbox and
                        junk mail folders or attempt to log onto your lender's
                        website and obtain the agreement details from there.
                      </p>
                      <h4>Click below to upload your Lending Proof</h4>
                    </div>
                    <div className="card-footer">
                      <div className="input-group my-3 justify-content-between">
                        <div className="choosefile flex-fill">
                          {lendingProof != '' ? lendingProof : "choose File"}
                          <input
                            type="file"
                            className="form-control"
                            name="lending_proof"
                            placeholder=" "
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            onChange={changeFilesHandler}
                            onClick={e => (e.target.value = null)}
                          />
                        </div>
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect02"
                        >
                          Browse
                        </label>
                        {isInValidLending && <span className="error_msg">{errorMessage.txt}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isInValidFile &&
              <div className=" mb-4">
                 <span className="btn btn-danger1 w-100">{errorMessage.txt}</span>
              </div>}
              <div className="mt-3 mb-3">
                <InputButton
                  name="formSubmitBtn"
                  className="btn btn-success w-100 btn-docsubmit"
                  id="formSubmitBtn"
                  value="Submit Documents"
                  btnType="submit"
                  onClick={handleSubmit}
                  style={{ float: "none" }}
                />
              </div>
              <div className="text-center mb-3">
              {/* <Link to={"/thankyou?uuid="+visitorParameters.visitor_parameters.uuid} className="skip-here fs-5 fw-semibold" onClick={}><u>Skip for now {">>"}</u></Link> */}
              <button className="skip_button" onClick={skipDocumentUpload} >
                Skip
            {/* {" "}
            Skip for now {">>"} */}
          </button>
              </div>
              
              <h1 className="text-center mb-5">
                Why do we need these documents?
              </h1>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                  <div className="card card-document p-3 rounded-5 h-100">
                    <h3>Credit Report</h3>
                    <p>
                      {" "}
                      Your Credit report will help us prove the loan/credit
                      facility wasunaffordable. It may also uncover new lenders
                      for us to investigate, if so, we will contact you to
                      discuss whether you would also like to pursue a complaint
                      against those. The more lenders we investigate, the more
                      chance of refunds for you!{" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                  <div className="card card-document p-3 rounded-5  h-100">
                    <h3>Bank Statements</h3>
                    <p>
                      {" "}
                      Bank statements will help us prove that your loan/credit
                      facility was not affordable at the time of your lending.
                      This will increase your chances of a successful claim and
                      may also increase the amount of refund we can obtain.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                  <div className="card card-document p-3 rounded-5  h-100">
                    <h3>Proof of Lending</h3>
                    <p>
                      {" "}
                      Proof of lending is very important to help your lender
                      locate your account. If you can provide this at the outset
                      it is likely to greatly reduce the length of your claim it
                      takes to settle your complaint.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <p className="mt-3">
                {" "}
                Please note, we will still assess your claim if you do not
                provide any additional documents requested. However, we strongly
                advise you to provideas much information as possible, to
                maximise on your chances of success and the speed of settlement!{" "}
              </p>
              <p>
                {" "}
                * We receive a fee from Credit Reporting Agency Limited when you
                subscribe to their free trial. Click{" "}
                <a target="_blank" href="https://dev.paydayreclaimuk.co.uk/pre-contract-information">
                  here
                </a>{" "}
                for more details.{" "}
              </p>
              <p>
                {" "}
                If you have any questions, please feel free to email us on{" "}
                <a href="mailto:enquiries@clearlegalmarketing.co.uk">enquiries@clearlegalmarketing.co.uk</a>
              </p>
              <p> Thanks for choosing Clear Legal Marketing. </p>
              <p> Thank you </p>
              <img src={logoWhite} alt="" />
            </div>
          </section>
          <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next"></section>
          <Footer/>
        </div>
      </div>
    </>
  );
};

export default DocumentUpload;
