import React, { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryStringProvider } from "./Contexts/QueryStringContext";
import { VisitorParamsProvider } from "./Contexts/VisitorParamsContext";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Questionnaire from "./Components/Pages/CL_LS_V3/Questionnaire";
import QuestionnaireV4 from "./Components/Pages/CL_LS_V4/Questionnaire";
import QuestionnaireV5 from "./Components/Pages/CL_LS_V5/Questionnaire";
import QuestionnaireV6 from "./Components/Pages/CL_LS_V6/Questionnaire";
import QuestionnaireV7 from "./Components/Pages/CL_LS_V7/Questionnaire";
import QuestionnaireV8 from "./Components/Pages/CL_LS_V8/Questionnaire";
import Preview from "./Components/Pages/Preview";
import Proceed from "./Components/Pages/Proceed";
import Unqualified from "./Components/Pages/Unqualified";
import DocumentUpload from "./Components/Pages/CL_LS_V3/DocumentUpload";
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import CL_LS_V3 from "./Components/Pages/Splits/CL_LS_V3";
import CL_LS_V4 from "./Components/Pages/Splits/CL_LS_V4";
import CL_LS_V5 from "./Components/Pages/Splits/CL_LS_V5";
import CL_LS_V6 from "./Components/Pages/Splits/CL_LS_V6";
import CL_LS_V7 from "./Components/Pages/Splits/CL_LS_V7";
import CL_LS_V8 from "./Components/Pages/Splits/CL_LS_V8";
import Signature from "./Components/Pages/CL_LS_V3/Signature";
import Thankyou from "./Components/Pages/CL_LS_V3/Thankyou"
import ComplaintsProcedure from "./Components/Includes/Layouts/common/ComplaintsProcedure";
import PrivacyPolicy from "./Components/Includes/Layouts/common/PrivacyPolicy";
import Terms_and_Conditions from "./Components/Includes/Layouts/common/Terms_and_Conditions";
import PreContractInfo from "./Components/Includes/Layouts/common/PreContractInfo";
import SignLoader from "./Components/Includes/Layouts/CL_LS_V3/SignLoader";
import SignatureV2 from "./Components/Includes/Layouts/common/SignatureV2";
import SignatureV5 from "./Components/Includes/Layouts/common/SignatureV5";
import QuestionnaireFollowup from "./Components/Pages/Followup/QuestionnaireFollowup";
import FollowupSignature from "./Components/Pages/Followup/FollowupSignature";
import FollowupThankyou from "./Components/Pages/Followup/FollowupThankyou";
import FollowupUnqualified from "./Components/Pages/Followup/FollowupUnqualified";
import VulnerablePolicy from "./Components/Includes/Layouts/common/VulnerablePolicy";
import SignatureV8 from "./Components/Includes/Layouts/common/SignatureV8";
import TestService from "./Components/Pages/TestService";
const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path={["/CL_LS_V3"]} component={CL_LS_V3}/>
              <Route exact path={["/CL_LS_V4"]} component={CL_LS_V4}/>
              <Route exact path={["/CL_LS_V5"]} component={CL_LS_V5}/>
              <Route exact path={["/CL_LS_V7"]} component={CL_LS_V7}/>
              <Route exact path={["/CL_LS_V8"]} component={CL_LS_V8}/>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/CL_LS_V3/questionnaire"]} component={Questionnaire} />
              <Route exact path={["/CL_LS_V4/questionnaire"]} component={QuestionnaireV4} />
              <Route exact path={["/CL_LS_V5/questionnaire"]} component={QuestionnaireV5} />
              <Route exact path={["/CL_LS_V6"]} component={CL_LS_V6}/>
              {/* <Route exact path={["/CL_LS_V3/questionnaire"]} component={Questionnaire} /> */}
              <Route exact path={["/CL_LS_V7/questionnaire"]} component={QuestionnaireV7} />
              {/* <Route exact path={["/CL_LS_V4/questionnaire"]} component={QuestionnaireV4} /> */}
              <Route exact path={["/CL_LS_V6/questionnaire"]} component={QuestionnaireV6} />
              <Route exact path={["/CL_LS_V8/questionnaire"]} component={QuestionnaireV8} />
              <Route exact path={["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={"/followup/unqualified"} component={FollowupUnqualified} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/followup/thankyou"} component={FollowupThankyou} />
              <Route exact path={["/id-upload"]} component={DocumentUpload} />
              <Route exact path={["/preview"]} component={PreviewLoa} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/ADV_1"]} component={ADV_1} />
              <Route exact path={["/signloadeer"]} component={SignLoader} />
              <Route exact path={["/ADV1/contact"]} component={ADV1_Contact}/>
              <Route exact path={["/Signature"]} component={Signature}/>
              <Route exact path={["/ComplaintsProcedure"]} component={ComplaintsProcedure}/>
              <Route exact path={["/PrivacyPolicy"]} component={PrivacyPolicy}/>
              <Route exact path={["/PreContractInfo"]} component={PreContractInfo}/>
              <Route exact path={["/Terms_and_Conditions"]} component={Terms_and_Conditions}/>
              <Route exact path={["/Signature_V2"]} component={SignatureV2}/>
              <Route exact path={["/Signature_V5"]} component={SignatureV5}/>
              <Route exact path={["/Signature_V8"]} component={SignatureV8}/>
              <Route exact path={["/followup"]} component={QuestionnaireFollowup}/>
              <Route exact path={["/followup/signature"]} component={FollowupSignature}/>
              <Route exact path={["/VulnerablePersonsPolicy"]} component={VulnerablePolicy}/>
              <Route exact path={["/webhook-check"]} component={TestService}/>
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
