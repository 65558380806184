import { Modal } from "react-bootstrap";
import React, { useRef, useState, useReducer, useContext, useEffect } from "react";
import {
    BrowserView,
    MobileOnlyView,
    TabletView,
    deviceType,
    isIPad13,

} from "react-device-detect";
import logo from "../../../../assets/img/Clear-LegalMarketingCMYK.png";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../UI/Button";
import VisitorParamsContext from "../../../../Contexts/VisitorParamsContext";
import { queryString } from "../../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useSignature } from "../../../../Hooks/useSignature";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import Footer from "../../../Includes/Layouts/common/Footer";
import CheckBox from "../../../UI/CheckBox";
import SignInfo from "../../../Includes/Layouts/CL_LS_V3/SignInfo";
import GTMDataLayer from "../../../Includes/Layouts/common/GTMDataLayer";
import * as EnvConstants from "../../../../Constants/EnvConstants"
// import SignLoader from "../../../Includes/Layouts/CL_LS_V3/SignLoader";
import { DynamicRoutingSplit } from "../../../../Utility/DynamicRoutingSplit";
import Pdf1 from "../../../../assets/pdf/BFOA.pdf";
import Pdf2 from "../../../../assets/pdf/confirmation-statement.pdf";
import Pdf3 from "../../../../assets/pdf/FOA.pdf";

const initialState = {
    isChecked: false,
    isAgreed: false,
    isCanvasEmpty: false,
    isValid: false,
};
const signatureReducer = (state, action) => {
    switch (action.type) {
        case "signPadChange": {
            return {
                ...state,
                isCanvasEmpty: action.payload.value,
            };
        }
        case "privacyAgreed": {
            return {
                ...state,
                isAgreed: action.payload.value,
            };
        }
        case "validSignature": {
            return {
                ...state,
                isValid: action.payload.value,
            };
        }
        default:
            return state;
    }
};

const SignatureModalV6 = ({ modalDisplay, handleClose,nextPage,field,splitName,formData,page_name,signSubmit }) => {
    // const { isCheck } = CheckUUID();
    // isCheck();
    const query = new URLSearchParams(window.location.search);
    const [showPrivacy, setshowPrivacy] = useState(false);
    const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(splitName, 'CL_LS_V6/questionnaire');
    const [state, dispatch] = useReducer(signatureReducer, initialState);
    const { saveSignature, isLoading } = useSignature();
    const [isChecked, setIsChecked] = useState(false);
    const history = useHistory();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [loading, setLoading] = useState(true);
    const [popupLoading, setPopupLoading] = useState(false);
    const signPadRef = useRef();
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    const message_type = "";
    const [imageShow, setImageShow] = useState('show');

    // const signSubmitValue = async() =>{
    //     alert("dddd");
    //   let sign =  signSubmit;
    // }
    const handleClick = async () => {
        try {
            const signPad = signPadRef.current;
            if (signPad.isEmpty()) {
                return dispatch({ type: "signPadChange", payload: { value: true } });
            }
            if (!isChecked) {
                return dispatch({ type: "privacyAgreed", payload: { value: true } });
            }
            const rpdata = signPad.toData();
           // console.log(rpdata);
           
            var signLength = 0;
            for (var i = 0; i < rpdata.length; i++) {
                signLength += Object.keys(rpdata[i]).length;
            }

            if (signLength > 13) {
                // const response = await saveSignature(
                //     signPad.getCanvas().toDataURL("image/png"),
                //     visitorParameters.visitor_parameters,
                //     formParameters,
                //     visitorParameters.data,
                //     queryString,
                //     message_type,
                //     page_name
                // );
                GTMDataLayer({
                    question: "Signature"
                });
                    const formdata = JSON.parse(localStorage.getItem("formData"));
                    if (formdata === undefined || formdata === null || formdata == '') {
                        localStorage.setItem("formData", JSON.stringify(formdata));
                    } else {
                        formdata.signature_data = signPad.getCanvas().toDataURL("image/png");
                        let currentSignTime = new Date().toLocaleString();
                        formdata.current_sign_time = currentSignTime.replace(/,/g, '');
                        localStorage.setItem("formData", JSON.stringify(formdata));
                    }
                }
             else {
                return dispatch({ type: "validSignature", payload: { value: true } });
            }
        } catch (e) {
            console.warn(e);
        }
    };
    // const signSubmit = () =>{
    //     setSignValue(1)
    // }
    useEffect(() => {
        if (isChecked) {
            dispatch({ type: "privacyAgreed", payload: { value: false } });
        }
    }, [isChecked]);

    useEffect(() => {
        setTimeout(() => {
            setPopupLoading(true);
            setImageShow('hide');
        }, 2000)

        setTimeout(() => {
            setPopupLoading(false);
            setLoading(false);
        }, 5000)

        setTimeout(() => {
            window.scrollTo({
                top: 150,
                left: 0,
                behavior: "smooth",
            });
        }, 7000)
    }, []);

    const clearSign = () => {
        if (signPadRef.current) signPadRef.current.clear();
    };
    const Privacy = (Clickvalue) => {
        if (Clickvalue == "showPrivacy") {
            setshowPrivacy("show");
        }
    }
    return (
        <>
            <Modal show={modalDisplay} onHide={handleClose} dialogClassName="signaturev2" keyboard={false} >
                {/* {loading && <SignLoader popupLoading={popupLoading} imageShow={imageShow} />} */}
                <GetVisitorsParams />
                <div className="signaturev2">
                <div className="modl_1" id="clientSignature" tabIndex='-1' aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className=" modal-dialog-centered" role="document">
                        <div className="">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Start your Claim</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" onClick={handleClose}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 canvasBlock show text-center">
                                        <p className="small">Please sign in the box below to confirm you understand the contents of the Contingency
                                            Fee Agreement and that you agree to be bound by this Agreement. By doing so you are signing to confirm
                                            that in the event your claim is successful you will pay our fee from your Compensation. The amount you
                                            will be required to pay is based on the total Compensation you receive and is detailed
                                             <a href="/PreContractInfo" target="_blank"> here</a>. </p>
                                        <div className="canvasBkg">
                                            {/* <canvas id="signCanvas" width={396} style={{ touchAction: 'none' }} height={196} /> */}
                                            {(() => {
                                                if (deviceType === "mobile") {
                                                    return (
                                                        <MobileOnlyView className="mob-canvas">
                                                            <SignatureCanvas
                                                                canvasProps={{
                                                                    id: "signCanvas",
                                                                    width: 300,
                                                                    height: 250,
                                                                    className:
                                                                        "signature-pad anim_bt d-lg-block mob-canvas",
                                                                }}
                                                                ref={signPadRef}
                                                                onEnd={() => {
                                                                    if (!signPadRef.current.isEmpty()) {
                                                                        dispatch({
                                                                            type: "signPadChange",
                                                                            payload: { value: false },
                                                                        });
                                                                        dispatch({
                                                                            type: "validSignature",
                                                                            payload: { value: false },
                                                                        });
                                                                        document
                                                                            .getElementById("acceptterms");
                                                                    }
                                                                }}
                                                            />
                                                        </MobileOnlyView>
                                                    );
                                                } else if (deviceType === "tablet" || isIPad13) {
                                                    return (
                                                        <TabletView className="tab-canvas">
                                                            <SignatureCanvas
                                                                canvasProps={{
                                                                    id: "signCanvas",
                                                                    width: 600,
                                                                    height: 300,
                                                                    className:
                                                                        "signature-pad anim_bt d-lg-block tab-canvas",
                                                                }}
                                                                ref={signPadRef}
                                                                onEnd={() => {
                                                                    if (!signPadRef.current.isEmpty()) {
                                                                        dispatch({
                                                                            type: "signPadChange",
                                                                            payload: { value: false },
                                                                        });
                                                                        dispatch({
                                                                            type: "validSignature",
                                                                            payload: { value: false },
                                                                        });
                                                                        document
                                                                            .getElementById("acceptterms");
                                                                    }
                                                                }}
                                                            />
                                                        </TabletView>
                                                    );
                                                } else {
                                                    return (
                                                        <BrowserView className="web-canvas">
                                                            <SignatureCanvas
                                                                canvasProps={{
                                                                    id: "signCanvas",
                                                                    width: 400,
                                                                    height: 200,
                                                                    className:
                                                                        "signature-pad anim_bt d-lg-block web-canvas",
                                                                }}
                                                                ref={signPadRef}
                                                                onEnd={() => {
                                                                    if (!signPadRef.current.isEmpty()) {
                                                                        dispatch({
                                                                            type: "signPadChange",
                                                                            payload: { value: false },
                                                                        });
                                                                        dispatch({
                                                                            type: "validSignature",
                                                                            payload: { value: false },
                                                                        });
                                                                        document
                                                                            .getElementById("acceptterms");
                                                                    }
                                                                }}
                                                            />
                                                        </BrowserView>
                                                    );
                                                }
                                            })()}
                                        </div>
                                        <div>
                                            {state.isCanvasEmpty && (<div className="sign_msg col-12 text-center sign_msg" id="signatures_required">Please Draw a Signature </div>)}
                                            {state.isValid && (<div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">Please Draw a Valid Signature</div>)}
                                            {state.isAgreed && (<div className="sign_msg col-12 text-center sign_msg"> Please tick to show your authorisation for us to
                                                proceed.</div>)}
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox text-left">
                                                {/* <input type="checkbox" className="custom-control-input" id="agreeSign" name="agreeSign" defaultValue="yes" /> */}
                                                <CheckBox
                                                className={`custom-control-input`}
                                                    id="check1"
                                                    value={isChecked}
                                                    onChange={(e) => setIsChecked(!isChecked)}
                                                />
                                                <label className="custom-control-label small" htmlFor="agreeSign">
                                                    <p><small>Please tick here to confirm I have read and understand the contents of the Confirmation Statement
                                                        and I am aware I do not need to use Clear Legal Marketing Limited to make a claim against my
                                                        lender(s) and could do this myself for free.</small> </p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            {/* <button type="submit" id="signForm" className="btn btn-custom1 submitSignedCase">Sign Form</button>
                                            <button id="clearSignature" className="btn btn-secondary d-block">Clear</button> */}
                                             <Button
                                                type="submit"
                                                className="btn btn-custom1 submitSignedCase"
                                                id="sign"
                                                buttonText="Sign Form"
                                                onClick={() => { handleClick(); signSubmit();}}
                                            />
                                            <Button
                                                type="submit"
                                                className="btn btn-secondary d-block"
                                                id="clear"
                                                buttonText="Clear"
                                                onClick={clearSign}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        </>
    )
}
export default SignatureModalV6;