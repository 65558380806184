import React,{useEffect} from "react";
import logo from "../../../assets/img/Clear-LegalMarketingCMYK.png";
import solicitors from "../../../assets/img/solicitors.jpg";
import Footer from "../../Includes/Layouts/common/Footer";

const FollowupThankyou = () =>{
  localStorage.clear();
  useEffect(() => {
    (async()=>{
      window.scrollTo(0, 0);
    })() 
  }, []);
    return(
        <>
        
  <div className="CL_LS_V3 success">
  <div className="bg">
    <div className="clearfix"></div>
    <section className="sec-nav-bars">
      <div className="container text-center">
        <a href="" className="logo">
          <img src={logo} alt=""/>
        </a>
      </div>
    </section>
    <section className="main-start bg text-center "></section>
    <section>
      <div className="container success-area">
        <div className="mt-4 text-center" >
          <h4 className="main_head1">Congratulations! We have received your claim(s) and are ready to submit your complaints!</h4>
          <br/>
          <h4 className="sub_head1">What happens next?</h4>
          <br/>
          <h4>We will shortly send you a Welcome Letter together with a copy of your agreement with us. In most cases we will now have enough information to submit your claim against Lending Stream but should we require anything else we will let you know. </h4>
          <br/>
          <h4>As soon as we submit your claim we will send you an email to confirm it has been submitted. Once this is done we usually hear from Lending Stream within 8-12 weeks. As soon as we hear from them we will be in touch to discuss their decision. </h4>
          <br/>
          <h4>IMPORTANT – When we submit your claim, Lending Stream will send you an email requesting confirmation that you have asked us to submit a claim on your behalf. When you receive this, please click on the link ASAP to confirm that this correct. </h4>
          <br/>
          <h4>Thanks for choosing Clear Legal Marketing.</h4>
          {/* <h1>Thank you for submitting your supporting documentation this will really help us win your claim!</h1>
          <p>If you have not yet submitted everything we have asked for, please do so as soon as possible. You will shortly receive an email from us reminding you how you can do this. Alternatively, if it is easier then you can simply email it to enquiries@clearlegalmarketing.co.uk.</p>
          <p> If you have submitted everything we have asked for, we will now review what you have sent to us and contact you should we need anything else. </p>
          <p> Regards </p>
          <p>Clear Legal Marketing Limited</p> */}
        </div>
      </div>
    </section>
    <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next"></section>
    <Footer/>
 </div>
 </div>
        </>
    )
}
export default FollowupThankyou;