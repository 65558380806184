import React, { useRef, useState, useReducer, useContext, useEffect } from "react";
import {
  BrowserView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,

} from "react-device-detect";
import logo from "../../../assets/img/Clear-LegalMarketingCMYK.png";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../UI/Button";
import VisitorParamsContext from "../../../Contexts/VisitorParamsContext";
import { queryString } from "../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useSignature } from "../../../Hooks/useSignature";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../Utility/CheckUUID";
import Footer from "../../Includes/Layouts/common/Footer";
import CheckBox from "../../UI/CheckBox";
import SignInfo from "../../Includes/Layouts/CL_LS_V3/SignInfo";
import GTMDataLayer from "../../Includes/Layouts/common/GTMDataLayer";
import SignLoader from "../../Includes/Layouts/CL_LS_V3/SignLoader";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Pdf1 from "../../../assets/pdf/BFOA.pdf";
import Pdf2 from "../../../assets/pdf/confirmation-statement.pdf";
import Pdf3 from "../../../assets/pdf/FOA.pdf";


const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const Signature = () => {
  const { isCheck } = CheckUUID();
  isCheck();
  const [showPrivacy, setshowPrivacy] = useState(false);
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('', 'Signature');
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [loading, setLoading] = useState(true);
  const [popupLoading, setPopupLoading] = useState(false);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [imageShow, setImageShow] = useState('show');
  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      console.log(rpdata);
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }

      if (signLength > 13) {
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type
        );
        GTMDataLayer({
          question: "Signature"
        });
        if (response.data.status === "Success") {
          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == '') {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            let currentSignTime =  new Date().toLocaleString();
            formdata.current_sign_time = currentSignTime.replace(/,/g, '');
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
          history.push(
            "/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName
          );
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    setTimeout(() => {
      setPopupLoading(true);
      setImageShow('hide');
    }, 2000)

    setTimeout(() => {
      setPopupLoading(false);
      setLoading(false);
    }, 5000)

    setTimeout(() => {
      window.scrollTo({
        top: 150,
        left: 0,
        behavior: "smooth",
      });
    }, 7000)
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  const Privacy = (Clickvalue) => {
    if (Clickvalue == "showPrivacy") {
      setshowPrivacy("show");
    }
  }
  return (
    <>
      {loading && <SignLoader popupLoading={popupLoading} imageShow={imageShow} />}
      <GetVisitorsParams />
      {!loading && <div className="CL_LS_V3 signature_div">
        <div className="bg">
          <div className="clearfix"></div>
          <section className="sec-nav-bars">
            <div className="container text-center">
              <a href="" className="logo">
                <img src={logo} alt="" />
              </a>
            </div>
          </section>
          <section className="main-start bg text-center d-none d-md-block d-lg-block"></section>
          <section>
            <div className="container">
              <div className="body_signature">
                <div className="sign_header">
                  <h1 className="mt-3">
                    Great news, you are eligible to make a claim! Sign here to
                    start your claim online NOW.
                  </h1>
                </div>
                <div className="inner">
                  <p className="text-center">
                    Please sign in the box below to confirm you understand the
                    contents of the Contingency Fee Agreement and that you agree
                    to be bound by this Agreement. By doing so you are signing to
                    confirm that in the event your claim is successful you will
                    pay our fee from your Compensation. The amount you will be
                    required to pay is based on the total Compensation you receive
                    and is detailed{" "}
                    <a href="/PreContractInfo" target="_blank">
                      here.
                    </a>
                  </p>
                  <div className="signature mt-3">
                    {(() => {
                      if (deviceType === "mobile") {
                        return (
                          <MobileOnlyView className="mob-canvas">
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: 300,
                                height: 250,
                                className:
                                  "signature-pad anim_bt d-lg-block mob-canvas",
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms");
                                }
                              }}
                            />
                          </MobileOnlyView>
                        );
                      } else if (deviceType === "tablet" || isIPad13) {
                        return (
                          <TabletView className="tab-canvas">
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: 600,
                                height: 300,
                                className:
                                  "signature-pad anim_bt d-lg-block tab-canvas",
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms");
                                }
                              }}
                            />
                          </TabletView>
                        );
                      } else {
                        return (
                          <BrowserView className="web-canvas">
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: 500,
                                height: 250,
                                className:
                                  "signature-pad anim_bt d-lg-block web-canvas",
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms");
                                }
                              }}
                            />
                          </BrowserView>
                        );
                      }
                    })()}

                  </div>
                  <div>
                    {state.isCanvasEmpty && (<div className="sign_msg col-12 text-center sign_msg" id="signatures_required">Please Draw a Signature </div>)}
                    {state.isValid && (<div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">Please Draw a Valid Signature</div>)}
                    {state.isAgreed && (<div className="sign_msg col-12 text-center sign_msg"> Please tick to show your authorisation for us to
                      proceed.</div>)}
                  </div>
                </div>
                <div className="sign_footer">
                  <div>
                    <div className="d-flex ">
                      <div className="me-2" id="divCheck">
                        <CheckBox
                          id="check1"
                          value={isChecked}
                          onChange={(e) => setIsChecked(!isChecked)}
                        />
                      </div>
                      <p>
                        <label htmlFor="check1">
                          By signing and by pressing “Sign Form” below, you agree to Clear Legal Marketing Limited<a a href="/PrivacyPolicy" target="_blank"> Privacy Policy </a>
                          and that we may use your signature on the<a href={Pdf1} target="_blank"> Bank Statement LOA </a> that we send to your bank.
                          A copy of the <a href="/PrivacyPolicy" target="_blank">Privacy Policy</a>,<a href={Pdf3} target="_blank">Form of  Authority</a>,<a a href="/PreContractInfo" target="_blank"> Pre Contract Information</a>,
                          and <a href={Pdf2} target="_blank">Confirmation Statement</a>  will be sent to you via email.
                        </label>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mb-2">
                    <Button
                      type="submit"
                      className="com_bnt clear_btn me-3"
                      id="clear"
                      buttonText="Clear"
                      onClick={clearSign}
                    />
                    <Button
                      type="submit"
                      className="com_bnt"
                      id="sign"
                      buttonText="Sign Form"
                      onClick={handleClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next"></section>
          <Footer />
          <SignInfo
            showPrivacy={showPrivacy}
            setshowPrivacy={setshowPrivacy}
          />
        </div>
      </div>}
    </>
  );
};
export default Signature;
