import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import Loader from "../../Includes/Layouts/common/Loader";
import Header from "../../Includes/Layouts/common/Header";
import Footer from "../../Includes/Layouts/common/Footer";
import { CheckUUID } from "../../../Utility/CheckUUID";
import { useForm } from "react-hook-form";
import VisitorParamsContext from "../../../Contexts/VisitorParamsContext";
import { useUserInfo } from "../../../Hooks/useUserInfo";
import { useQuestionnaire } from "../../../Hooks/useQuestionnaire";
import {
    questionnaire1,
    questionnaire2,
    questionnaire3,
    questionnaire4,
    questionnaire5,
    questionnaire6,
    questionnaire8,
    questionnaire9,
} from "../../../Constants/Questions";
import { queryString, urlParams } from "../../../Utility/QueryString";
import QuestionRadioSlide from "../../Includes/Layouts/CL_LS_V3/QuestionRadioSlide";
import QuestionSelectSlide from "../../Includes/Layouts/CL_LS_V3/QuestionSelectSlide";
import QuestionInputSlide from "../../Includes/Layouts/CL_LS_V3/QuestionInputSlide";
import QuestionnaireCheckboxSlide from "../../Includes/Layouts/CL_LS_V3/QuestionnaireCheckboxSlide";
import QuestionTextAreaSlide from "../../Includes/Layouts/CL_LS_V3/QuestionTextAreaSlide";
import ModalQuestionnaire from "../../Includes/Layouts/common/ModalQuestionnaire";
import GTMDataLayer from "../../Includes/Layouts/common/GTMDataLayer";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import { Api } from "../../../api/Api";
import { useFollowupUserQuestions } from "../../../Hooks/useFollowupUserQuestions";
import { useFollowUpPendingDetails } from "../../../Hooks/useFollowUpPendingDetails";

var complete_arr = [];
const initialState = {
    showQuestionSlide1: "d-none",
    showQuestionSlide2: "d-none",
    showQuestionSlide3: "d-none",
    showQuestionSlide4: "d-none",
    showQuestionSlide5: "d-none",
    showQuestionSlide6: "d-none",
    showQuestionSlide8: "d-none",
    showQuestionSlide9: "d-none",
};
const backbutton_status = {
     "question_1": true ,
     "question_2": true ,
     "question_9": true ,
     "question_3": true ,
     "question_4": true ,
     "question_5": true ,
     "question_6": true ,
}
const QuestionReducer = (state, action) => {
    switch (action.type) {
        case "nextQuestionSlide": {
            if (action.payload.selectedQuest == "question_1") {
                return {
                    ...state,
                    showQuestionSlide1: "d-block",
                    showQuestionSlide2: "d-none",
                    showQuestionSlide3: "d-none",
                    showQuestionSlide4: "d-none",
                    showQuestionSlide5: "d-none",
                    showQuestionSlide6: "d-none",
                    showQuestionSlide8: "d-none",
                    showQuestionSlide9: "d-none",
                };
            } else if (action.payload.selectedQuest == "question_2") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide2: "d-block",
                    showQuestionSlide3: "d-none",
                    showQuestionSlide4: "d-none",
                    showQuestionSlide5: "d-none",
                    showQuestionSlide6: "d-none",
                    showQuestionSlide8: "d-none",
                    showQuestionSlide9: "d-none",
                };
            } else if (action.payload.selectedQuest == "question_9") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide2: "d-none",
                    showQuestionSlide3: "d-none",
                    showQuestionSlide4: "d-none",
                    showQuestionSlide5: "d-none",
                    showQuestionSlide6: "d-none",
                    showQuestionSlide8: "d-none",
                    showQuestionSlide9: "d-block",
                };
            } else if (action.payload.selectedQuest == "question_3") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide2: "d-none",
                    showQuestionSlide3: "d-block",
                    showQuestionSlide4: "d-none",
                    showQuestionSlide5: "d-none",
                    showQuestionSlide6: "d-none",
                    showQuestionSlide8: "d-none",
                    showQuestionSlide9: "d-none",
                };
            } else if (action.payload.selectedQuest == "question_4") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide2: "d-none",
                    showQuestionSlide3: "d-none",
                    showQuestionSlide4: "d-block",
                    showQuestionSlide5: "d-none",
                    showQuestionSlide6: "d-none",
                    showQuestionSlide8: "d-none",
                    showQuestionSlide9: "d-none",
                };
            } else if (action.payload.selectedQuest == "question_5") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide2: "d-none",
                    showQuestionSlide3: "d-none",
                    showQuestionSlide4: "d-none",
                    showQuestionSlide5: "d-block",
                    showQuestionSlide6: "d-none",
                    showQuestionSlide8: "d-none",
                    showQuestionSlide9: "d-none",
                };
            } else if (action.payload.selectedQuest == "question_6") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide2: "d-none",
                    showQuestionSlide3: "d-none",
                    showQuestionSlide4: "d-none",
                    showQuestionSlide5: "d-none",
                    showQuestionSlide6: "d-block",
                    showQuestionSlide8: "d-none",
                    showQuestionSlide9: "d-none",
                };
            } else if (action.payload.selectedQuest == "question_8") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide2: "d-none",
                    showQuestionSlide3: "d-none",
                    showQuestionSlide4: "d-none",
                    showQuestionSlide5: "d-none",
                    showQuestionSlide6: "d-none",
                    showQuestionSlide8: "d-block",
                    showQuestionSlide9: "d-none",
                };
            } else {
                return {
                    ...state,
                };
            }
        }
        case "backQuestionSlide": {
            if (action.payload.prevSlide.slide == "back1") {
                return {
                    ...state,
                    showQuestionSlide1: "d-none",
                    showQuestionSlide8: "d-block",
                };
            } else if (action.payload.prevSlide.slide == "back2") {
                return {
                    ...state,
                    showQuestionSlide2: "d-none",
                    showQuestionSlide1: "d-block",
                };
            } else if (action.payload.prevSlide.slide == "back3") {
                return {
                    ...state,
                    showQuestionSlide3: "d-none",
                    showQuestionSlide9: "d-block",
                };
            } else if (action.payload.prevSlide.slide == "back4") {
                return {
                    ...state,
                    showQuestionSlide4: "d-none",
                    showQuestionSlide3: "d-block",
                };
            } else if (action.payload.prevSlide.slide == "back5") {
                return {
                    ...state,
                    showQuestionSlide5: "d-none",
                    showQuestionSlide3: "d-block",
                };
            } else if (action.payload.prevSlide.slide == "back6") {
                return {
                    ...state,
                    showQuestionSlide6: "d-none",
                    showQuestionSlide5: "d-block",
                };
            } else if (action.payload.prevSlide.slide == "back7") {
                return {
                    ...state,
                    showQuestionSlide7: "d-none",
                    showQuestionSlide6: "d-block",
                };
            } else if (action.payload.prevSlide.slide == "back9") {
                return {
                    ...state,
                    showQuestionSlide9: "d-none",
                    showQuestionSlide2: "d-block",
                };
            } else {
                return {
                    ...state,
                };
            }
        }
    }
};
const QuestionnaireFollowup = () => {
    const { register, errors, clearErrors, setError, trigger, getValues } =
        useForm({
            mode: "onSubmit",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    const [clickedSlide, setClickedSlide] = useState();
    const [popShow, setPopShow] = useState(false);
    const history = useHistory();
    const userQuestionnire = useRef(null);
    const [prevSlide, setPrevSlide] = useState();
    const [quest1Ans, setQuest1Ans] = useState(null);
    const { visitorParameters } = useContext(VisitorParamsContext);
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const [completedQuestArr, setCompletedQuestArr] = useState([]);
    const [state, dispatch] = useReducer(QuestionReducer, initialState);
    const { updateUserQualifiedStatus } = useUserInfo();
    const [completePercentage, setCompletePercentage] = useState("15");
    // const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('CL_LS_V3', 'CL_LS_V3/questionnaire');
    const { SaveQuestionnaireSkip } = useQuestionnaire();
    const [showEndYear, setShowEndYear] = useState(false);
    const [show, setShow] = useState('d-none');
    const [show1, setShow1] = useState('d-block');
    const [show2, setShow2] = useState('d-none');
    const [show3, setShow3] = useState('d-none');
    const [show4, setShow4] = useState('d-block');
    const [showUserName, setUserName] = useState("");
    const [allFollowupData, setFollowupData] = useState({});
    const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
    const [currentUuid, setCurrentUuid] = useState("");
    const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
    const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
    const followupData = JSON.parse(localStorage.getItem('followup_data'));
    const userToken = urlParams.get("atp_sub2");
    const atpSub6 = urlParams.get("atp_sub6") ? urlParams.get("atp_sub6") : "FLP";
    localStorage.setItem("user_token", userToken);
    localStorage.setItem("atp_sub6", atpSub6);
    const message_type = "followup_question_store";
    const [ signatureData, setSignatureData ] = useState();
    const { followUpPendingDetails } = useFollowUpPendingDetails();
    useEffect(() => {
        (async () => {
            const response = await followUpPendingDetails(queryString, null);
            const followupDetails = response.data.response.followup_data;
            localStorage.setItem("followup_data", JSON.stringify(followupDetails));
            const is_qualified = response.data.response.user_info.is_qualified;
            const is_user_complete = response.data.response.pending_details.is_user_complete;
            const is_questionnaire = response.data.response.pending_details.is_questionnaire;
            const is_user_sign    = response.data.response.pending_details.is_user_sign;
            const filled_question = response.data.response.pending_details.filled_question;
            setSignatureData(is_user_sign);
            let question_id       = [];
            if(filled_question != undefined || filled_question != null){
                filled_question.map((value)=>{
                    if(value.quest_id == 9 && value.option_id == 28){
                        setShowEndYear(false);
                    }else if(value.quest_id == 9 && value.option_id == 29){
                        setShowEndYear(true);
                    }else if(value.quest_id == 1){
                        localStorage.setItem('question_1',value.option_id);
                    }
                    question_id.push(value.quest_id);
                });
    
            }
            setCompletedQuestArr(question_id);
            const uuid = response.data.response.user_info.uuid;
            const email = response.data.response.user_info.email;
            localStorage.setItem("user_email", email);
            if (userToken == null) {
                localStorage.setItem("user_token", followupDetails.atp_sub2);
            }
            setUserName(response.data.response.user_info.user_name);
            setFollowupData({
                flpData: response.data.response,
            });
            const pendingQuestions = response.data.response.pending_details.pending_question;
            let question_name = "question_" + pendingQuestions[0];
            backbutton_status[question_name]= false;
            setPendingQuestionnaire({ pendingQuestions });
            localStorage.setItem(
                "user_info",
                JSON.stringify(response.data.response.user_info)
            );
            setCurrentUuid(uuid);
            if (response.data.status === "Success") {
                const flpResponse = await loadFollowup(
                    "followup_load",
                    followupDetails,
                    "CL_LS_V3",
                    queryString,
                    "v2",
                    uuid
                );

                setTimeout(() => {
                    setShow1('d-none');
                }, 1500);

                setTimeout(() => {
                    setShow2('d-show');
                }, 2000);

                setTimeout(() => {
                    setShow3('d-show');
                }, 2200);

                setTimeout(() => {
                    setShow4('d-none');
                    setShow('d-block');
                }, 4000);

                if(is_qualified == 0){
                    history.push( "/unqualified?uuid=" + uuid )
                    return false;
                } else if (is_questionnaire == 1 && is_user_sign == 1 && is_user_complete == 1) {
                    history.push("/followup/thankyou?uuid=" + uuid + '&user_token=' + userToken + '&source=' + atpSub6);
                    return false;
                }else if(is_questionnaire == 1 && is_user_sign != 1){
                    history.push("/followup/signature?"+queryString);
                    return false;
                }
                if (pendingQuestions.length != 0) {
                    let selectedQuest = question_name;
                    dispatch({ type: 'nextQuestionSlide', payload: { selectedQuest } });
                } else {
                    console.log("no pending questions");
                }
            }
        })();
    }, []);
    
    // useEffect(() => {
    //     if (pendingQuestionnaire.length != 0) {
    //         const totalQuestArr = pendingQuestionnaire.pendingQuestions;
    //         let questIntersection = totalQuestArr.filter(x => !completedQuestArr.includes(x));
    //         if (questIntersection[0] == undefined) {
    //             setShow('d-none');
    //         }
    //         let selectedQuest = questIntersection[0];
    //         if (selectedQuest) {
    //             dispatch({ type: 'nextQuestionSlide', payload: { selectedQuest } });
    //         }
    //     } else {
    //         console.log("no pending questions");
    //     }
    // }, [pendingQuestionnaire, completedQuestArr]);

    useEffect(() => {
        if (clickedSlide) {
            clickedSlide.slide == "question_8"
                ? setCompletePercentage("30")
                : clickedSlide.slide == "question_1"
                    ? setCompletePercentage("40")
                    : clickedSlide.slide == "question_2"
                        ? setCompletePercentage("50")
                        : clickedSlide.slide == "question_9"
                            ? setCompletePercentage("60")
                            : clickedSlide.slide == "next3"
                                ? setCompletePercentage("70")
                                : clickedSlide.slide == "next4"
                                    ? setCompletePercentage("80")
                                    : setCompletePercentage("100");
            dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
        }
    }, [clickedSlide]);
    useEffect(() => {
        if (prevSlide) {
            prevSlide.slide == "back1"
                ? setCompletePercentage("15")
                : prevSlide.slide == "back2"
                    ? setCompletePercentage("30")
                    : prevSlide.slide == "back9"
                        ? setCompletePercentage("40")
                        : prevSlide.slide == "back3"
                            ? setCompletePercentage("50")
                            : prevSlide.slide == "back4"
                                ? setCompletePercentage("60")
                                : prevSlide.slide == "back5"
                                    ? setCompletePercentage("70")
                                    : prevSlide.slide == "back6"
                                        ? setCompletePercentage("80")
                                        : setCompletePercentage("100");
            dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
        }
    }, [prevSlide]);

    const getLabelValue = (questionId, value) => {
        let questionnaire = {};
        switch (questionId) {
            case "1":
                questionnaire = questionnaire1;
                break;
            case "2":
                questionnaire = questionnaire2;
                break;
            case "3":
                questionnaire = questionnaire3;
                break;
            case "4":
                questionnaire = questionnaire4;
                break;
            case "5":
                questionnaire = questionnaire5;
                break;
            case "6":
                questionnaire = questionnaire6;
                break;
            case "8":
                questionnaire = questionnaire8;
                break;
            case "9":
                questionnaire = questionnaire9;
                break;
        }

        let labelValue = questionnaire.answers
            .filter((obj) => obj.value === value)
            .map((obj) => obj.label);

        return labelValue;
    };

    const handleCloseQuestionModal = () => {
        setPopShow(false);
    }

    const storeQuestionnireSkip = async (type, is_qualified) => {
        const response = await SaveQuestionnaireSkip(
            currentUuid,
            type,
            is_qualified
        );
    };
    const slideChange = async (e, fieldName) => {
        var selectedQuest = e.target.dataset.question_id;
        selectedQuest = parseInt(selectedQuest);
        gtmDataLayer(e.target.name, e.target);
        // e.preventDefault();
        window.scrollTo(0, 0);
        const values = getValues();
        const formData = values;
     
         if(e.target.name == 'question_1' ){
            localStorage.setItem("question_1", e.target.value);
         }
        let question_1 = localStorage.getItem('question_1');
        let isQualified = ((e.target.name == "next5" ) && question_1 == "1" ) ? "0" : '';
        if (e.target.value == "4" && fieldName == "question_2") {
            setPopShow(true);
        } else {
            if (e.target.name == "question_9" && e.target.value == "29") {
                setShowEndYear(true);
            }
            if (e.target.name == "question_9" && e.target.value == "28") {
                setShowEndYear(false);
            }
            if (e.target.value === "131") {
                var currentSlide = "questionnaire6";
            } else if (e.target.value === "skip4") {
                var currentSlide = e.target.name;
                setCompletedQuestArr([...completedQuestArr, 4]);
                setClickedSlide({ slide: currentSlide });
                storeQuestionnireSkip("account_number", '');
                return;
            }
            else if (e.target.value === "skip6") {
                const values = getValues();
                const formData = values;
                storeQuestionnireSkip('question_6', isQualified);
                if (question_1 != "1") {
                    history.push("/followup/signature?uuid=" + currentUuid + '&user_token=' + userToken + '&source=' + atpSub6);
                    return;
                } else {
                    history.push(
                        "/unqualified?uuid=" + currentUuid + '&user_token=' + userToken + '&source=' + atpSub6
                    );
                }
            }
            else {
                var currentSlide = e.target.name;
            }
            const form = userQuestionnire.current;
            const values = getValues();
            const formData = values;
            const question_1=localStorage.getItem('question_1');
            setClickedSlide({ slide: currentSlide });
            var question_id = e.target.dataset.question_id;
            var input_questions = ["3", "4", "6"];
            var optionId = input_questions.includes(question_id) ? "" : question_id == "5" ? fieldName : form[fieldName].value;
            let inputAnswer = input_questions.includes(question_id) ? form[fieldName].value : "";
            let labelValue = "";
            if (question_id == "5") {
                labelValue = getLabelValue(question_id, fieldName);
            } else {
                labelValue = getLabelValue(question_id, form[fieldName].value);
            }
            let question_data = {
                question_id: question_id,
                option_id: optionId,
                // answer_text: labelValue.toString(),
                input_answer: inputAnswer,
                is_qualified: isQualified,
                bankId: '1',
            };
            ///save questionData in localstorage
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = {};
            var newEntries =
                existingEntries == null ? (existingEntries = {}) : existingEntries;
            Object.assign(newEntries, {
                [question_id]: optionId ? optionId : inputAnswer,
            });
            localStorage.setItem("questionData", JSON.stringify(newEntries));
            ///send questionData to api
            if (e.target.value != "skip6") {
                storeQuestionnire(question_data, followupData, message_type);
            }
            if (e.target.name == "next5") { 
                if (question_1 == "1" ) {
                    history.push(
                        "/unqualified?uuid=" + currentUuid + '&user_token=' + userToken + '&source=' + atpSub6
                    );
                }else if( signatureData != 1 ){
                    history.push(
                        "/followup/signature?uuid=" + currentUuid + '&user_token=' + userToken + '&source=' + atpSub6
                    );
                } else if(signatureData == 1){
                    history.push(
                        "/followup/thankyou?uuid=" + currentUuid + '&user_token=' + userToken + '&source=' + atpSub6
                        );
                }
                else{
                    history.push(
                        "/followup/thankyou?uuid=" + currentUuid + '&user_token=' + userToken + '&source=' + atpSub6
                    );
                }
               
            }else {
                let selectedQuest = e.target.dataset.nextquestion;
                dispatch({ type: 'nextQuestionSlide', payload: { selectedQuest } });
            }
            setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        }
    };

    const storeQuestionnire = async (question_data, followupData, message_type) => {
        const response = await saveFollowupUserQuestions(
            question_data,
            followupData,
            message_type,
            currentUuid
        );
    };

    const gtmDataLayer = (current_question, element = null) => {
        let question = "";
        let answer = "";
        switch (current_question) {
            case "question_8":
                question = "Employment Status";
                break;
            case "question_1":
                question = "How many times increased";
                break;
            case "question_2":
                question = "Claim against lender";
                break;
            case "next3":
                question = "received years";
                break;
            case "next4":
                question = "Account number";
                break;
            case "next5":
                question = "Tick that applies";
                break;
            case "next6":
                question = "lending was unaffordable";
                break;
        }
        GTMDataLayer({
            question: question,
            answer: answer,
        });
    };

    const previousSlide = (e) => {
        var previous_slide = e.target.name;
        setPrevSlide({ slide: previous_slide });
    };
    const formOnSubmit = (e) => {
        e.preventDefault();
        return false;
    };

    return (
        <>
            <div className="followup ">
                <Loader
                    show1={show1}
                    show2={show2}
                    show3={show3}
                    show4={show4}
                />
                <div id="myDiv2" className={show}>
                    <Header />
                    <section className="main-start bg text-center " />
                    <section>
                        <div className="container">
                            {/* SLIDE 1 */}
                            <div className={`section-slides form-wraper text-center slide1`}>
                                <div>
                                    <div
                                        className="progress progrss"
                                        data-percentage={completePercentage}
                                    >
                                        <span className="progress-left">
                                            <span className="progress-bar" />
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar" id="progressBar" />
                                        </span>
                                        <div className="progress-value">
                                            <div id="progressBar_content">
                                                <span className="perce">{completePercentage}%</span>
                                                <br />
                                                <span>completed</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form ref={userQuestionnire} onSubmit={formOnSubmit}>
                                    <QuestionRadioSlide
                                        showQuestionSlide={state.showQuestionSlide1}
                                        questionnaire={questionnaire1}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={backbutton_status['question_1']}
                                        validation={register}
                                        validationMsg={errors}
                                        nextQuestion="question_2"
                                        trigger={trigger}
                                    />
                                    <QuestionRadioSlide
                                        showQuestionSlide={state.showQuestionSlide2}
                                        questionnaire={questionnaire2}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={backbutton_status['question_2']}
                                        validation={register}
                                        validationMsg={errors}
                                        nextQuestion="question_9"
                                        trigger={trigger}
                                    />
                                    <QuestionSelectSlide
                                        showQuestionSlide={state.showQuestionSlide3}
                                        questionnaire={questionnaire3}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={backbutton_status['question_3']}
                                        nextQuestion="question_5"
                                        showEndYear={showEndYear}
                                    />
                                    {/* <QuestionInputSlide
                                        showQuestionSlide={state.showQuestionSlide4}
                                        questionnaire={questionnaire4}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={backbutton_status['question_4']}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        userQuestionnire={userQuestionnire}
                                        setError={setError}
                                    /> */}
                                    <QuestionnaireCheckboxSlide
                                        showQuestionSlide={state.showQuestionSlide5}
                                        questionnaire={questionnaire5}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={backbutton_status['question_5']}
                                        validation={register}
                                        validationMsg={errors}
                                        nextQuestion="question_6"
                                        trigger={trigger}
                                    />
                                    {/* <QuestionTextAreaSlide
                                        showQuestionSlide={state.showQuestionSlide6}
                                        questionnaire={questionnaire6}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={backbutton_status['question_6']}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        userQuestionnire={userQuestionnire}
                                    /> */}
                                    <QuestionRadioSlide
                                        showQuestionSlide={state.showQuestionSlide8}
                                        questionnaire={questionnaire8}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={false}
                                        validation={register}
                                        validationMsg={errors}
                                        nextQuestion="question_1"
                                        trigger={trigger}
                                    />
                                    <QuestionRadioSlide
                                        showQuestionSlide={state.showQuestionSlide9}
                                        questionnaire={questionnaire9}
                                        previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        backBtn={backbutton_status['question_9']}
                                        validation={register}
                                        validationMsg={errors}
                                        nextQuestion="question_3"
                                        trigger={trigger}
                                    />
                                </form>
                            </div>
                        </div>
                        <ModalQuestionnaire
                            content="Unfortunately we cannot process any cases based on this criteria"
                            popDisplay={popShow}
                            handleClose={handleCloseQuestionModal}
                        />
                    </section>
                    <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next" />
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default QuestionnaireFollowup;