import React, { useRef, useState, useContext, useEffect, } from "react";
import {creditCardstartYear} from "../../../../Constants/Constants";

const QuestionSelectSlide = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const [showStartError, setShowStartError] = useState('d-none');
  const [showEndError, setShowEndError] = useState('d-none');
  const [startYearVal, setStartYearVal] = useState('');
  const [endYearVal, setEndYearVal] = useState('');
  const [yearValue, setYearValue] = useState('');
  const [compareYearError, setCompareYearError] = useState('d-none');
  
  useEffect(() => {
  if(props.showEndYear){
    setYearValue(startYearVal+'-'+endYearVal);
  }else{
    setYearValue(startYearVal);
  }
  }, [startYearVal,endYearVal])

  const startYear =  creditCardstartYear.map((item, index) => {
      return (
        <option value={item.value} key={index}>
          {item.label}
        </option>
      );
  });
  const endYear =  creditCardstartYear.map((item, index) => {
    if(item.value >= startYearVal || item.value == ''){
      return (
        <option value={item.value} key={index}>
          {item.label}
        </option>
      );
    }
  });
  const setValue = (e) => {
    if (e.target.id == 'startYear') {
      setEndYearVal('');
     if (props.showEndYear){
       document.getElementById('endYear').value = '';
     } 
      setStartYearVal(e.target.value);
      (e.target.value != '') ? setShowStartError('d-none') : setShowStartError('d-block');
    } else {
      setEndYearVal(e.target.value);
      (e.target.value != '') ? setShowEndError('d-none') : setShowEndError('d-block');
    }
  }
  const validateSelect = (e) => {
    e.preventDefault();
    if (props.showEndYear && startYearVal != '' && endYearVal != '') {
      if(startYearVal <= endYearVal){
      setYearValue(startYearVal+'-'+endYearVal);
      props.slideChange(e, radioName);
      }
      else {
        setCompareYearError('d-block');
      }
    } 
    else if (!props.showEndYear && startYearVal != '') {
      setYearValue(startYearVal);
      props.slideChange(e, radioName);
    }else {
      (startYearVal == '') ? setShowStartError('d-block') : setShowEndError('d-block')
    }
  }
  const clearError = () => {
    if(startYearVal < endYearVal){
      setCompareYearError('d-none');
      }
  }

  const backClick = (e) =>{
    setEndYearVal('');
    setStartYearVal('');
    setYearValue('');
    props.previousSlide(e);
    document.getElementById("startYear").value = '';
    if(props.showEndYear){
      (document.getElementById("endYear").value   = '');
    }
  }

  return (
    <>
      <div className={`slide-top ${props.showQuestionSlide}`}>
      <h1 className="title">
        {(props.showEndYear)?props.questionnaire.question: props.questionnaire.question_yes}
      </h1>
      {(props.showEndYear)?
        <p>
          If you still have it then put the current year (Don't worry if you
          cannot remember - an estimate will suffice)
        </p>:''
        }
        <div className="select-filed mt-4">
          <div className="row">
            {props.showEndYear ? 
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className=" ">
                <select name="startYear" id="startYear" onChange={(e) => { setValue(e) }}>
                <option value="" >Select Start Year</option>
                 {startYear}
                </select>
              </div>
              <span className={`errormsg ${showStartError}`}>Please Select Year</span>
            </div>
            :
            <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
              <div className=" ">
                <select name="startYear" id="startYear" onChange={(e) => { setValue(e) }}>
                <option value="" >Select Start Year</option>
                {startYear}
                </select>
              </div>
              <span className={`errormsg ${showStartError}`}>Please Select Year</span>
            </div>
            }
            {props.showEndYear && <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className=" ">
                <select name="endYear" id="endYear" onChange={(e) => { setValue(e) }} onClick={clearError}>
                <option value="" >Select End Year</option>
                 {endYear}
                </select>
              </div>
              <span className={`errormsg ${showEndError}`}>Please Select Year</span>
              <span className={`errormsg ${compareYearError}`}>End Year Should be greater than Start Year</span>
            </div>}
          </div>
        </div>
        <input type="hidden" name={`question_${question_id}`} id={`question_${question_id}`} value ={yearValue}/>
        <div className="mt-4 d-flex justify-content-center controle_btn">
          {props.backBtn && <button type="button" className="com_bnt me-3 back2 " name={`back${question_id}`} onClick={backClick}>
            <i className="bi bi-arrow-left" /> Back{" "}
          </button>}
          <button type="button" className="com_bnt next3" value={question_id} name={`next${question_id}`}
          data-question_id={question_id} onClick={validateSelect} data-nextquestion={props.nextQuestion}>
            {" "}
            Next <i className="bi bi-arrow-right" />
          </button>
        </div>
      </div>
    </>
  )
};

export default QuestionSelectSlide;
