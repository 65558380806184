import React, { useContext, useEffect, useRef, useState } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParamsContext";
import "../../assets/css/loa.scss";
import { useUserInfo } from "../../Hooks/useUserInfo";
import { FormData } from "../../Utility/FormData";
import { useHistory } from "react-router-dom";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
const PreviewLoa = () => {
   const { isCheck, } = CheckUUID();
   const isCheckResponse = isCheck();
   const [userName, setUserName] = useState();
   const [userEmail, setUserEmail] = useState();
   const [address, setAddress] = useState();
   const [userDob, setUserDob] = useState();
   const [currentDate, setCurrentDate] = useState();
   const [currentDateTime, setCurrentDateTime] = useState();
   const [currentSignDateTime, setCurrentSignDateTime] = useState();
   const [userSign, setUserSign] = useState();
   const { getUserInfo } = useUserInfo();
   const { visitorParameters } = useContext(VisitorParamsContext);
   const [currentUuid, setCurrentUuid] = useState();
   const [bankName, setBankName] = useState();
   const { setFormData } = FormData();
   const [userSortCode, setUserSortCode] = useState([]);
   const [userBankAccount, setUserBankAccount] = useState([]);
   const [previousAddress, setPreviousAddress] = useState();
   const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('', 'preview');
   const { saveDataIngestion, isLoading } = useDataIngestion();
   const [pdfPageNo, setPdfPageNo] = useState(1);
   const history = useHistory();
   const pdfRef_1 = useRef();
   const pdfRef_2 = useRef();
   const pdfRef_3 = useRef();
   const pdfRef_4 = useRef();
   const signRef_1 = useRef();
   const signRef_2 = useRef();
   const signRef_3 = useRef();
   const signRef_4 = useRef();
   const [userId,setUserId]=useState('');
   const [ipAddress,setIpAddress]= useState('');
   useEffect(() => {
      (async () => {
         const visitorData = visitorParameters.visitor_parameters;
         if (visitorData) {
            const uuid = visitorData.uuid;
            setCurrentUuid(uuid);
         }
      })();
   }, [visitorParameters]);
   const query = new URLSearchParams(window.location.search);
   const query_uuid = query.get("uuid");
   const local_uuid = localStorage.getItem('uuid');
   const customUuid = (query_uuid) ? query_uuid : local_uuid;

   useEffect(() => {
      (async () =>{
         const formdata = JSON.parse(localStorage.getItem('formData'));
         console.log(formdata);
         const customPreviousData = JSON.parse(localStorage.getItem('customPreviousData'));
         const userBank = localStorage.getItem('bankName');
         let question3 = (formdata) ? formdata.question_3 : '';
         const response = await getUserInfo(
               customUuid
            );
         if((response.data.response).length >0){
            setUserId(response.data.response[0].id);
            setIpAddress(response.data.response[0].ip_address);
         }
         if (formdata === undefined || formdata === null || formdata == '') {
               const set_formdata = setFormData(
                  response
               );
               let dataLength = (response.data.response).length;
               if (dataLength > 0) {
                  const userName = (response.data.response[0].first_name) ? Capitalize(response.data.response[0].first_name) + ' ' + Capitalize(response.data.response[0].last_name) : '';
                  const usersign = (response.data.response[0].signature_image) ? response.data.response[0].signature_image : '';
                  const userdob = (response.data.response[0].user_dob) ? response.data.response[0].user_dob : '';
                  let userAddress = '';
                  let preAddress = '';
                  userAddress += (response.data.response[0].address_line1) ? response.data.response[0].address_line1 + '<br/>' : '';
                  userAddress += (response.data.response[0].address_line2) ? response.data.response[0].address_line2 + '<br/>' : '';
                  userAddress += (response.data.response[0].address_line3) ? response.data.response[0].address_line3 + '<br/>' : '';
                  userAddress += (response.data.response[0].country) ? response.data.response[0].country + '<br/>' : '';
                  userAddress += (response.data.response[0].postcode) ? response.data.response[0].postcode.toUpperCase() + '<br/>' : '';

                  preAddress += (response.data.response[1] && response.data.response[1].address_line1) ? response.data.response[1].address_line1 + '<br/>' : '';
                  preAddress += (response.data.response[1] && response.data.response[1].address_line2) ? response.data.response[1].address_line2 + '<br/>' : '';
                  preAddress += (response.data.response[1] && response.data.response[1].address_line3) ? response.data.response[1].address_line3 + '<br/>' : '';
                  preAddress += (response.data.response[1] && response.data.response[1].country) ? response.data.response[1].country + '<br/>' : '';
                  preAddress += (response.data.response[1] && response.data.response[1].postcode) ? response.data.response[1].postcode.toUpperCase() + '<br/>' : '';
                  let userEmail = (response.data.response[0].email) ? response.data.response[0].email : '';
                  const bankname = (response.data.response[0].bank_name) ? response.data.response[0].bank_name : null;
                  setCurrentSignDateTime(response.data.response[0].sign_date_time);
                  setBankName(bankname);
                  setUserName(userName);
                  setUserSign(usersign);
                  setUserEmail(userEmail);
                  setUserDob(userdob);
                  setAddress(userAddress);
                  setPreviousAddress(preAddress);

                  let timeNow = new Date().toLocaleString();
                  timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
                  setCurrentDate(timeNow);

                  let currentDateTime = new Date().toLocaleString();
                  currentDateTime = currentDateTime.replace(/,/g, '');
                  setCurrentDateTime(currentDateTime);
               };
         } else {
            let months = { January: "01", February: "02", March: "03", "April": "04", May: "05", June: "06", July: "07", August: "08", September: "09", October: "10", November: "11", December: "12" };
            const userName = (formdata) ? Capitalize(formdata.txtFName) + ' ' + Capitalize(formdata.txtLName) : '';
            const userdob = (formdata) ? formdata.dobDay + '/' + months[formdata.dobMonth] + '/' + formdata.dobYear : '';
            const userSign = (formdata) ? formdata.user_sign : '';
            const userEmail = (formdata) ? formdata.txtEmail : '';
            const currentSignDateTime = (formdata)? formdata.current_sign_time:'';
            let userAddress = '';
            let preAddress = ''
            userAddress = ((formdata.txtHouseNumber) ? formdata.txtHouseNumber + '<br/>' : '') + ((formdata.address2) ? formdata.address2 + '<br/>' : '') +
               ((formdata.txtStreet) ? formdata.txtStreet + '<br/>' : '') + ((formdata.txtCountry) ? formdata.txtCountry + '<br/>' : '') +
               ((formdata.txtPostCode).toUpperCase());

            preAddress += (formdata.txtPrevHouseNumber_1) ? formdata.txtPrevHouseNumber_1 + '<br/>' : '';
            preAddress += (formdata.txtPrevroute_1) ? formdata.txtPrevroute_1 + '<br/>' : '';
            preAddress += (formdata.txtPrevStreet_1) ? formdata.txtPrevStreet_1 + '<br/>' : '';
            preAddress += (formdata.txtPrevCountry_1) ? formdata.txtPrevCountry_1 + '<br/>' : '';
            preAddress += (formdata.txtPrevPostCode_1) ? formdata.txtPrevPostCode_1.toUpperCase() + '<br/>' : '';

            setPreviousAddress(preAddress);
            setBankName(userBank);
            setUserName(userName);
            setUserEmail(userEmail);
            setCurrentSignDateTime(currentSignDateTime);
            setAddress(userAddress);
            setUserDob(userdob);
            setUserSign(userSign);
            let timeNow = new Date().toLocaleString();
            timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
            setCurrentDate(timeNow);
            let currentDateTime = new Date().toLocaleString();
            currentDateTime = currentDateTime.replace(/,/g, '');
            setCurrentDateTime(currentDateTime);
         }
      })()
   }, []);

   function Capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
   }

   useEffect(() => {

      let previewType = (pdfPageNo == '1') ? 'preview_load' : (pdfPageNo == '4') ? 'preview_submit' : '';
      let previewClick = (pdfPageNo != '1') ? 'click_' + (parseInt(pdfPageNo) - 1) : '';
      let nextRef = (pdfPageNo == '1') ? pdfRef_1 : (pdfPageNo == '2') ? pdfRef_2 : pdfRef_3;
      let preRef = (pdfPageNo == '2') ? pdfRef_1 : (pdfPageNo == '3') ? pdfRef_2 : pdfRef_3;
      let focusRef = (pdfPageNo == '1') ? signRef_1 : (pdfPageNo == '2') ? signRef_2 : signRef_3;
      if (pdfPageNo != 1) {
         preRef.current.classList.replace('show', 'hide');
         nextRef.current.classList.replace('hide', 'show');
      }

      focusRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      (async () => {
         let submitData = { 'preview_type': previewType, 'source': 'live', 'preview_click': previewClick };
         const formSubmitResult = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            submitData,
            "preview_status",
            "",
            "",
            customUuid,
         );
      })();

      if (pdfPageNo == '4') {
         history.push(
            "/" + DynamicRouteNextPage + "?uuid=" + customUuid + "&splitName=" + DynamicRouteSplitName
         );
      };

   }, [pdfPageNo]);


   return (
      <>
         <div className="previewPage">
            <div className="pt-lg-5 pt-3">
               <section className="container-1 show" ref={pdfRef_1}>
                  <div className="pt-3">
                     <h4 className="text-center"> Confirmation Statement </h4>
                     <p>
                        I, {userName}, confirm that I have read and understand that:-
                     </p>
                     <ol className="mt-4">
                        <li className="spl">
                           <span>a)</span> &nbsp; &nbsp; <p>I do not need to use Clear Legal Marketing Limited to make a claim against my lender(s).</p>
                        </li>
                        <li className="spl">
                           <span>b)</span>  &nbsp; &nbsp;<p>It is possible for me to present a claim directly to a lender or to the Financial Services Ombudsman for free.</p>
                        </li>
                     </ol>
                     <p className="mt-4">
                        However, having considered these options, I still wish to instruct Clear Legal Marketing Limited to present the claim(s) on my behalf.
                     </p>
                     <p>
                        Signature
                     </p>
                     <p ref={signRef_1}>
                        <img src={userSign} alt="" className="w-25" />
                     </p>
                     <p>
                        Date:
                        <span className="ps-4">{currentDate}</span>
                     </p>
                  </div>
               </section>



               {/* <!-- page-3 --> */}
               <section className=" hide " ref={pdfRef_2}>
                  <div className="container-1">


                     <div className="pt-3">
                        <h4 className="text-center"> FORM OF AUTHORITY </h4>
                        <table aria-describedby="table-12" className="detail border-0">
                           {/* <thead style="display: none;">
                        <th></th>
                     </thead> */}
                           <tbody>
                              <tr>
                                 <td className="td1 border-0">
                                    <p>
                                       <b>Name of account holder:</b>
                                    </p>
                                 </td>
                                 <td className="td2 border-0">
                                    <p>Mr {userName}</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td className="td1 border-0">
                                    <p>
                                       <b>Current address:</b>
                                    </p>
                                 </td>
                                 <td className="td2 border-0">
                                    <p dangerouslySetInnerHTML={{ __html: address }} />
                                 </td>
                              </tr>
                              <tr>
                                 <td className="td1 border-0">
                                    <p>
                                       <b>Previous address(s):</b>
                                    </p>
                                 </td>
                                 <td className="td2 border-0">
                                    <p dangerouslySetInnerHTML={{ __html: previousAddress }} />
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <p>
                           I appoint and authorise Clear Legal Marketing Limited to consider my claim for the mis-selling of my loan facility in accordance with DISP 2.2 of the FCA Handbook. Clear Legal Marketing Limited is regulated by the FCA under FCA Number 833090.
                        </p>
                        <p>
                           I also authorise and insist that you, <i>LendingStream</i>, release to Clear Legal Marketing without delay any information or records they ask for, in accordance with the 'Rights of Data Subjects and Others' under the Data Protection Act 2018.
                        </p>
                        <p>
                           In the event that my complaint is successful and compensation is awarded to me, I authorise and insist that you <i>LendingStream</i>, release the compensation payment funds to Clear Legal Marketing Limited without delay.
                        </p>
                        <p>
                           I request that all responses are returned directly to Clear Legal Marketing.
                        </p>
                        <p>
                           <b>Signature</b>
                        </p>
                        <p ref={signRef_2}>
                           <img src={userSign} alt="" className="w-25" />
                        </p>
                        <p>
                           <b>Date:</b>
                           <span className="ps-4">{currentDate}</span>
                        </p>
                        <p className="pb-5">
                           <b>Date of Birth:</b>
                           <span className="ps-4">{userDob}</span>
                        </p>
                     </div>
                  </div>
                  <div className="container-1 my-5">
                     <h4 className="text-center"> Document Tracking </h4>

                     <table className="table-1a" style={{ paddingTop: '50px' }}>
                        <tbody>
                           <tr>
                              <td style={{ width: '50%' }}> <p>document ID:</p></td>
                              <td> <p>{userId}</p></td>
                           </tr>
                           <tr>
                              <td> <p> Website URL:</p></td>
                              <td><a href="https://unaffordableloans.legal/">https://unaffordableloans.legal</a></td>
                           </tr>
                           <tr>
                              <td> <p>User Email:</p></td>
                              <td><a >{userEmail}</a></td>
                           </tr>
                           <tr>
                              <td><p> document Created</p></td>
                              <td><p>{currentDateTime}<br />
                                 {ipAddress}</p></td>
                           </tr>
                           <tr>
                              <td> <p>document Viewed by {userName}</p></td>
                              <td>
                                 <p>{currentSignDateTime}<br />
                                 {ipAddress}</p>
                              </td>
                           </tr>
                           <tr>
                              <td> <p>document Signed by {userName}</p></td>
                              <td>
                                 <p>{currentSignDateTime}<br />
                                 {ipAddress}</p>
                              </td>
                           </tr>
                           <tr>
                              <td> <p>document Complete</p></td>
                              <td>
                                 <p>{currentDateTime} <br />
                                 {ipAddress}</p>
                              </td>
                           </tr>
                        </tbody>
                     </table>

                  </div>
               </section>



               {/* <!-- page-4 --> */}
               <div className=" hide" ref={pdfRef_3}>
                  <section className="container-1 mb-5" >
                     <div className="pt-3">
                        <h4 className="text-center"> Non-Contentious Business Agreement </h4>
                        <h4 className="text-center"> Irresponsible Lending Claims </h4>
                        <p>This is your agreement with Clear Legal Marketing Limited in relation to your Irresponsible Lending
                           claims. </p>
                        <p>What we will do for you </p>
                        <ol>
                           <li>
                              We will work diligently on your behalf, no matter how straightforward or difficult your claim,
                              from the day we first discuss it with you until your compensation and fees have been paid.
                              This work will be limited to pursuing your claim for all losses suffered by you as a result of the
                              mis-sale of your loan or credit facility.
                           </li>
                           <li>
                              If you win your claim, we are entitled to deduct an amount from your Compensation, in
                              respect of our fees
                           </li>
                           <li>
                              The amount we are entitled to deduct will be based on the Total Compensation you receive
                              and will be calculated by reference to the Charging Table in Clause 5 and will either be a sum
                              equivalent to the ‘Maximum % Rate of Charge’ or a sum equivalent to the ‘Maximum Total
                              Fee’, which ever is the lesser. However, the amount you pay us will never exceed the total
                              cash compensation you receive.
                           </li>
                           <li>
                              If you lose your claim, and this agreement has been complied with, you do not have to pay us
                              anything.
                           </li>
                           <li>
                              Total Compensation’ means any direct monetary payment or balance reduction made by
                              your opponent in respect of your claim. It includes both the cash and balance reduction
                              elements of a refund. For Example, if you are award £1000 refund but have a £500 balance
                              and as a result receive £500 cash plus a reduction in your balance of £500, we will charge our
                              fee based on £1000.
                           </li>
                           <li>
                              Charging Table:
                           </li>
                        </ol>
                        <table aria-describedby="table-15">

                           <tbody>
                              <tr>
                                 <th> Band </th>
                                 <th> Total Compensation</th>
                                 <th> Maximum % rate of charge (Inc VAT)</th>
                                 <th> Maximum total fee (Inc VAT) </th>
                              </tr>
                              <tr>
                                 <td> 1</td>
                                 <td> £1 - £1,499 </td>
                                 <td>36% </td>
                                 <td>£504.00 </td>
                              </tr>
                              <tr>
                                 <td> 2</td>
                                 <td> £1,500 - £9,999</td>
                                 <td>33.6% </td>
                                 <td> £3,000.00</td>
                              </tr>
                              <tr>
                                 <td> 3</td>
                                 <td> £10,000 - £24,999 </td>
                                 <td> 30% </td>
                                 <td>£6,000.00  </td>
                              </tr>
                              <tr>
                                 <td> 4</td>
                                 <td>£25,000 - £49,999 </td>
                                 <td> 24% </td>
                                 <td> £9,000.00</td>
                              </tr>
                              <tr>
                                 <td> 5</td>
                                 <td> £50,000 or above </td>
                                 <td> 18% </td>
                                 <td> £12,000.00 </td>
                              </tr>
                           </tbody>
                        </table>
                        <p className="mt-3"> Please note: both the ‘Maximum % Rate’ and the ‘Maximum Total Fee’ include VAT at the prevailing rate of 20%. </p>
                     </div>
                  </section>

                  <section className="container-1 mb-5">
                     <div className="py-3">
                        <p> What you must do for us </p>
                        <ol>
                           <li value="7">
                              You must do all you can to help us to progress your claim. You must provide us with honest
                              instructions that enable us to do our work properly and to run your claim efficiently.
                              <br />
                              <br />
                              Please sign, to confirm you understand the contents of this document, that you agree to be bound
                              by this Agreement, and in the event your claim is successful you will pay us an amount of your
                              Compensation in line with the Charging Table:
                           </li>
                        </ol>
                        <table aria-describedby="table-17" className="border-0">

                           <tbody>
                              <tr ref={signRef_3}>
                                 <td className="border-0 fw-normal"> Signature:</td>
                                 <td className="border-0"> <img src={userSign} className="w-25" alt="" /></td>
                              </tr>
                              <tr>
                                 <td className="border-0"> Date: </td>
                                 <td className="border-0"> {currentDate}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </section>


                  <section className="container-1 mb-5" >
                     <div className="pt-3">
                        <h4 className="text-start"> And now for the (not so) small print </h4>
                        <p>When you instruct Clear Legal Marketing, we will agree to act for you on a risk-free basis.
                           If you win your claim: </p>
                        <ul>
                           <li>we will charge you a percentage from your Total Compensation in respect of our fees,
                              irrespective of the amount of work we have done; </li>
                           <li> the total amount we charge will be based on the Total Compensation you receive and it will be
                              calculated by reference to the Charging Table above. We will only charge you the 'Maximum %
                              rate of Charge’ or the ‘Maximum Total Fee’, whichever is the lesser. </li>
                           <li> In addition, the amount we will charge you will never exceed the total amount of cash
                              compensation you receive.</li>
                           <li> we will pay any money we receive on your behalf into a designated account, to make sure that
                              your Compensation is secure; </li>
                        </ul>
                        <h4 className="text-start"> Possible Outcomes</h4>
                        <p> There are 4 potential outcomes on your case. The amount we charge will be dependent on the
                           outcome:-</p>
                        <ul>
                           <li><b>Full Cash Refund</b> – this means that you receive 100% of the Total Compensation from the
                              Lender in cash. We will charge you based on the Total Compensation in accordance with the
                              Charging Table above. </li>
                           <li>
                              <b> Cash and Offset Refund</b> – this means that you have an outstanding balance with the lender
                              which is less than the Total Compensation awarded. As a result, you receive both a balance
                              reduction AND a cash award. We will charge you on the Total Compensation in accordance with
                              the Charging Table above, however, we will cap our charges at no more than 100% of the Cash
                              Refund element of your award.
                           </li>
                           <li>
                              <b>Offset Refund</b> – this means that you have an outstanding balance with the lender which is
                              greater than the Total Compensation awarded. As a result, the full amount of the Total
                              Compensation reduces your balance and you receive no physical cash. We will NOT charge you
                              in this scenario.
                           </li>
                           <li>
                              <b>Failed complaint</b> – this means your complaint has not been upheld and as a result no
                              Compensation is awarded. We will NOT charge you in this scenario.
                           </li>
                        </ul>
                        <h4 className="text-start"> Worked Examples</h4>
                        <p className="text-decoration-underline"> Full Cash Refund</p>
                        <table aria-describedby="table-18" className="border-0">

                           <tbody>
                              <tr>
                                 <td className="border-0">
                                    <table aria-describedby="table-21">

                                       <tbody>
                                          <tr>
                                             <th> Cash Refund Received</th>
                                             <th> Our Fee </th>
                                             <th>  You Receive</th>
                                          </tr>
                                          <tr>
                                             <td> £749.50 </td>
                                             <td> £269.82 </td>
                                             <td>  £479.68 </td>
                                          </tr>
                                          <tr>
                                             <td>£4,999.50</td>
                                             <td> £1,679.83 </td>
                                             <td> £3,319.67</td>
                                          </tr>
                                          <tr>
                                             <td> £12,499.50</td>
                                             <td> £3,749.85</td>
                                             <td> £8,749.65</td>
                                          </tr>
                                          <tr>
                                             <td> £24,999.50</td>
                                             <td> £5,999.88</td>
                                             <td> £18,999.62</td>
                                          </tr>
                                          <tr>
                                             <td>£50,000.00</td>
                                             <td> £9,000.00 </td>
                                             <td> £41,000.00</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </td>
                                 {/* <td className="border-0">
                  <p className="text-decoration-underline"> Cash and Offset Refund</p>
               </td> */}
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </section>


                  <section className="container-1 mb-5" >
                     <div className="pt-3">
                        <div style={{ "overflow": "auto" }}>
                           <p className="text-decoration-underline"> Cash and Offset Refund</p>
                           <table aria-describedby="table-18">

                              <tbody>
                                 <tr>
                                    <th> Total Compensation</th>
                                    <th> Cash Refund Elemen</th>
                                    <th> Balance Reduction Element</th>
                                    <th> Our Fee</th>
                                    <th> You Receive</th>
                                 </tr>
                                 <tr>
                                    <td> £749.50  </td>
                                    <td> £374.75</td>
                                    <td> £374.75</td>
                                    <td> £269.82 </td>
                                    <td>
                                       £104.93 plus 100% write off your
                                       remaining balance
                                    </td>
                                 </tr>
                                 <tr>
                                    <td> £4,999.50</td>
                                    <td> £2,499.75</td>
                                    <td> £2,499.75</td>
                                    <td> £1,679.83</td>
                                    <td> £819.918 plus 100% write off your
                                       remaining balance</td>
                                 </tr>
                                 <tr>
                                    <td>£12,499.50 </td>
                                    <td> £6,249.75</td>
                                    <td> £6,249.75</td>
                                    <td> £3,749.85</td>
                                    <td>£499.9 plus 100% write off your
                                       remaining balance</td>
                                 </tr>
                                 <tr>
                                    <td>£24,999.50</td>
                                    <td>£12,499.75</td>
                                    <td>£12,499.75</td>
                                    <td> £5,999.88</td>
                                    <td> £6499.87 plus 100% write off your
                                       remaining balance</td>
                                 </tr>
                                 <tr>
                                    <td>£50,000.00</td>
                                    <td> £25,000.00</td>
                                    <td> £25,000.00</td>
                                    <td> £9,000.00</td>
                                    <td> £16000 plus 100% write off your
                                       remaining balance</td>
                                 </tr>
                                 <tr>
                                    <td> £1,499</td>
                                    <td> £149.90</td>
                                    <td> £1,349.10</td>
                                    <td> £149.90</td>
                                    <td> £0 plus 100% write off your
                                       remaining balance</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <p className="mt-5"> If you lose your claim:</p>
                        <ul>
                           <li>you do not have to pay us anything</li>
                        </ul>
                        <p>
                           For the definitions of “Win”, “Lose”, and “Compensation” please refer to your client care letter that will
                           be sent to you once you have signed and returned this agreement.
                        </p>
                        <p>
                           What you must do for us
                        </p>
                        <ul>
                           <li>You must give us full, honest and timely instructions; </li>
                           <li> You must locate and provide to us any relevant documentation that you may have (if any);</li>
                           <li> You must undertake enquiries and provide information reasonably required by us; </li>
                           <li> You must always co-operate with us;</li>
                           <li> You must not ask us to work in an improper or unreasonable way; </li>
                           <li> You must not deliberately mislead us;</li>
                           <li> You must keep us updated if your contact details change.</li>
                        </ul>
                        <h4> Ending the agreement </h4>
                        <p>We can end the agreement: </p>
                        <ul>
                           <li>if you reject our advice in relation to an offer of settlement, or if you do not comply with your
                              obligations under the agreement and subsequently go to settle your case directly with the
                              lender. In this situation you will be liable to pay our full fee in line with the Charging Table
                              should your claim be successful.</li>
                           <li>
                              if we advise that your claim is unlikely to succeed; You can end the agreement:
                           </li>
                           <li>
                              within fourteen days by confirming this to us in writing using the Model Cancellation Form
                              below;
                           </li>
                           <li>
                              at any time before your claim is settled, but if you do you will be liable to pay our full fee in line
                              with the Charging Table should your claim be successful.
                           </li>
                        </ul>
                        <p>
                           If either we or you end the agreement, we have the right to keep all documents, money and other
                           property we hold on your behalf until all money due to us is paid.
                        </p>
                     </div>
                  </section>




                  <section className="container-1 my-5">

                     <div className="pt-3">
                        <h4 > Model Cancellation Form</h4>
                        <p>To Clear Legal Marketing</p>
                        <p>I hereby give notice that I cancel the Agreement for Clear Legal Marketing to pursue my mis-sold Loan
                           Refund Complaints on my behalf signed on [&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; ].
                        </p>
                        <p className="mt-5"> Name: </p>

                        <p className="mt-5">Address:</p>

                        <p className="mt-5"> Signature: </p>

                        <p className="mt-5"> Date: </p>

                        <p className="mt-5">Change of mind </p>
                        <p className="mt-5 pb-5">
                           It is important that you are happy with this agreement before you sign it as we cannot cancel it once it
                           has been signed.
                        </p>
                     </div>
                  </section>
                  <br />
               </div>
               {/* <!-- footer-nxtpage --> */}
               <footer className="d-flex justify-content-center mt-5 bg-dark p-2">
                  <button className="btn btn-success px-3" onClick={() => { setPdfPageNo(parseInt(pdfPageNo + 1)) }}>{(pdfPageNo < "3") ? "Next" : "Finish"}</button>
               </footer>
            </div>
         </div>
      </>
   )
}
export default PreviewLoa;