import React,{useState} from "react";
import { Modal } from "react-bootstrap";
const UnqualifiedConfirmationPop = (props) => {
    const question_id = props.questionnaire.questionId;
    const radioName = 'question_' + question_id;
    const [showError, setShowError] = useState('d-none');
    const validateRadio = async (e) => {
        e.preventDefault();
        let radioChecked = await props.trigger(radioName);
        if (radioChecked) {
            props.slideChange(e, radioName);
        } else {
            setShowError('d-block');
        }
    };
    return (
        <>
        <Modal centered show={props.popDisplay} onHide={props.handleClose} dialogClassName="modalReact" id="model5" backdrop="static"
            keyboard={false}>
            <div className="Questionnaire_CL_LS_V7" id="model2" backdrop="static"  >
                <div className="">
                    <div className=" popup-style p-4">
                        <div className=" text-center">
                            <Modal.Body>
                                <h1>We encourage you to review your loan selections and ensure that you meet the eligibility criteria</h1>
                                <p className="color_pink">if you choose fewer than five loans, you will not be eligible to make a claim at this time.</p>
                                <div className="d-flex btn-group justify-content-center mt-3 ques1-btn">
                                    {props.questionnaire.answers.map((item, index) => {
                                        return (
                                            <div className="option-btn" key={index}>
                                                <input type="radio"
                                                    className="next-2"
                                                    id={'pop_question_' + question_id + '_' + item.value}
                                                    name={'question_' + question_id}
                                                    value={item.value}
                                                    data-answertext={item.label}
                                                    data-question_id={question_id}
                                                    ref={props.validation({ required: "Please Select" })}
                                                    onClick={(e) => { props.setConfirmPopShow(false); props.slideChange(e, e.target.name);localStorage.setItem('popVal',e.target.value) }} />
                                                <label htmlFor={'pop_question_' + question_id + '_' + item.value}
                                                    className="btn-upload slide_next"
                                                >
                                                    {item.label}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Modal.Body>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        </>
    )
}
export default UnqualifiedConfirmationPop;