import { Link } from "react-router-dom";
import solicitors from "../../../../assets/img/solicitors.jpg"
const Footer = () => {
    return (
        <>
            <div className="CL_LS_V3">
                <footer className="py-3 py-lg-4 py-md-4  py-sm-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <ul className="mb-3 ft-menu ">
                                    <li className="footer-link ">
                                        <Link to="/PrivacyPolicy" target="_blank">Privacy Policy</Link>
                                    </li>
                                    <li className="footer-link ">
                                        <Link to="/VulnerablePersonsPolicy" target="_blank">Vulnerable Persons Policy</Link>
                                    </li>
                                    <li className="footer-link ">
                                        <Link to="/ComplaintsProcedure" target="_blank">Complaints Procedure</Link>
                                    </li>
                                    <li className="footer-link ">
                                        <Link to="/PreContractInfo" target="_blank">Pre Contract Info</Link>
                                    </li>
                                    <li className="footer-link ">
                                        <Link to="/Terms_and_Conditions" target="_blank">Terms and Conditions</Link>
                                    </li>
                                </ul>
                                {/* <p className="m-0"> unaffordableloans.legal  is a trading style of Clear Law LLP registered in England & Wales under company number 0C308339. </p>
                            <p>Clear Law LLP is authorised and regulated by the Solicitors Regulation Authority under SRA number 403088.</p>
                            <p className="  ">Units 115-119, Timber Wharf, 42-50 Worsley Street, Manchester, M15 4LD</p> */}
                                <p className="m-0">complaints.loans is a trading name of Clear Legal Marketing Limited, a Claims Management Company, (registered number 07933243) whose registered address is Unit 115-119 Timber Wharf, 42-50 Worsley Street, Manchester M15 4LD and who are Regulated by the Financial Conduct Authority.
                                    Its registration is recorded on the website<span style={{ padding: "2px" }}></span><a href="https://register.fca.org.uk." target="_blank">https://register.fca.org.uk.</a> Reg No: 833090, Registered with the Information Commissioners Office. Reg No: Z3370717</p>
                                    <br/>
                                <p className="  ">All calls to Clear Legal Marketing Ltd are recorded in line with the FCA requirements</p>
                                <p>*Average refund of £1450.97 is taken from refunds settled by Clear Legal Marketing Limited between May 2022 and April 2023.</p>

                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}
export default Footer;