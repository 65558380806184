import React from "react";
import { Modal } from "react-bootstrap";
import loadingImg from "../../../../assets/img/loading.gif"
function SignLoader(props) {
  return (
    <>
      <div
        className="modal"
        id="modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        style={{
          zIndex: "999999",
          display: "block",
          background: "#16162b",
        }}
      >
        <div className="modal-dialog modal-dialog-centered justify-content-center modal-lg">
          <div className={`cube text-center ${props.imageShow}`}>
            <img src={loadingImg} alt="" />
          </div>
          {props.popupLoading && <div className="modal-content">
            <div className="modal-body">
              <div className="newscontainer text-center">
                <p className="title title1">Great news..!!</p>
              </div>
              <div className="newscontainer text-center">
                <p className="title title2">It appears you may have successful claim</p>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>

  )
}

export default SignLoader