import { useHistory } from "react-router-dom";

export const FormData = () => {
  const history = useHistory();
  const setFormData = (response) => {
    if (response.data.response.length) {
      var userDob = response.data.response[0].user_dob.split('/');
      var DobDay = userDob[0];
      var DobMonth = userDob[1];
      var DobYear = userDob[2];
      var spouseDob = response.data.response[0].spouse_dob ? response.data.response[0].spouse_dob.split('/') : '';
      var JointDobDay = spouseDob ? spouseDob[0] : "";
      var JointDobMonth = spouseDob ? spouseDob[1] : "";
      var JointDobYear = spouseDob ? spouseDob[2] : "";
      var months = { "01" : "January", "02" : "February", "03" : "March", "04" : "April", "05" : "May", "06" : "June", "07" : "July", "08" : "August", "09" : "September", "10" : "October", "11" : "November", "12" : "December" };
      var formDataParameters = {
        "txtFName": (response.data.response[0].first_name) ? response.data.response[0].first_name : '',
        "txtLName": (response.data.response[0].last_name) ? response.data.response[0].last_name : '',
        "dobDay": DobDay,
        "dobMonth": months[DobMonth],
        "dobYear": DobYear,
        "JointDobDay": JointDobDay,
        "JointDobMonth": JointDobMonth,
        "JointDobYear": JointDobYear,
        "txtHouseName": (response.data.response[0].housename) ? response.data.response[0].housename : '',
        "txtHouseNumber": (response.data.response[0].address_line1) ? response.data.response[0].address_line1 : '',
        "txtPostCode": (response.data.response[0].postcode) ? response.data.response[0].postcode : '',
        "txtCounty": (response.data.response[0].county) ? response.data.response[0].county : '',
        "txtCountry": (response.data.response[0].country) ? response.data.response[0].country : '',
        "txtStreet": (response.data.response[0].address_line3) ? response.data.response[0].address_line3 : '',
        "txtTown": (response.data.response[0].town) ? response.data.response[0].town : '',
        "user_sign": (response.data.response[0].signature_image) ? response.data.response[0].signature_image : '',
        "partner_sign": (response.data.response[0].spouse_sign) ? response.data.response[0].spouse_sign : '',
        "jointFName": (response.data.response[0].spouses_first_name) ? response.data.response[0].spouses_first_name : '',
        "jointLName": (response.data.response[0].spouses_last_name) ? response.data.response[0].spouses_last_name : '',
        "txtEmail": (response.data.response[0].email) ? response.data.response[0].email : '',
        "txtPhone": (response.data.response[0].telephone) ? response.data.response[0].telephone : '',
        "question_3": (response.data.response[0].is_joint == '1') ? '5' : '6',
        "page_name": (response.data.response[0].page_name) ? response.data.response[0].page_name : '',
        "bank": (response.data.response[0].bank_id) ? response.data.response[0].bank_id : '',
        "address2":(response.data.response[0].address_line2) ? response.data.response[0].address_line2 : '',
        "txtPrevHouseNumber_1": (response.data.response[1] && response.data.response[1].address_line1) ? response.data.response[1].address_line1 : '',
        "txtPrevroute_1": (response.data.response[1] && response.data.response[1].address_line2) ? response.data.response[1].address_line2 : '',
        "txtPrevStreet_1": (response.data.response[1] && response.data.response[1].address_line3) ? response.data.response[1].address_line3 : '',
        "txtPrevCountry_1": (response.data.response[1] && response.data.response[1].country) ? response.data.response[1].country : '',
        "previous_address_province": (response.data.response[1] && response.data.response[1].previous_address_country) ? response.data.response[1].previous_address_country : '',
        "txtPrevPostCode_1": (response.data.response[1] && response.data.response[1].postcode) ? response.data.response[1].postcode : '',
        "current_sign_time":(response.data.response[0] && response.data.response[0].sign_date_time) ? response.data.response[0].sign_date_time : ''
      };
      const fData = JSON.stringify(formDataParameters);
      localStorage.setItem('formData', fData);
      localStorage.setItem('bankName', response.data.response[0].bank_name);
      localStorage.setItem('sort_code', response.data.response[0].sort_code);
      localStorage.setItem('account_number', response.data.response[0].account_number);
      let customData = {
        "txtPrevHouseNumber_1": (response.data.response[1] && response.data.response[1].address_line1) ? response.data.response[1].address_line1 : '',
        "txtPrevroute_1": (response.data.response[1] && response.data.response[1].address_line2) ? response.data.response[1].address_line2 : '',
        "txtPrevStreet_1": (response.data.response[1] && response.data.response[1].address_line3) ? response.data.response[1].address_line3 : '',
        "txtPrevCountry_1": (response.data.response[1] && response.data.response[1].country) ? response.data.response[1].country : '',
        "previous_address_province": (response.data.response[1] && response.data.response[1].previous_address_country) ? response.data.response[1].previous_address_country : '',
        "txtPrevPostCode_1": (response.data.response[1] && response.data.response[1].postcode) ? response.data.response[1].postcode : ''
      }
      let customPreData = JSON.stringify(customData);
      localStorage.setItem('customPreviousData', customPreData);
    }
    else {
      history.push("/404");
    }
  }
  return { setFormData }
}
