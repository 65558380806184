import React, { useEffect } from 'react';
import Header from '../../Includes/Layouts/common/Header';
import Footer from '../../Includes/Layouts/common/Footer';

const FollowupUnqualified = () => {
   
   return (
      <>
         <div className="CL_LS_V3 success">
            <div className="bg">
               <div className="clearfix"></div>
               <Header />
               <section className="main-start bg text-center "></section>
               <section>
                  <div className="container success-area">
                     <div className="mt-4 text-center" >
                       <h4>Thank you for your enquiry, having reviewed the information you provided, we are sorry to inform you that we are unable to act for you in relation to your claim.</h4>
                     </div>
                  </div>
               </section>
               <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next"></section>
               <Footer />
            </div>
         </div>
    </>
  )
}
export default FollowupUnqualified;
