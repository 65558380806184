import React, { useRef, useState, useContext, useEffect, } from "react";

const QuestionRadioSlide = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const [showError, setShowError] = useState('d-none');
  const validateRadio = async (e) => {
    e.preventDefault();
    let radioChecked = await props.trigger(radioName);
    if (radioChecked) {
      props.slideChange(e, radioName);
    } else {
      setShowError('d-block');
    }
  };
  return (
    <>
      <div className={`slide-top ${props.showQuestionSlide}`}>
        <h1 className="title">
          {props.questionnaire.question}
        </h1>
        <p>
        {(props.questionnaire.sub_question != "")? props.questionnaire.sub_question:""}
        </p>
        <div className="d-flex btn-group justify-content-center mt-3 ques1-btn">
          {props.questionnaire.answers.map((item, index) => {
            return (
              <div className="option-btn" key={index}>
                <input type="radio" 
                  id={'question_' + question_id+'_'+item.value}
                  name={'question_' + question_id}
                  value={item.value} data-answertext={item.label}
                  data-question_id={question_id}
                  data-nextquestion={props.nextQuestion}
                  ref={props.validation({ required: "Please Select" })}
                  onClick={(e) => { props.slideChange(e, radioName) }}
                />

                <label htmlFor={'question_' + question_id+'_'+item.value}
                  className="btn-upload slide_next"
                >
                  {item.label}
                </label>
              </div>
            );
          })}

        </div>
        <div className="d-flex justify-content-center">
        <span className={`errormsg1 ${showError}`}>Please Select</span>
        </div>
        
        <div className="mt-4 d-flex justify-content-center controle_btn">
          {props.backBtn && <button type="button" className="com_bnt me-3 back2" name={`back${question_id}`} onClick={props.previousSlide}>
            <i className="bi bi-arrow-left" /> Back{" "}
          </button>}
          {/* <button className="com_bnt next1" name={`next${question_id}`} 
          data-question_id={question_id} onClick={validateRadio}>
            {" "}
            Next <i className="bi bi-arrow-right" />
          </button> */}
        </div>
      </div>
    </>
  )
};

export default QuestionRadioSlide;
