//import {useState} from 'react';
import { Api, CommonEndApi } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";
import * as EnvConstants from "../Constants/EnvConstants";

export const usePostCodeAddress = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getPostcodeAddress = async (postcode) => {
    postcode = postcode.replace(/\s/g, "");
    const response = await CommonEndApi.get(
      "api/get-addr-list?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&postcode=' + postcode,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'GET POSTCODE ADDRESS LIST',
        errorReason: error.message,
        errorCode: error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  const getSplitPostcodeAddress = async (AddressID, postcode) => {
    const response = await CommonEndApi.get(
      "api/get-addr-details?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&addressId=' + AddressID,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'GET POSTCODE ADDRESS DETAILS',
        errorReason: error.message,
        errorCode: error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { getPostcodeAddress, getSplitPostcodeAddress };
};
