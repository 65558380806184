import React from "react";
import Header from "./Header";
import Footer from "./Footer";
const Terms_and_Conditions = () => {
    return (
        <>
            <div className="terms_and_condition">
                <body className="bg">
                    <div className="clearfix"></div>
                    <Header />
                    <section className="main-start bg text-center mb-5">
                        <h1>Terms and Conditions</h1>
                        <h4>Welcome to  Clear Legal Marketing Limited's Terms and Conditions</h4>
                    </section>
                    <div className="form-wrap">
                        <div className="container pb-4">
                            <h5 className="text-center">Non-Contentious Business Agreement </h5>
                            <h5 className="text-center"> Irresponsible Lending Claims </h5>
                            <p style={{ marginbottom: "26px" }}>
                                This is your agreement with Clear Legal Marketing Limited in relation to your Irresponsible Lending
                                claims.
                            </p>
                            <p>
                                What we will do for you
                            </p>

                            <ol style={{ paddingleft: "5%", paddingright: "5%" }}>
                                <li className="ps-10">
                                    &nbsp;We will work diligently on your behalf, no matter how straightforward or difficult your claim,
                                    from the day we first discuss it with you until your compensation and fees have been paid.
                                    This work will be limited to pursuing your claim for all losses suffered by you as a result of the
                                    mis-sale of your loan or credit facility.
                                </li>

                                <li className="ps-10">
                                    &nbsp;If you win your claim, we are entitled to deduct an amount from your Compensation, in
                                    respect of our fees.
                                </li>

                                <li className="ps-10">
                                    &nbsp;The amount we are entitled to deduct will be based on the Total Compensation you receive
                                    and will be calculated by reference to the Charging Table in Clause 5 and will either be a sum
                                    equivalent to the ‘Maximum % Rate of Charge’ or a sum equivalent to the ‘Maximum Total
                                    Fee’, which ever is the lesser. However, the amount you pay us will never exceed the total
                                    cash compensation you receive.
                                </li>

                                <li className="ps-10">
                                    &nbsp;If you lose your claim, and this agreement has been complied with, you do not have to pay us
                                    anything.
                                </li>

                                <li className="ps-10">
                                    &nbsp;‘Total Compensation’ means any direct monetary payment or balance reduction made by
                                    your opponent in respect of your claim. It includes both the cash and balance reduction
                                    elements of a refund. For Example, if you are award £1000 refund but have a £500 balance
                                    and as a result receive £500 cash plus a reduction in your balance of £500, we will charge our
                                    fee based on £1000.
                                </li>

                                <li className="ps-10">
                                    &nbsp;Charging Table:
                                </li>
                            </ol>
                            <div className="scroll-table1">
                                <table className="table_charging w-100" aria-describedby="ex01">
                                    <thead>
                                        <th className="d-none"></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> Band </td>
                                            <td> Total Compensation</td>
                                            <td> Maximum % rate of charge(Inc VAT) </td>
                                            <td> Maximum total fee (IncVAT) </td>
                                        </tr>
                                        <tr>
                                            <td> 1</td>
                                            <td> £1 - £1,499 </td>
                                            <td> 36% </td>
                                            <td>£504.00 </td>
                                        </tr>
                                        <tr>
                                            <td> 2</td>
                                            <td> £1,500 - £9,999 </td>
                                            <td> 33.6% </td>
                                            <td> £3,000.00 </td>
                                        </tr>
                                        <tr>
                                            <td> 3</td>
                                            <td> £10,000 - £24,999 </td>
                                            <td> 30% </td>
                                            <td> £6,000.00</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td> £25,000 - £49,999 </td>
                                            <td> 24% </td>
                                            <td> £9,000.00</td>
                                        </tr>
                                        <tr>
                                            <td> 5</td>
                                            <td> £50,000 or above </td>
                                            <td> 18%</td>
                                            <td> £12,000.00 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style={{ margintop: "24px" }} className="ps-10">
                                Please note: both the ‘Maximum % Rate’ and the ‘Maximum Total Fee’ include VAT at the prevailing
                                rate of 20%.
                            </p>



                            <table className="main-table" aria-describedby="ex03">
                                <thead>
                                    <th className="d-none"></th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="text-center fs-6">What you must do for us</p>
                                            <table className="table321" aria-describedby="ex02">
                                                <thead>
                                                    <th className="d-none"></th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "5%", verticalAlign: "top" }}>
                                                            <p>7.</p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                You must do all you can to help us to progress your claim. You must provide us with
                                                                honest
                                                                instructions that enable us to do our work properly and to run your claim
                                                                efficiently.
                                                            </p>
                                                            <p>
                                                                Please sign, to confirm you understand the contents of this document, that you agree
                                                                to be
                                                                bound by this Agreement, and in the event your claim is successful you will pay us
                                                                an
                                                                amount of your Compensation in line with the Charging Table:
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p style={{ paddingTop: '25px' }}>
                                                <b>Signature</b>
                                            </p>

                                            <p style={{ paddingTop: "15px" }}>
                                                <b>Date :</b>
                                            </p>

                                            <p style={{ paddingTop: "40px" }}>
                                                <b>And now for the (not so) small print</b>
                                            </p>
                                            <p className="m-0">
                                                When you instruct Clear Legal Marketing, we will agree to act for you on a risk-free basis.
                                                If you win your claim:
                                            </p>

                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            {/* <!----------------- page 2 ----------------- --> */}

                            <table aria-describedby="ex04">
                                <thead className="d-none">
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ul className="new-list">
                                                <li>we will charge you a percentage from your Total Compensation in respect of our fees,
                                                    irrespective of the amount of work we have done; </li>
                                                <li>
                                                    the total amount we charge will be based on the Total Compensation you receive and it will be
                                                    calculated by reference to the Charging Table above. We will only charge you the 'Maximum %
                                                    rate of Charge’ or the ‘Maximum Total Fee’, whichever is the lesser.
                                                </li>
                                                <li>
                                                    In addition, the amount we will charge you will never exceed the total amount of cash
                                                    compensation you receive.
                                                </li>
                                                <li>
                                                    we will pay any money we receive on your behalf into a designated account, to make sure that
                                                    your Compensation is secure;
                                                </li>
                                            </ul>
                                            <h5> Possible Outcomes</h5>
                                            <p> There are 4 potential outcomes on your case. The amount we charge will be dependent on the
                                                outcome:-</p>
                                            <ul className="new-list">
                                                <li> <b>Full Cash Refund</b> – this means that you receive 100% of the Total Compensation from
                                                    the
                                                    Lender in cash. We will charge you based on the Total Compensation in accordance with the
                                                    Charging Table above.</li>
                                                <li>
                                                    <b>Cash and Offset Refund </b> – this means that you have an outstanding balance with the
                                                    lender
                                                    which is less than the Total Compensation awarded. As a result, you receive both a balance
                                                    reduction AND a cash award. We will charge you on the Total Compensation in accordance with
                                                    the Charging Table above, however, we will cap our charges at no more than 100% of the Cash
                                                    Refund element of your award.
                                                </li>
                                                <li>
                                                    <b> Offset Refund</b> – this means that you have an outstanding balance with the lender which
                                                    is
                                                    greater than the Total Compensation awarded. As a result, the full amount of the Total
                                                    Compensation reduces your balance and you receive no physical cash. We will NOT charge you
                                                    in this scenario.
                                                </li>
                                                <li>
                                                    <b>Failed complaint</b> – this means your complaint has not been upheld and as a result no
                                                    Compensation is awarded. We will NOT charge you in this scenario.
                                                </li>
                                            </ul>
                                            <h5> Worked Examples</h5>

                                            <p className="text-decoration-underline"> Full Cash Refund</p>

                                            <table aria-describedby="ex-09" className="mb-0">
                                                <thead className="d-none">
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <table className="table-full-cash-refund" aria-describedby="ex-06">
                                                                <thead className="d-none">
                                                                    <th></th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td> Cash Refund Received </td>
                                                                        <td> Our Fee </td>
                                                                        <td> You Receive</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> £749.50</td>
                                                                        <td> £269.82 </td>
                                                                        <td> £479.68</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> £4,999.50 </td>
                                                                        <td> £1,679.83 </td>
                                                                        <td> £3,319.67</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> £12,499.50</td>
                                                                        <td> £3,749.85 </td>
                                                                        <td> £8,749.65</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> £24,999.50</td>
                                                                        <td> £5,999.88 </td>
                                                                        <td> £18,999.62 </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> £50,000.00 </td>
                                                                        <td> £9,000.00</td>
                                                                        <td> £41,000.00</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <p className="text-decoration-underline"> Cash and Offset Refund</p>

                                            <table className="table_total_compensation" aria-describedby="ex-003">
                                                <thead className="d-none">
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "15%" }}> Total Compensation</td>
                                                        <td style={{ width: "15%" }}> Cash Refund Element</td>
                                                        <td style={{ width: "15%" }}> Balance Reduction Element</td>
                                                        <td style={{ width: "15%" }}> Our Fee</td>
                                                        <td> You Receive </td>
                                                    </tr>
                                                    <tr>
                                                        <td> £749.50 </td>
                                                        <td> £374.75</td>
                                                        <td> £374.75</td>
                                                        <td> £269.82</td>
                                                        <td> £104.93 plus 100% write off your
                                                            remaining balance</td>
                                                    </tr>
                                                    <tr>
                                                        <td>£4,999.50 </td>
                                                        <td> £2,499.75 </td>
                                                        <td> £2,499.75 </td>
                                                        <td> £1,679.83 </td>
                                                        <td> £819.918 plus 100% write off your
                                                            remaining balance</td>
                                                    </tr>
                                                    <tr>
                                                        <td> £12,499.50 </td>
                                                        <td> £6,249.75</td>
                                                        <td> £6,249.75</td>
                                                        <td> £3,749.85 </td>
                                                        <td> £499.9 plus 100% write off your
                                                            remaining balance</td>
                                                    </tr>
                                                    <tr>
                                                        <td> £24,999.50</td>
                                                        <td> £12,499.75</td>
                                                        <td> £12,499.75 </td>
                                                        <td> £5,999.88 </td>
                                                        <td> £6499.87 plus 100% write off your
                                                            remaining balance
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> £50,000.00</td>
                                                        <td> £25,000.00</td>
                                                        <td> £25,000.00</td>
                                                        <td> £9,000.00</td>
                                                        <td> £16000 plus 100% write off your
                                                            remaining balance</td>
                                                    </tr>
                                                    <tr>
                                                        <td> £1,499</td>
                                                        <td> £149.90 </td>
                                                        <td> £1,349.10 </td>
                                                        <td> £149.90</td>
                                                        <td> £0 plus 100% write off your
                                                            remaining balance
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                            <p> If you lose your claim:</p>
                                            <ul className="new-list">
                                                <li> you do not have to pay us anything </li>
                                            </ul>
                                            <p> For the definitions of “Win”, “Lose”, and “Compensation” please refer to your client care
                                                letter that will
                                                be sent to you once you have signed and returned this agreement. </p>
                                            <p> What you must do for us </p>
                                            <ul className="new-list">
                                                <li> You must give us full, honest and timely instructions;</li>
                                                <li> You must locate and provide to us any relevant documentation that you may have (if any);
                                                </li>
                                                <li> You must undertake enquiries and provide information reasonably required by us;</li>
                                                <li> You must always co-operate with us;</li>
                                                <li>You must not ask us to work in an improper or unreasonable way;</li>
                                                <li>You must not deliberately mislead us; </li>
                                                <li>You must keep us updated if your contact details change. </li>
                                            </ul>

                                            <h6><b>Ending the agreement</b> </h6>
                                            <p> We can end the agreement: </p>
                                            <ul className="new-list">
                                                <li> if you reject our advice in relation to an offer of settlement, or if you do not comply
                                                    with your
                                                    obligations under the agreement and subsequently go to settle your case directly with the
                                                    lender. In this situation you will be liable to pay our full fee in line with the Charging
                                                    Table
                                                    should your claim be successful. </li>
                                                <li>if we advise that your claim is unlikely to succeed; You can end the agreement:</li>
                                                <li> within fourteen days by confirming this to us in writing using the Model Cancellation Form
                                                    below; </li>
                                                <li> at any time before your claim is settled, but if you do you will be liable to pay our full
                                                    fee in line
                                                    with the Charging Table should your claim be successful. </li>
                                            </ul>
                                            <p> at any time before your claim is settled, but if you do you will be liable to pay our full fee
                                                in line
                                                with the Charging Table should your claim be successful. </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Footer />

                    {/* <!---------------------------------> */}
                    <script src="dist/js/app.js"></script>

                </body>
            </div>
        </>
    )
}
export default Terms_and_Conditions;