import React, { useRef, useState, useContext } from "react";
import RightArrow from "../../../assets/img/right-arrow.png";
import PersonalDetails from "../../Includes/Layouts/CL_LS_V3/PersonalDetails";
import DateOfBirth from "../../Includes/Layouts/CL_LS_V3/DateOfBirth";
import Accordion from "react-bootstrap/Accordion";
import ContactDetails from "../../Includes/Layouts/CL_LS_V3/ContactDetails";
import SelectBox from "../../UI/SelectBox";
import { homeOwnerStatus } from "../../../Constants/Constants";
import PostCode from "../../Includes/Layouts/CL_LS_V3/PostCode";
import { useForm } from "react-hook-form";
import PreviousAddress from "../../Includes/Layouts/CL_LS_V3/PreviousAddress";
import InputButton from "../../UI/InputButton";
import Button from "../../UI/Button";
import PreviousAddress_2 from "../../Includes/Layouts/CL_LS_V3/PreviousAddress_2";
import ModalPopup from "../../Includes/Layouts/CL_LS_V3/ModalPopup";
import RadioButton from "../../UI/RadioButton";
import VisitorParamsContext from "../../../Contexts/VisitorParamsContext";
import { useSignature } from "../../../Hooks/useSignature";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryStringContext";
import { useHistory } from "react-router-dom";
import * as EnvConstants from "../../../Constants/EnvConstants";
import { PostcodeValidation } from "../../../Utility/PostcodeValidation";
import GTMDataLayer from "../../Includes/Layouts/common/GTMDataLayer";
import SignatureModalV6 from "../../Includes/Layouts/common/SignatureModalV6";

const FormSplitCL_LS_V6 = (props) => {
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const splitForm = useRef(null);
  const [emailCheck, setEmailCheck] = useState();
  const [phoneCheck, setPhoneCheck] = useState();
  const [signValue,setSignValue] = useState(false);
  const field = "pid";
  const [prevAddressShow1, setPrevAddressShow1] = useState("show");
  const [addressHide, setAddressHide] = useState("show");
  const [prevAddressShow2, setPrevAddressShow2] = useState("hide");
  const [addAnotherButton, setAddAnotherButton] = useState(false);
  const [ivaStatus, setIvaStatus] = useState("");
  const [ivaErr, setIvaErr] = useState("hide");
  const [policyStatus, setPolicyStatus] = useState("");
  const [policyErr, setPolicyErr] = useState("hide");
  const [show, setShow] = useState(false);
  const [blinkEffect1, setBlinkEffect1] = useState("");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { saveSignature, isLoadingSignature } = useSignature();
  const query_string = localStorage.getItem("querystring");
  const [modalShow, setmodalShow] = useState(false);

  const history = useHistory();
  const handleCloseModal = () => {
    setIvaStatus(false);
    setPolicyStatus("32");
    setShow(false);
  };
  const handleClosePolicyModal = () => {
    setShow(false);
  };
  const handleClose = () =>{
    setmodalShow(false);

  }

  const signSubmit = async () =>{
    const formData = JSON.parse(localStorage.getItem('formData'));
    const signature_data = formData.signature_data;
    formData.signature_data = '';

    console.log(formData);
    console.log(signature_data);
    const queryString = localStorage.getItem('queryString');  
  //  var signPadResult = formData.signature_data;
     console.log(signature_data);
   if(signature_data){
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "CL_LS_V6",
        queryString,
        visitorParameters.data
        );
        const response = await saveSignature(
          signature_data,
          visitorParameters.visitor_parameters,
          formData,
          visitorParameters.data,
          queryString,
          "signature_store"
      );
        if (formSUbmitResult.data.status === "Success") {
          if (queryString != null) {
            if (queryString.indexOf("&" + field + "=") !== -1) {
              if (EnvConstants.AppConversionPixel === "true") {
                setShow(true);
                history.push("/fbpixel?split_name=" + props.splitName + "&next_page=" + props.nextPage); // fb fixel firing
                window.scrollTo(0, 0);
              } else {
                window.scrollTo(0, 0);
                history.push(
                  "/" + props.nextPage + "?uuid=" +
                  visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
                );
              }
            } else {
              window.scrollTo(0, 0);
              history.push(
                "/" + props.nextPage + "?uuid=" +
                visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
              );
            }
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/" + props.nextPage + "?uuid=" +
              visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
            );
          }
        
    }
  
   } 
  }

  
  const form = splitForm.current;
  const onHandleSubmit = () => {
    form["txtPrevPostCode_1"].value = "";
    form["txtPrevaddress1_1"].value = "";
    form["txtPrevHouseNumber_1"].value = "";
    form["txtPrevroute_1"].value = "";
    form["txtPrevTown_1"].value = "";
    form["txtPrevCountry_1"].value = "";
    form["txtPrevcounty_1"].value = "";
  };
 
  const basicDetailsValidation = async (e) => {
   
    e.preventDefault();
    const form = splitForm.current;
    const values = getValues();
    const formData = values;

    let txtlstSalutation = await trigger("lstSalutation");
    let txtFNameResult;
    let txtLNameResult;
    let DobDayResult;
    let DobMonthResult;
    let DobYearResult;
    let txtEmailResult;
    let txtPhoneResult;
    let txtHomeOwnerStatus;
    let postCodeResult;
    let prevAddress;
    let prevAddress1Result;
    let prevAddress1_1result;
    let prevAddress1_2result;
    let prevAddress1len;
  
    if (txtlstSalutation) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("salutationRadio").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      DobDayResult = await trigger("dobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (DobDayResult) {
      DobMonthResult = await trigger("dobMonth");
    } else {
      document.getElementById("dobDay").focus();
      return false;
    }
    if (DobMonthResult) {
      DobYearResult = await trigger("dobYear");
    } else {
      document.getElementById("dobMonth").focus();
      return false;
    }
    if (DobYearResult) {
      txtEmailResult = await trigger("txtEmail");
    } else {
      document.getElementById("dobYear").focus();
      return false;
    }
    if (txtEmailResult) {
      txtPhoneResult = await trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }
    if (txtPhoneResult) {
      txtHomeOwnerStatus = await trigger("homeOwnerStatus");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (txtHomeOwnerStatus) {
      postCodeResult = await trigger("txtPostCode");
    } else {
      document.getElementById("homeOwnerStatus").focus();
      return false;
    }
    if (postCodeResult) {
      if (!PostcodeValidation(form["txtPostCode"].value)) {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
        document.getElementById("txtPostCode").focus();
        return false;
      } else {
        prevAddress = await trigger("address1");
      }
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    prevAddress1len = form["txtPrevPostCode_1"].value;
    if (prevAddress && prevAddress1len != "") {
      prevAddress1Result = await trigger("txtPrevPostCode_1");
    } else if (prevAddress && prevAddress1len == "") {
      document.getElementById("iva").focus();
    } else if (!prevAddress) {
      document.getElementById("address1").focus();
      return false;
    }
    if (prevAddress1Result) {
      if (!PostcodeValidation(form["txtPrevPostCode_1"].value)) {
        setError("txtPrevPostCode_1", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
        document.getElementById("txtPrevPostCode_1").focus();
        return false;
      } else {
        prevAddress1_1result = await trigger("txtPrevaddress1_1");
      }
    }
    if (prevAddress1Result && !prevAddress1_1result) {
      document.getElementById("txtPrevaddress1_1").focus();
      return false;
    }
    if (prevAddress1_1result) {
      formData.txtPrevStreet_1 = form["txtPrevStreet_1"].value;
    }
    if (addAnotherButton) {
      let prevAddField = form["txtPrevPostCode_2"].value;
      if (prevAddField != "") {
        if (!PostcodeValidation(form["txtPrevPostCode_2"].value)) {
          setError("txtPrevPostCode_2", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
          document.getElementById("txtPrevPostCode_2").focus();
          return false;
        } else {
          prevAddress1_2result = await trigger("txtPrevaddress1_2");
        }
      }
      if (!prevAddress1_2result && prevAddField != "") {
        document.getElementById("txtPrevaddress1_2").focus();
        return false;
      }
      if (prevAddress1_2result) {
        formData.txtPrevStreet_2 = form["txtPrevStreet_2"].value;
      }
    }
    if (emailCheck == 0) {
      document.getElementById("txtEmail").focus();
      setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
        shouldFocus: true,
      });
      return false;
    }
    if (phoneCheck == 0) {
      document.getElementById("txtPhone").focus();
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
      return false;
    }
    formData.txtStreet = form["txtStreet"].value;
    if (ivaStatus == "" || ivaStatus == "30") {
      document.getElementById("iva").focus();
      setIvaErr("show");
      return false;
    } else {
      formData.bank = 1;
      formData.question10 = ivaStatus;

      if (policyStatus == "" || policyStatus == "33") {
        document.getElementById("privacy").focus();
        setPolicyErr("show");
        return false;
      } else {
        formData.question11 = policyStatus;
      }
      localStorage.setItem("formData", JSON.stringify(formData));
      const full_url = window.location.href;
      const query_string_arr = full_url.split("?");
      var queryString = "";
      if (query_string_arr != null) {
        queryString = query_string_arr[1];
      }
      var signPadResult = formData.user_sign;
      console.log(signPadResult);
      localStorage.setItem("queryString", queryString);
      setmodalShow(true);
      
      
      GTMDataLayer({
        question: "Form Submitted",
      });
    }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div className="form-wrap" action="" autoComplete="">
          <div>
            <div className="container pb-5">
              <PersonalDetails
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                getValues={getValues}
                splitForm={splitForm}
                setError={setError}
              />
              <div className="row">
                <DateOfBirth
                  txtDob="Date of Birth"
                  validation={register}
                  validationMsg={errors}
                />
                <ContactDetails
                  title="Contact Details"
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  setError={setError}
                  getValues={getValues}
                  setEmailCheck={setEmailCheck}
                  setPhoneCheck={setPhoneCheck}
                />
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <div className="form-group row">
                    <label className="text-custom col-md-12">
                      <h5>Home Owner Status</h5>
                    </label>
                    <div className="col-12 mb-3">
                      <SelectBox
                        OptionValue={homeOwnerStatus}
                        className="form-style form-select  custom-select custom-select-lg"
                        id="homeOwnerStatus"
                        name="homeOwnerStatus"
                        onChange={() => clearErrors("homeOwnerStatus")}
                        myRef={register({
                          required: "Please Select Home Owner Status",
                        })}
                      />
                      {errors.homeOwnerStatus && (
                        <div className="mb-3 error">
                          <span className="" id="">
                            {errors.homeOwnerStatus.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <PostCode
                txtAddress="Your Address"
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                setError={setError}
                getValues={getValues}
                splitForm={splitForm}
                setBlinkEffect1={setBlinkEffect1}
              />
              <PreviousAddress
                txtPrevaddress="Previous Address"
                className={addressHide}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                setError={setError}
                getValues={getValues}
                splitForm={splitForm}
                setPrevAddressShow1={setPrevAddressShow1}
                setPrevAddressShow2={setPrevAddressShow2}
                blinkEffect1={blinkEffect1}
                setBlinkEffect1={setBlinkEffect1}
              />
              {addAnotherButton ? (
                <PreviousAddress_2
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  setError={setError}
                  getValues={getValues}
                  splitForm={splitForm}
                />
              ) : (
                ""
              )}
              <div className="row mt-2">
                <div className="col-lg-12">
                  <div className={prevAddressShow1}>
                    <Button
                      id="prevAddressButton"
                      className={`address-btn address-btn1 ${blinkEffect1}`}
                      type="button"
                      buttonText="I don't have any Previous Addresses"
                      onClick={() => {
                        setPrevAddressShow1("hide");
                        setAddressHide("hide");
                        onHandleSubmit();
                      }}
                    />
                  </div>
                  <div className={prevAddressShow2}>
                    <Button
                      id="addMore"
                      className="address-btn address-btn2 "
                      type="button"
                      buttonText="Add Another"
                      onClick={() => {
                        setPrevAddressShow2("hide");
                        setAddAnotherButton(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-5 mb-2">
                <div className="col-lg-7">
                  <p>
                    Have you ever been in or are proposed to be in an IVA, Debt
                    Relief Order, Declared Bankrupt or something similar?drghdr
                  </p>
                </div>
                <div className="col-lg-5">
                  <div className="btn-fr  btn-group" id="iva" tabIndex="0">
                    <div
                      className={` btn btn1 btn-light p-2 ps-3 pe-3 `}
                      onClick={() => {
                        setShow(true);
                        setIvaStatus("30");
                        setIvaErr("hide");
                      }}
                    >
                      <RadioButton id="iva_yes" labelName="Yes" ref={register("iva")} />
                    </div>
                    <div
                      className={` btn btn1 btn-light p-2 ps-3 pe-3 ${ivaStatus == "31" ? "current" : ""
                        }`}
                      onClick={() => {
                        setIvaStatus("31");
                        setIvaErr("hide");
                      }}
                    >
                      <RadioButton
                        id="iva_no"
                        value="no"
                        labelName="No"
                        ref={register("iva")}
                      />
                    </div>
                  </div>
                  <span className={`iva_error ${ivaErr} `} id="iva_err">
                    Please Select One
                  </span>
                </div>
              </div>
              <div className="col-lg-7">
                <p> Unfortunately we cannot process any cases based on this criteria</p>
              </div>
              <div className="row">
                <div className="col-lg-7">
                  <p>
                    {" "}
                    I confirm I have read and agree with the terms of the
                    Privacy Policy and consent to you contacting me to discuss
                    my Irresponsible Lending claim(s).
                  </p>
                </div>
                <div className="col-lg-5 mb-3">
                  <div className="btn-fr  btn-group" id="privacy" tabIndex="0">
                    <div className={` btn btn1 btn-light p-2 ps-3 pe-3 ${policyStatus == "32" ? "current" : ""
                      }`}
                      onClick={() => {
                        setPolicyStatus("32");
                        setPolicyErr("hide");
                        document.getElementById("start_claim").focus();
                        document.getElementById("start_claim").classList.add('efct_filed');
                      }}>
                      <RadioButton
                        id="policy_yes"
                        name="policy"
                        labelName="Yes"
                      />
                    </div>
                    <div className={` btn btn1 btn-light p-2 ps-3 pe-3 `}
                      onClick={() => {
                        setShow(true);
                        setPolicyStatus("33");
                        setPolicyErr("hide");
                      }}>
                      <RadioButton
                        id="policy_no"
                        name="policy"
                        labelName="No"
                      />
                    </div>
                  </div>
                  <span className={`iva_error ${policyErr} `} id="policy_err">
                    Please Select One
                  </span>
                </div>
              </div>
              <p>
                Remember, our service is 100% No Win No Fee so you have nothing to lose. You will only ever pay us a fee if your claim is successful. If your claim is not successful then we will not charge you a penny. The amount you pay is based on the Total Compensation you receive and is detailed here.
              </p>
              <p>
                {" "}
                You do not need to use our services to make a claim against your
                lender(s). It's free to claim compensation from financial
                companies if you have lost money from a mis-sold financial
                product. You can do this yourself directly with the lender(s)
                and even if they reject your claim you can still take this to
                the Financial Ombudsman Service
                https://www.financial-ombudsman.org.uk. If you aren’t sure how
                to make the claim, you can get free, impartial advice from
                consumer organisations.{" "}
              </p>
              <p>
                Please note: your lender may suspend further lending on receipt of your claim.
              </p>
            </div>
          </div>
          <Accordion>
            <section className="colouredBox">
              <div className="container">
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                      <Accordion.Item eventKey="1" className="accordion-item">
                        <Accordion.Header eventKey="1" className="accordion-header" id="flush-headingOne">
                            Contingency Fee Agreement
                        </Accordion.Header>
                        <Accordion.Collapse eventKey="1" id="flush-collapseOne" className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <Accordion.Body eventKey="1" className="accordion-body">
                            <div className="mt-3">
                              <h4> Non-Contentious Business Agreement </h4>
                              <h4> Irresponsible Lending Claims </h4>
                              <p>
                                This is your agreement with Clear Legal Marketing Limited in
                                relation to your Irresponsible Lending claims.{" "}
                              </p>
                              <p>What we will do for you </p>
                              <ol type={1}>
                                <li className="lst_1">
                                  {" "}
                                  We will work diligently on your behalf, no matter how
                                  straightforward or difficult your claim, from the day we
                                  first discuss it with you until your compensation and fees
                                  have been paid. This work will be limited to pursuing your
                                  claim for all losses suffered by you as a result of the
                                  mis-sale of your loan or credit facility.{" "}
                                </li>
                                <li className="lst_1">
                                  {" "}
                                  If you win your claim, we are entitled to deduct an amount
                                  from your Compensation, in respect of our fees{" "}
                                </li>
                                <li className="lst_1">
                                  {" "}
                                  The amount we are entitled to deduct will be based on the
                                  Total Compensation you receive and will be calculated by
                                  reference to the Charging Table in Clause 5 and will
                                  either be a sum equivalent to the ‘Maximum % Rate of
                                  Charge’ or a sum equivalent to the ‘Maximum Total Fee’,
                                  which ever is the lesser. However, the amount you pay us
                                  will never exceed the total cash compensation you receive.{" "}
                                </li>
                                <li className="lst_1">
                                  {" "}
                                  If you lose your claim, and this agreement has been
                                  complied with, you do not have to pay us anything.{" "}
                                </li>
                                <li className="lst_1">
                                  {" "}
                                  Total Compensation’ means any direct monetary payment or
                                  balance reduction made by your opponent in respect of your
                                  claim. It includes both the cash and balance reduction
                                  elements of a refund. For Example, if you are award £1000
                                  refund but have a £500 balance and as a result receive
                                  £500 cash plus a reduction in your balance of £500, we
                                  will charge our fee based on £1000.{" "}
                                </li>
                                <li className="lst_1"> Charging Table: </li>
                              </ol>
                              <table>
                                <tbody>
                                  <tr>
                                    <th> Band </th>
                                    <th> Total Compensation</th>
                                    <th> Maximum % rate of charge (Inc VAT)</th>
                                    <th> Maximum total fee (Inc VAT) </th>
                                  </tr>
                                  <tr>
                                    <td> 1</td>
                                    <td> £1 - £1,499 </td>
                                    <td>36% </td>
                                    <td>£504.00 </td>
                                  </tr>
                                  <tr>
                                    <td> 2</td>
                                    <td> £1,500 - £9,999</td>
                                    <td>33.6% </td>
                                    <td> £3,000.00</td>
                                  </tr>
                                  <tr>
                                    <td> 3</td>
                                    <td> £10,000 - £24,999 </td>
                                    <td> 30% </td>
                                    <td>£6,000.00 </td>
                                  </tr>
                                  <tr>
                                    <td> 4</td>
                                    <td>£25,000 - £49,999 </td>
                                    <td> 24% </td>
                                    <td> £9,000.00</td>
                                  </tr>
                                  <tr>
                                    <td> 5</td>
                                    <td> £50,000 or above </td>
                                    <td> 18% </td>
                                    <td> £12,000.00 </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p className="mt-3">
                                {" "}
                                Please note: both the ‘Maximum % Rate’ and the ‘Maximum
                                Total Fee’ include VAT at the prevailing rate of 20%.{" "}
                              </p>
                            </div>
                            {/* page-2 */}
                            <div className="py-3">
                              <p> What you must do for us </p>
                              <ol>
                                <li value={7} className="lst_1">
                                  {" "}
                                  You must do all you can to help us to progress your claim.
                                  You must provide us with honest instructions that enable
                                  us to do our work properly and to run your claim
                                  efficiently. <br />
                                  <br /> Please sign, to confirm you understand the contents
                                  of this document, that you agree to be bound by this
                                  Agreement, and in the event your claim is successful you
                                  will pay us an amount of your Compensation in line with
                                  the Charging Table:
                                </li>
                              </ol>
                              <table className="border-0 ">
                                <tbody>
                                  <tr style={{ width: "100%" }}>
                                    <td
                                      className="border-0 text-start fw-normal"
                                      style={{ width: "30%" }}
                                    >
                                      {" "}
                                      Signature:
                                    </td>
                                    <td
                                      className="border-0 text-start"
                                      style={{ width: "70%" }}
                                    >
                                      <img
                                        src="{{user_signature_image}}"
                                        className="w-25"
                                        alt=""
                                      />
                                    </td>
                                  </tr>
                                  <tr style={{ width: "100%" }}>
                                    <td
                                      className="border-0 text-start"
                                      style={{ width: "30%" }}
                                    >
                                      {" "}
                                      Date:{" "}
                                    </td>
                                    <td
                                      className="border-0 text-start"
                                      style={{ width: "70%" }}
                                    >
                                      {" "}
                                      {"{"}current date{"}"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/* page-3 */}
                            <h4 className="text-start">
                              {" "}
                              And now for the (not so) small print{" "}
                            </h4>
                            <p>
                              When you instruct Clear Legal Marketing, we will agree to act
                              for you on a risk-free basis. If you win your claim:{" "}
                            </p>
                            <ul className="mrgn_1">
                              <li className="lst_2">
                                we will charge you a percentage from your Total Compensation
                                in respect of our fees, irrespective of the amount of work
                                we have done;{" "}
                              </li>
                              <li className="lst_2">
                                {" "}
                                the total amount we charge will be based on the Total
                                Compensation you receive and it will be calculated by
                                reference to the Charging Table above. We will only charge
                                you the 'Maximum % rate of Charge’ or the ‘Maximum Total
                                Fee’, whichever is the lesser.{" "}
                              </li>
                              <li className="lst_2">
                                {" "}
                                In addition, the amount we will charge you will never exceed
                                the total amount of cash compensation you receive.
                              </li>
                              <li className="lst_2">
                                {" "}
                                we will pay any money we receive on your behalf into a
                                designated account, to make sure that your Compensation is
                                secure;{" "}
                              </li>
                            </ul>
                            <h4 className="text-start"> Possible Outcomes</h4>
                            <p>
                              {" "}
                              There are 4 potential outcomes on your case. The amount we
                              charge will be dependent on the outcome:-
                            </p>
                            <ul className="mrgn_1">
                              <li className="lst_2">
                                <b>Full Cash Refund</b> – this means that you receive 100%
                                of the Total Compensation from the Lender in cash. We will
                                charge you based on the Total Compensation in accordance
                                with the Charging Table above.
                              </li>
                              <li className="lst_2">
                                <b> Cash and Offset Refund</b> – this means that you have an
                                outstanding balance with the lender which is less than the
                                Total Compensation awarded. As a result, you receive both a
                                balance reduction AND a cash award. We will charge you on
                                the Total Compensation in accordance with the Charging Table
                                above, however, we will cap our charges at no more than 100%
                                of the Cash Refund element of your award.
                              </li>
                              <li className="lst_2">
                                <b>Offset Refund</b> – this means that you have an
                                outstanding balance with the lender which is greater than
                                the Total Compensation awarded. As a result, the full amount
                                of the Total Compensation reduces your balance and you
                                receive no physical cash. We will NOT charge you in this
                                scenario.
                              </li>
                              <li className="lst_2">
                                <b>Failed complaint</b> – this means your complaint has not
                                been upheld and as a result no Compensation is awarded. We
                                will NOT charge you in this scenario.
                              </li>
                            </ul>
                            <h4 className="text-start"> Worked Examples</h4>
                            <p className="text-decoration-underline"> Full Cash Refund</p>
                            <table className="border-0">
                              <tbody>
                                <tr>
                                  <th> Cash Refund Received</th>
                                  <th> Our Fee </th>
                                  <th> You Receive</th>
                                </tr>
                                <tr>
                                  <td> £749.50 </td>
                                  <td> £269.82 </td>
                                  <td> £479.68 </td>
                                </tr>
                                <tr>
                                  <td>£4,999.50</td>
                                  <td> £1,679.83 </td>
                                  <td> £3,319.67</td>
                                </tr>
                                <tr>
                                  <td> £12,499.50</td>
                                  <td> £3,749.85</td>
                                  <td> £8,749.65</td>
                                </tr>
                                <tr>
                                  <td> £24,999.50</td>
                                  <td> £5,999.88</td>
                                  <td> £18,999.62</td>
                                </tr>
                                <tr>
                                  <td>£50,000.00</td>
                                  <td> £9,000.00 </td>
                                  <td> £41,000.00</td>
                                </tr>
                              </tbody>
                            </table>
                            {/* page-4 */}
                            <p className="text-decoration-underline mt-3">
                              {" "}
                              Cash and Offset Refund
                            </p>
                            <div className="tbl_ovrflw">
                              <table>
                                <tbody>
                                  <tr>
                                    <th> Total Compensation</th>
                                    <th> Cash Refund Elemen</th>
                                    <th> Balance Reduction Element</th>
                                    <th> Our Fee</th>
                                    <th> You Receive</th>
                                  </tr>
                                  <tr>
                                    <td> £749.50 </td>
                                    <td> £374.75</td>
                                    <td> £374.75</td>
                                    <td> £269.82 </td>
                                    <td>
                                      {" "}
                                      £104.93 plus 100% write off your remaining balance{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td> £4,999.50</td>
                                    <td> £2,499.75</td>
                                    <td> £2,499.75</td>
                                    <td> £1,679.83</td>
                                    <td>
                                      {" "}
                                      £819.918 plus 100% write off your remaining balance
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>£12,499.50 </td>
                                    <td> £6,249.75</td>
                                    <td> £6,249.75</td>
                                    <td> £3,749.85</td>
                                    <td>
                                      £499.9 plus 100% write off your remaining balance
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>£24,999.50</td>
                                    <td>£12,499.75</td>
                                    <td>£12,499.75</td>
                                    <td> £5,999.88</td>
                                    <td>
                                      {" "}
                                      £6499.87 plus 100% write off your remaining balance
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>£50,000.00</td>
                                    <td> £25,000.00</td>
                                    <td> £25,000.00</td>
                                    <td> £9,000.00</td>
                                    <td>
                                      {" "}
                                      £16000 plus 100% write off your remaining balance
                                    </td>
                                  </tr>
                                  <tr>
                                    <td> £1,499</td>
                                    <td> £149.90</td>
                                    <td> £1,349.10</td>
                                    <td> £149.90</td>
                                    <td> £0 plus 100% write off your remaining balance</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <p className="mt-5"> If you lose your claim:</p>
                            <ul className="mrgn_1">
                              <li className="lst_2">you do not have to pay us anything</li>
                            </ul>
                            <p>
                              {" "}
                              For the definitions of “Win”, “Lose”, and “Compensation”
                              please refer to your client care letter that will be sent to
                              you once you have signed and returned this agreement.{" "}
                            </p>
                            <p> What you must do for us </p>
                            <ul className="mrgn_1">
                              <li className="lst_2">
                                You must give us full, honest and timely instructions;{" "}
                              </li>
                              <li className="lst_2">
                                {" "}
                                You must locate and provide to us any relevant documentation
                                that you may have (if any);
                              </li>
                              <li className="lst_2">
                                {" "}
                                You must undertake enquiries and provide information
                                reasonably required by us;
                              </li>
                              <li className="lst_2">
                                {" "}
                                You must always co-operate with us;
                              </li>
                              <li className="lst_2">
                                {" "}
                                You must not ask us to work in an improper or unreasonable
                                way;{" "}
                              </li>
                              <li className="lst_2">
                                {" "}
                                You must not deliberately mislead us;
                              </li>
                              <li className="lst_2">
                                {" "}
                                You must keep us updated if your contact details change.
                              </li>
                            </ul>
                            <h4> Ending the agreement </h4>
                            <p>We can end the agreement: </p>
                            <ul className="mrgn_1">
                              <li className="lst_2">
                                if you reject our advice in relation to an offer of
                                settlement, or if you do not comply with your obligations
                                under the agreement and subsequently go to settle your case
                                directly with the lender. In this situation you will be
                                liable to pay our full fee in line with the Charging Table
                                should your claim be successful.
                              </li>
                              <li className="lst_2">
                                {" "}
                                if we advise that your claim is unlikely to succeed; You can
                                end the agreement:
                              </li>
                              <li className="lst_2">
                                {" "}
                                within fourteen days by confirming this to us in writing
                                using the Model Cancellation Form below;{" "}
                              </li>
                            </ul>
                            {/* page-5 */}
                            <ul className="mrgn_1">
                              <li className="lst_2">
                                at any time before your claim is settled, but if you do you
                                will be liable to pay our full fee in line with the Charging
                                Table should your claim be successful.{" "}
                              </li>
                            </ul>
                            <p>
                              {" "}
                              If either we or you end the agreement, we have the right to
                              keep all documents, money and other property we hold on your
                              behalf until all money due to us is paid.{" "}
                            </p>
                            {/* page-6 */}
                            <h4> Model Cancellation Form</h4>
                            <p>To Clear Legal Marketing</p>
                            <p>
                              I hereby give notice that I cancel the Agreement for Clear
                              Legal Marketing to pursue my mis-sold Loan Refund Complaints
                              on my behalf signed on [&nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; ].{" "}
                            </p>
                            <p className="mt-5"> Name: </p>
                            <p className="mt-5">Address:</p>
                            <p className="mt-5"> Signature: </p>
                            <p className="mt-5"> Date: </p>
                            <p className="mt-5">Change of mind </p>
                            <p className="mt-5">
                              {" "}
                              It is important that you are happy with this agreement before
                              you sign it as we cannot cancel it once it has been signed.{" "}
                            </p>
                          </Accordion.Body>
                        </Accordion.Collapse>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" className="accordion-item mt-3">
                        <Accordion.Header eventKey="2" className="accordion-header" id="flush-headingTwo">
                            Form of Authority
                        </Accordion.Header>
                        <Accordion.Collapse eventKey="2"
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <Accordion.Body eventKey="2" className="accordion-body">
                            <div className="mt-3">
                              <h4> FORM OF AUTHORITY </h4>
                              <p>
                                I appoint and authorise Clear Legal Marketing Limited to
                                consider my claim for the mis-selling of my loans/credit
                                facility in accordance with DISP 2.2 of the FCA Handbook.
                                Clear Legal Marketing Limited is regulated by the FCA under
                                FCA Number 833090.
                              </p>
                              <p>
                                I also authorise and insist that you, Lender Name , release
                                to Clear Legal Marketing without delay any information or
                                records they ask for, in accordance with the 'Rights of Data
                                Subjects and Others' under the Data Protection Act 2018.{" "}
                              </p>
                              <p>
                                In the event that my complaint is successful and
                                compensation is awarded to me, I authorise and insist that
                                you, Lender Name , release the compensation payment funds to
                                Clear Legal Marketing Limited without delay.{" "}
                              </p>
                              <p>
                                I request that all responses are returned directly to Clear
                                Legal Marketing.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Collapse>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" className="accordion-item mt-3">
                        <Accordion.Header eventKey="3" className="accordion-header" id="flush-headingThree">
                            Confirmation Statement
                        </Accordion.Header>
                        <Accordion.Collapse eventKey="3"
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                         >
                          <Accordion.Body eventKey="3" className="accordion-body">
                            <div className="mt-3">
                              <h4> Confirmation Statement </h4>
                              <p>
                                I, Client Full Name, confirm that I have read and understand
                                that:-
                              </p>
                              <ul className="mrgn_2">
                                <li className="lst_3">
                                  {" "}
                                  I do not need to use Clear Legal Marketing Limited to make
                                  a claim against my lender(s).
                                </li>
                                <li className="lst_3">
                                  It is possible for me to present a claim directly to a
                                  lender or to the Financial Services Ombudsman for free.
                                </li>
                              </ul>
                              <p>
                                However, having considered these options, I still wish to
                                instruct Clear Legal Marketing Limited to present the
                                claim(s) on my behalf.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Collapse>
                      </Accordion.Item>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <section className="mt-3">
              <div className="container ">
                <button
                  type="button"
                  name="formSubmitBtn"
                  className="com_bnt w-100 btn-next next-btn font-style"
                  id="start_claim"
                  onClick={basicDetailsValidation}
                >{`Start My Claim  `} <img className="imgArrow" src={RightArrow}/></button>
              </div>
              <ModalPopup
                content="Unfortunately we cannot process any cases based on this criteria"
                popDisplay={show}
                handleClose={handleClosePolicyModal}
              />
              <SignatureModalV6
               modalDisplay={modalShow}
               signSubmit={signSubmit}
               getValues={getValues}
               nextPage= {props.nextPage}
               splitName={props.splitName}
               handleClose={handleClose}
              //  page_name="CL_LS_V6"
              setSignValue={setSignValue}
               field={field}
              />
            </section>
          </Accordion>
        </div>
      </form>
    </>
  );
};
export default FormSplitCL_LS_V6;
