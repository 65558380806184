import React, { useState } from "react";
import { Modal } from "bootstrap";
import Button from "../../../UI/Button";
const SignInfo = ({ showPrivacy, setshowPrivacy }) => {
  console.log(showPrivacy);
  const Privacy = () => {
    setshowPrivacy("hide");
  };
  return (
    <>
      <div
        className={`modal fade ${showPrivacy}`}
        id="signModel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content signmodel-wrap ">
            <Button
              type="button"
              className="btn-close float-end"
              data-bs-dismiss="modal"
              onClick={() => Privacy()}
            ></Button>
            <div className="modal-body" style={{ color: "#000" }}>
              <div>
                <h4>Nature of the Service</h4>
                <p>
                  We pursue claims on behalf of individuals for Irresponsible
                  Lending. Most loans were arranged online in minutes. The
                  lenders were and continue to be under a duty to carry out
                  reasonable and proportionate affordability checks before
                  approving the loans. Often, the checks carried out were either
                  not sufficient or further lending meant that additional checks
                  should have been carried out.{" "}
                </p>
                <p>
                  If you wish to instruct us, we will gather information from
                  you and/or your Lender to establish whether they indeed
                  carried out the appropriate affordability checks. If they did
                  not, we will then establish whether, had they done so, they
                  would have still agreed to lend to you.{" "}
                </p>
                <p>
                  If, once our investigations are complete, we determine that
                  the lender shouldn't have lent to you then we will seek
                  redress either directly with the lender or if necessary via
                  the Financial Ombudsman.
                </p>
                <p>
                  Where we do not believe your claim has merit, we will advise
                  you of this and the reasons why.{" "}
                </p>
                <h4>Illustration of fees</h4>
                <p>
                  We will act on a “no win no fee” basis which means that you
                  only pay our fees in the event you are successful and awarded
                  a cash refund.{" "}
                </p>
                <p>
                  Where you have an outstanding balance with your lender, they
                  might seek to offset any compensation against your outstanding
                  liabilities. This means that you may not receive the actual
                  cash in your hand. In the event that some, or all, of your
                  compensation is a reduction in your outstanding balance, we
                  will not charge you a fee on that part of the compensation; we
                  only charge our fee on the cash refund element.
                </p>
                <p>
                  The amount you will pay us is dependent on the amount of cash
                  refund you receive. The below table details what we charge.
                </p>

                <table className="table">
                  <caption></caption>

                  <tr>
                    <th>Band</th>
                    <th>Cash Refund Received</th>
                    <th>Maximum % rate of charge (Inc VAT)</th>
                    <th>Maximum total fee (Inc VAT)</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>£1 - £1,499</td>
                    <td>36%</td>
                    <td>£504.00</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>£1,500 - £9,999</td>
                    <td>33.6%</td>
                    <td>£3,000.00</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>£10,000 - £24,999</td>
                    <td>30%</td>
                    <td>£6,000.00</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>£25,000 - £49,999</td>
                    <td>24%</td>
                    <td>£9,000.00</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>£50,000 or above</td>
                    <td>18%</td>
                    <td>£12,000.00</td>
                  </tr>
                </table>

                <p className="font-italic">
                  The standardised figures used above are for cost illustrations
                  only and are not to be taken as an estimate of the amount
                  likely to be recovered for you. The fee you will have to pay
                  will be based on your compensation award which will vary based
                  on the amount you borrowed, length of time and overall cost of
                  the lending. This means what you pay might be more or less
                  than the illustrations shown above.
                </p>
                <p>
                  If you fail to cooperate or provide false or misleading
                  information to Clear Law Marketing Limited, you may be liable
                  to pay any costs incurred.
                </p>

                <h4>Worked Fee Examples</h4>
                <table className="table">
                  <caption> </caption>
                  <tbody>
                    <tr>
                      <th>Band</th>
                      <th>Example Cash Refund Received</th>
                      <th>Maximum % rate of charge (Inc VAT)</th>
                      <th>Maximum Fee You Will Pay</th>
                      <th>Amount you will receive after our fee</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>£749.50</td>
                      <td>36%</td>
                      <td>£269.82</td>
                      <td>£479.68</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>£4,999.50</td>
                      <td>33.60%</td>
                      <td>£1,679.83</td>
                      <td>£3,319.67</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>£12,499.50</td>
                      <td>30%</td>
                      <td>£3,749.85</td>
                      <td>£8,749.65</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>£24,999.50</td>
                      <td>24%</td>
                      <td>£5,999.88</td>
                      <td>£18,999.62</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>£50,000.00</td>
                      <td>18%</td>
                      <td>£9,000.00</td>
                      <td>£41,000.00</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        £5000.00 And a reduction in your balance of £1000.00
                      </td>
                      <td>33.60%</td>
                      <td>£1,680.00</td>
                      <td>
                        £3,320.00 Plus a reduction of £1000.00 from your
                        outstanding balance
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Please note: the amount you pay will either be a sum
                  equivalent to the 'Maximum % Rate of Charge' or a sum
                  equivalent to the 'Maximum Total Fee', whichever is the
                  lesser.{" "}
                </p>
                <p>
                  For example, a customer who is awarded redress of £1,000 must
                  not be charged more than £300 (plus VAT). This is the lower
                  figure out of: (a) the maximum % rate 36% of £1,000 and (b)
                  the maximum total fee of £504.00.
                </p>
                <p>
                  However, a customer who is awarded redress of £1,450 must not
                  be charged more than £504. This is the lower figure out of:
                  (a) the maximum % rate of 36% of £1,450 awarded (which is
                  £522); and (b) the Max Total Fee of £504.
                </p>
                <h4>Commission Payments</h4>
                <p>
                  Credit Reporting Agency Limited will pay Clear Law Marketing
                  Limited a fee of £12 for each validated subscription to their
                  30 day free trial. In addition, they also offer an additional
                  tiered bonus structure based on the number of leads we send to
                  them in a calendar month. Please see a breakdown below.
                </p>
                <p>
                  Tier 1: 50 - 99 leads = £200
                  <br />
                  Tier 2: 100 - 249 leads = £400
                  <br />
                  Tier 3: 250 - 499 leads = £1000
                  <br />
                  Tier 4: 500 - 999 leads = £2000
                  <br />
                  Tier 5: 1000 &gt; = £6000
                </p>

                <p>
                  For example, if we were to send them 100 validated trials in a
                  single month, we would receive £1,200 plus the £400 bonus.
                </p>
                <p>
                  If you want to know more about this arrangement, please
                  contact us to discuss.
                </p>
                <p>
                  We ask that you send us a copy of your credit report so that
                  it can assist in the formulation of your complaint and so we
                  can highlight any additional claims you might have.{" "}
                </p>
                <p>
                  You do not need to use Credit Report Agency Limited's product.
                  There are a number of companies providing credit reports which
                  you could use instead.
                </p>
                <h4>Alternative Ways to Claim</h4>
                <p>
                  You do not need to use our services to make a claim against
                  your lender(s). It's free to claim compensation from financial
                  companies if you have lost money from a mis-sold financial
                  product. You can do this yourself directly with the lender(s)
                  and even if they reject your claim you can still take this to
                  the Financial Ombudsman Service
                  http://www.financial-ombudsman.org.uk/. If you aren't sure how
                  to make the claim, you can get free, impartial advice from
                  consumer organisations.
                </p>
                <h4>Overview of key stages of a claim</h4>
                <table className="table">
                  <caption> </caption>

                  <tr>
                    <th>Stage 1</th>
                    <th>Initial online assessment of potential claim</th>
                  </tr>
                  <tr>
                    <td>Stage 2</td>
                    <td>
                      Request for information from Lender or Claim Submission
                    </td>
                  </tr>
                  <tr>
                    <td>Stage 3</td>
                    <td>Lender response</td>
                  </tr>
                  <tr>
                    <td>Stage 4</td>
                    <td>Advice on Lender Response</td>
                  </tr>
                  <tr>
                    <td>Stage 5</td>
                    <td>
                      Complaint Upheld - Recommend acceptance
                      <br />
                      Complaint Rejected - No Further Action
                      <br />
                      Complaint Rejected - Referral to Financial Ombudsman
                      Service (FOS)
                    </td>
                  </tr>
                  <tr>
                    <td>Stage 6</td>
                    <td>
                      Redress Received
                      <br />
                      Case Rejected by FOS
                    </td>
                  </tr>
                </table>
                <h4>Keeping you updated</h4>
                <p>
                  We will keep you updated at each key stage of the process,
                  usually via email and SMS. We may also call you to discuss
                  your claim.{" "}
                </p>

                <h4>What you have to do</h4>
                <p>
                  You will need to provide sufficient detail about your loans or
                  credit facilities, that enables your Lender to locate your
                  account. When we have located your loans and/or credit
                  facilities, we may ask you for further information about the
                  loans/credit facilities and your circumstances at the time
                  they were given to you.{" "}
                </p>

                <h4>Cancellation</h4>
                <p>
                  If you wish to cancel this contract you can do so within 14
                  days at no cost. Just let us know.
                </p>
                <p>
                  If you cancel our service after 14 days and after we submit
                  your claim you will be liable to pay our full fee (calculated
                  as shown above) should your claim be successful.
                </p>
                <h5>IVA's, Debt Relief Orders, Bankruptcy and more</h5>
                <p>If you:-</p>
                <ul>
                  <li style={{ liststyletype: "lower-alpha" }}>
                    have every been subject to an IVA or
                  </li>
                  <li style={{ liststyletype: "lower-alpha" }}>
                    are awaiting the approval of an IVA proposal or
                  </li>
                  <li style={{ liststyletype: "lower-alpha" }}>
                    have ever been declared bankrupt or
                  </li>
                  <li style={{ liststyletype: "lower-alpha" }}>
                    are subject to a bankruptcy petition or
                  </li>
                  <li style={{ liststyletype: "lower-alpha" }}>
                    have ever been subject to a Debt Relief Order or
                  </li>
                  <li style={{ liststyletype: "lower-alpha" }}>
                    have even been subject to a similar process
                  </li>
                </ul>
                <p>
                  you must inform us immediately. If we proceed with a claim for
                  you then you must provide us with the IVA Supervisor / Trustee
                  in Bankruptcy's written consent to pursue the claim for you
                  prior to instructing us. You also authorise us to pay
                  settlement monies to your IVA Supervisor / Trustee in
                  Bankruptcy after our fees have been deducted.
                </p>

                <h4>Payments Made by Your Guarantor</h4>
                <p>
                  We need to make you aware that if your claim is successful,
                  and your guarantor has made payments towards your loan, some
                  lenders which will contact your Guarantor and offer to return
                  the payments they made. If they accept the offer, they will be
                  released from their obligation to pay and the amount they are
                  refunded could be added back to your loan account meaning you
                  would be responsible for repaying it. All interest and charges
                  are still likely to be waived and as a result it is only the
                  capital amount you will be required to pay back.
                </p>
                <h4>Complaints </h4>
                <p>
                  Should you have a complaint in regards to the service we have
                  provided, we have a complaints procedure which you should
                  follow. The full process can be found on our website or can be
                  provided on request.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                buttonText="Close"
                onClick={Privacy}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignInfo;
