import React from "react";
import logo from "../../../../assets/img/Clear-LegalMarketingCMYK.png";
const Header = () => {
    return (
        <>
            <div className="CL_LS_V3">
                <section className="sec-nav-bars">
                    <div className="container text-center">
                        <a href="" className="logo">
                            <img src={logo} alt="" />
                        </a>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Header;