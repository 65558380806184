import React, { useEffect, useState } from "react";
import Button from "../../../UI/Button";

const QuestionnaireCheckboxSlide = (props) => {
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [showError, setShowError] = useState("d-none");
  const question_id = props.questionnaire.questionId;
  
  const checkboxQuestion = props.questionnaire.answers.map((element, index) => {
    return (
      <li>
        <label className="checklist">
          <input
            type="checkbox"
            id={`question_${question_id}_${element.value}`}
            name={`question_${question_id}[]`}
            value={element.value}
            onClick={(e) => {
              insertValues(e);
            }}
          />
          <span className="checkmark" />
          <div htmlFor={`question_${question_id}_${element.value}`}>
            {" "}
            {element.label}
          </div>
        </label>
      </li>
    );
  });
  const insertValues = (e) => {
    setShowError("d-none");
    var values = e.target.value;
    if (checkboxValues.includes(values)) {
      setCheckboxValues(checkboxValues.filter((item) => item !== values));
    } else {
      setCheckboxValues([...checkboxValues, values]);
    }
  };
  const pageValidate = (e) => {
    checkboxValues.length != 0
      ? props.slideChange(e, checkboxValues)
      : setShowError("d-block");
  };

  return (
    <>
      <div
        className={`section-slides form-wraper text-center slide5 d ${props.showQuestionSlide}`}
      >
        <div></div>
        <div className="slide-top optionalarea">
          <div className="select-filed    mt-4">
            <div className="row">
              <div className="col-lg-12 ">
                <h3 className="mb-3  text-center">
                  {props.questionnaire.question}
                </h3>
                <ul className="optionlist mb-3">{checkboxQuestion}</ul>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
          <span
            className={`error_msg error_msg_custom errormsg2  ${showError}`}
            id="email_err"
          >
            Please select an option
          </span>
          </div>
          

          <div className="mt-4 d-flex justify-content-center controle_btn">
          {props.backBtn && <Button
              type="button"
              className="com_bnt me-3 back4"
              name="back5"
              onClick={props.previousSlide}
              buttonText={[<i className="bi bi-arrow-left" />, "Back "]}
            ></Button>}
            <Button
             type="button"
              className="com_bnt next5"
              data-question_id={question_id}
              value={question_id}
              name={`next${question_id}`}
              onClick={(e) => {
                pageValidate(e);
              }}
              question_id={question_id}
              buttonText={["Next ", <i className="bi bi-arrow-right" />]}
            >
              Next
              <i className="bi bi-arrow-right"></i>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireCheckboxSlide;
