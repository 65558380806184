import React from "react";
import Header from "../../Includes/Layouts/common/Header";
import Footer from "../../Includes/Layouts/common/Footer";
import FormSplitCL_LS_V7 from "../../Forms/CL_LS_V7/FormSplitCL_LS_V7";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

const CL_LS_V7 = () => {
    const {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('CL_LS_V7','CL_LS_V7');
    return (
        <>
            <AdtopiaLoadLP pageType="LP" splitName="CL_LS_V7" />
            <FbViewPixel />
            <div className="CL_LS_V7">
                <div className="bg">
                    <div className="clearfix"></div>
                    <Header />
                    <section className="main-start bg text-center mb-5">
                        <h1>Start your Lending Stream Claim Today</h1>
                        <p>Enter your details below to begin. You could be owed £££thousands* if you were mis-sold!</p>
                    </section>
                    <FormSplitCL_LS_V7 splitName ={DynamicRouteSplitName} nextPage={DynamicRouteNextPage} />
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default CL_LS_V7;

