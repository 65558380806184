import React from "react";
import Header from "./Header";
import Footer from "./Footer";
const VulnerablePolicy = () => {
    return (
        <>
        <div className="vulnerablepolicy CL_LS_V3">
            <div className="clearfix" />
            <Header />
            <section className="main-start bg text-center mb-5">
                <h1>Vulnerable Persons Policy</h1>
                <h4>Welcome to Clear Legal Marketing Limited's Vulnerable Persons Policy.</h4>
            </section>
            <div className="form-wrap">
                <div className="container pb-4">
                    <table className="main-table" aria-describedby="ex1">
                        <tbody>
                            <tr>
                                <th className="d-none" />
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <h5>
                                        <b>Vulnerable Persons Policy</b>
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Clear Legal Marketing Limited is committed to ensuring that
                                        customers who exhibit vulnerable characteristics are recognised
                                        and safeguarded against the risk of harm.
                                    </p>
                                    <p>
                                        Our regulator, The Financial Conduct Authority (FCA), expects
                                        firms like us to treat all our customers fairly and when dealing
                                        with people with vulnerable characteristics, take account of
                                        such vulnerability to ensure that the way that we sell and
                                        provide our services does not cause them harm.
                                    </p>
                                    <p>
                                        As a result, Clear Legal Marketing Limited gives consideration
                                        to potentially vulnerable customers at all stages of our process
                                        from our marketing material right through to process and
                                        customer care.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table
                                        style={{ borderCollapse: "unset !important" }}
                                        aria-describedby="ex3"
                                    >
                                        <tbody>
                                            <tr>
                                                <th className="d-none" />
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <b>
                                                            <u>What is a vulnerable customer?</u>
                                                        </b>
                                                    </p>
                                                    <p>
                                                        The FCA defines a vulnerable customer as ‘someone who,
                                                        due to their personal circumstances, is especially
                                                        susceptible to harm, particularly when a firm is not
                                                        acting with appropriate levels of care.’
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        <b>
                                            <u>
                                                What do we do to reduce the risk of harm to vulnerable
                                                customers?
                                            </u>
                                        </b>
                                    </p>
                                    <p></p>
                                    <ul className="bullet-points">
                                        <li>
                                            &nbsp; We will strive to understand the vulnerable customers’
                                            needs.
                                        </li>
                                        <li>
                                            &nbsp; We will ensure our staff have the necessary skills and
                                            capability to recognise vulnerability and deal with it
                                            appropriately.
                                        </li>
                                        <li>
                                            &nbsp; Where appropriate we will ensure our customer service
                                            is designed to allow for flexibility when dealing with
                                            vulnerable customers and highlight support available to them.
                                        </li>
                                        <li>
                                            &nbsp; We will ensure that all communications are designed to
                                            be easily understood and, where possible, in a format that is
                                            preferable to the vulnerable customer. We will communicate
                                            regularly to keep them up to date with any development on
                                            their case.
                                        </li>
                                        <li>
                                            &nbsp; We will monitor individual vulnerable customers on an
                                            ongoing basis but also review and monitor our performance on
                                            behalf of vulnerable customers on a bi-annual basis.
                                        </li>
                                        <li>
                                            &nbsp; We will consider impact of our service on vulnerable
                                            customers at all stages of the process.
                                        </li>
                                    </ul>
                                    <p />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        <b>
                                            <u>Key drivers of vulnerability</u>
                                        </b>
                                    </p>
                                    <p>
                                        The FCA highlight the following key drivers of vulnerability:-
                                    </p>
                                    <p></p>
                                    <ol className="numeric_points">
                                        <li>
                                            &nbsp;
                                            <b>Health</b> – health conditions or illnesses that affect
                                            ability to carry out day-to-day tasks.
                                        </li>
                                        <li>
                                            &nbsp;
                                            <b>Life events</b> – life events such as bereavement, job loss
                                            or relationship breakdown.
                                        </li>
                                        <li>
                                            &nbsp;
                                            <b>Resilience</b> – low ability to withstand financial or
                                            emotional shocks.
                                        </li>
                                        <li>
                                            &nbsp;
                                            <b>Capability</b> – low knowledge of financial matters or low
                                            confidence in managing money (financial capability). Low
                                            capability in other relevant areas such as literacy, or
                                            digital skills.
                                        </li>
                                    </ol>
                                    <p />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        <b>
                                            <u>
                                                How do we manage the risks more commonly associated with our
                                                target customer base?
                                            </u>
                                        </b>
                                    </p>
                                    <p>
                                        <b>Customer with reduced financial capability/resilience</b>
                                    </p>
                                    <p>
                                        Although we believe the potential for harm to customers with
                                        reduced financial resilience is likely to be low, here are some
                                        of the measures we have in place to further reduce the risk:-
                                    </p>
                                    <p></p>
                                    <ul className="bullet-points">
                                        <li>
                                            &nbsp; Our fees are prescribed by the FCA under the Claims
                                            Management Fee Cap and as a result we are unable to charge
                                            outside those parameters.
                                        </li>
                                        <li>
                                            &nbsp; We only ever charge our customers where they are in
                                            receipt of a cash refund. This means that where a customer
                                            receives 100% of their compensation as a balance reduction, we
                                            do not charge that customer for our services. Where the
                                            customer’s compensation is made both a balance reduction and a
                                            cash refund We also cap our fees at no more than the cash
                                            refund they receive.
                                        </li>
                                    </ul>
                                    <p />
                                    <p>
                                        The above ensures that our fees are reasonable and we do not put
                                        our customers in further financial difficulty.
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* ======  page 2 ====== */}
            <div className="form-wrap">
                <div className="container pb-4">
                    <p>
                        <b>Poor Language, literacy or Digital Skills</b>
                    </p>
                    <p>
                        Again the potential harm from issues with language, literacy and digital
                        skills is relatively low. This is because we initially engage with all
                        our customers through digital platforms.
                    </p>
                    <p>However, to keep the risk low we adopt the following precautions:-</p>
                    <p></p>
                    <ol className="numeric_points">
                        <li>
                            &nbsp; We explain at various stages in the process that our primary
                            method of communication will be through digital formats. This is
                            because we need to limit communication so that we can adopt a
                            streamlined process which allows us to limit our fees as explained
                            above.
                        </li>
                        <li>
                            &nbsp; However, we do also explain that in exceptional circumstances
                            we will accommodate customers who need alternative methods of
                            communication.
                        </li>
                        <li>
                            &nbsp; All communications are carefully written in Plain English to
                            allow for greater understanding by all levels of cognition
                        </li>
                        <li>
                            &nbsp; All clients have 14 day cooling off period in which they can
                            terminate their agreement. However, in certain circumstances, if a
                            client wishes to cancel their agreement outside the 14 day cooling off
                            period due to a specific vulnerability, we will not unreasonably
                            refuse.
                        </li>
                    </ol>
                    <p />
                    <p>
                        <b>
                            <u>Staff Skills and Capability</u>
                        </b>
                    </p>
                    <p>
                        It is important that all our staff understand how their role can affect
                        vulnerable consumer, how to recognise signs of vulnerability and what
                        they should do if they believe someone might be vulnerable.
                    </p>
                    <p>
                        To make sure of this, all staff are provided with training on customer
                        vulnerability.
                    </p>
                    <p>
                        <b>
                            <u>Customer Service</u>
                        </b>
                    </p>
                    <p>
                        Vulnerable consumers are more likely to have different service needs.
                        Therefore, we ensure that our customer service provision meets the needs
                        of vulnerable consumers, otherwise they could exacerbate the risk of
                        harm from being vulnerable.
                    </p>
                    <p>In order to do so we have adopted the following considerations:-</p>
                    <p></p>
                    <ul className="bullet-points">
                        <li>
                            &nbsp; Where a member of staff identifies a potential for
                            vulnerability, they will consider whether it is appropriate to allow
                            for variances to the usual process.
                        </li>
                        <li>
                            &nbsp; We ensure, where appropriate, customers are aware that they
                            could elect for third party representation from one of the following:-
                            <ul className="round-points">
                                <li>&nbsp; Someone with Power of Attorney</li>
                                <li>&nbsp; A Deputy appointed by the Court of Protection OR</li>
                                <li>
                                    &nbsp; Where the customer understands the meaning of consent, a
                                    signed statement asking us to liaise with a third party
                                    representative
                                </li>
                            </ul>
                        </li>
                        <li>
                            &nbsp; We record all interactions with the customers within the case
                            management system so that all staff members can easily access the
                            information.
                        </li>
                    </ul>
                    <p />
                </div>
            </div>
            {/* ====== page 3 ==== */}
            <div className="form-wrap">
                <div className="container pb-4">
                    <table className="main-table" aria-describedby="table1">
                        <tbody>
                            <tr>
                                <th className="d-none" />
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <table
                                        aria-describedby="table1"
                                        style={{ borderCollapse: "unset" }}
                                    >
                                        <tbody>
                                            <tr>
                                                <th className="d-none" />
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <b>
                                                            <u>Communications</u>
                                                        </b>
                                                    </p>
                                                    <p>
                                                        To ensure that our communications are effective in
                                                        reducing harm to vulnerable customers, the following
                                                        provisions have been established:-
                                                    </p>
                                                    <p></p>
                                                    <ul className="bullet-points">
                                                        <li>
                                                            &nbsp; All communications including marketing
                                                            materials are written in Plain English and provided in
                                                            a legible format.
                                                        </li>
                                                        <li>
                                                            &nbsp; If a customer requests communication in a
                                                            format outside the usual methods, we will do our very
                                                            best to accommodate the customer’s requests.
                                                        </li>
                                                        <li>
                                                            &nbsp; The customer is kept updated throughout the key
                                                            stages of the process
                                                        </li>
                                                        <li>
                                                            &nbsp; The customer is provided with contact details
                                                            should they wish to discuss their case at any time.
                                                        </li>
                                                    </ul>
                                                    <p />
                                                    <p>
                                                        <b>
                                                            <u>Monitoring and evaluation</u>
                                                        </b>
                                                    </p>
                                                    <p>
                                                        To ensure that we monitor and evaluate how we deal with
                                                        vulnerable customers, we have specificprocesses in place
                                                        where a customer is identified as potentially
                                                        vulnerable.
                                                    </p>
                                                    <p>
                                                        We also monitor feedback and data on vulnerable
                                                        customers to ensure any trends are spotted and any
                                                        necessary improvements are implemented.
                                                    </p>
                                                    <p>
                                                        <b>
                                                            <u>
                                                                What should you do if you believe you are vulnerable
                                                                customer?
                                                            </u>
                                                        </b>
                                                    </p>
                                                    <p>
                                                        If you believe any of the above relate to your
                                                        circumstances and you would like us to take that into
                                                        account when dealing with your case, please let us know
                                                        immediately and we will do our best to accommodate your
                                                        requirements.
                                                    </p>
                                                    <p>
                                                        <b>Email:</b> &nbsp;{" "}
                                                        <a
                                                            className="alink"
                                                            href="mailto:enquiries@clearlegalmarkeing.co.uk"
                                                        >
                                                            enquiries@clearlegalmarkeing.co.uk
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <b>Phone:</b> &nbsp; 0161 249 7885
                                                    </p>
                                                    <p>
                                                        <b>Post:</b> &nbsp; Units 115-119 Timber Wharf, 42-50
                                                        Worlsey Street, Manchester. M15 4LD
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer/>
            </div>
        </>
    )
}
export default VulnerablePolicy;