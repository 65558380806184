import React, { useRef, useState, useContext, useEffect } from "react";
import TextField from "../../../UI/TextField";
import { NumberValidation } from "../../../../Utility/NumberValidation";

const QuestionTextAreaSlide = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = "question_" + question_id;
  const [showError, setShowError] = useState("d-none");
  const validateSlide = async (e) => {
    let comments = await props.trigger('txtComments');
    if (comments) {
      let form = props.userQuestionnire.current;
      props.slideChange(e, 'txtComments');
    }
  };
  return (
    <>
      <div
        className={`section-slides form-wraper text-center slide6 ${props.showQuestionSlide}`}
      >
        <div className="slide-top optionalarea">
          <h1 className="title "> {props.questionnaire.question}</h1>
          <div className="select-filed    mt-4">
            <div className="row">
              <div className="col-lg-12   ">
                <div className="form-text-area">
                  <textarea
                    className="form-control"
                    placeholder="Comments"
                    cols={30}
                    rows={8}
                    defaultValue={""}
                    name="txtComments"
                    id="txtComments"
                    ref={props.validation({
                      required: "Please Enter Comments"
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          {props.validationMsg.txtComments && (
            <div className="mb-3  errormsg3 ">
              <span className=" " id="email_err">
                {props.validationMsg.txtComments.message}
              </span>
            </div>
          )}

          <div className="mt-4 d-flex justify-content-center controle_btn">
            <button
              type="button"
              className="com_bnt me-3 back5 "
              name="back6"
              onClick={props.previousSlide}
            >
              <i className="bi bi-arrow-left" /> Back{" "}
            </button>
            <a href="#">
              <button
                type="button"
                className="com_bnt  "
                name={`next${question_id}`} onClick={validateSlide}
                data-question_id={question_id}
              >
                {" "}
                Next <i className="bi bi-arrow-right" />
              </button>
            </a>
          </div>
          <div className="mt-4 d-flex justify-content-center controle_btn">
            <button type="button" className="skip_button" value={`skip${question_id}`} name={`skip${question_id}`}
              onClick={(e) => props.slideChange(e, 'txtComments')} data-question_id={question_id}>
              Skip
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionTextAreaSlide;
