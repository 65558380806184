import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useGetUUID = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getUserUUID = async (uuid) => {
    const response = await Api.post("v1/get-uuid", {
      uuid,
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'GET UUID',
        errorReason: error.message,
        errorCode: error.code,
        uuid:null
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { getUserUUID };
};
