import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import { RadioSalutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
const PersonalDetails = ({
  validation,
  validationMsg,
  splitForm,
  setError,
}) => {
  const [selectedSalutationValue, setSelectedSalutationValue] = useState(
    RadioSalutation[0].value
  );
  const clearSalutationMsg = (e) => {
    setSelectedSalutationValue(e.target.value);
  };
  const form = splitForm.current;
  const Radio = RadioSalutation.map((element, index) => {
    let activeSalutation =
      selectedSalutationValue === element.value ? "active" : "";
    let checkedSalutation =
      selectedSalutationValue === element.value ? true : false;
    return (
      <RadioButton
        labelClassName={`btn btn-lg btn-custom hover-display ${activeSalutation}`}
        id={`lstSalutation_${element.label}`}
        name="lstSalutation"
        value={element.value}
        labelName={element.label}
        key={element.label}
        onClick={clearSalutationMsg}
        defaultChecked={checkedSalutation}
        validation={validation({
          required: "Select Salutation",
          message: "Please Select Salutation",
        })}
      />
    );
  });

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };
  return (
    <>
      {" "}
      <div className="row mb-4 ">
        <div className="col-lg-4 col-md-12 col-sm-12 ">
          <div
            className="btn-group user-type-toggle mb-3"
            id="salutationRadio"
            data-toggle="buttons"
            tabIndex="0"
          >
            {Radio}
          </div>
          {validationMsg.lstSalutation && (
            <span className="error" id="salutation_error">
              {validationMsg.lstSalutation.message}
            </span>
          )}
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12">
          <div className="mb-3">
            <TextField
              className="form-style form-control-lg w-100"
              type="text"
              placeholder="First Name"
              name="txtFName"
              id="txtFName"
              onBlur={namevalidate}
              validation={validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
            />
            {validationMsg.txtFName && (
              <div className="mb-3 error">
                <span className="" id="">
                  {validationMsg.txtFName.message}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12">
          <div className="mb-3">
            <TextField
              className="form-style form-control-lg w-100"
              type="text"
              placeholder="Last Name"
              name="txtLName"
              id="txtLName"
              onBlur={namevalidate}
              validation={validation({
                required: "Please Enter Last Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid Last Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
            />
            {validationMsg.txtLName && (
              <div className="mb-3 error">
                <span className="" id="">
                  {validationMsg.txtLName.message}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PersonalDetails;
