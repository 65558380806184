import React, { useRef, useState, useContext } from "react";
import PersonalDetails from "../../Includes/Layouts/CL_LS_V7/PersonalDetails";
import DateOfBirth from "../../Includes/Layouts/CL_LS_V7/DateOfBirth";
import ContactDetails from "../../Includes/Layouts/CL_LS_V7/ContactDetails";
import SelectBox from "../../UI/SelectBox";
import { homeOwnerStatus } from "../../../Constants/Constants";
import PostCode from "../../Includes/Layouts/CL_LS_V7/PostCode";
import { useForm } from "react-hook-form";
import PreviousAddress from "../../Includes/Layouts/CL_LS_V7/PreviousAddress";
import InputButton from "../../UI/InputButton";
import Button from "../../UI/Button";
import PreviousAddress_2 from "../../Includes/Layouts/CL_LS_V7/PreviousAddress_2";
import ModalPopup from "../../Includes/Layouts/CL_LS_V7/ModalPopup";
import RadioButton from "../../UI/RadioButton";
import VisitorParamsContext from "../../../Contexts/VisitorParamsContext";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryStringContext";
import { useHistory } from "react-router-dom";
import * as EnvConstants from "../../../Constants/EnvConstants";
import { PostcodeValidation } from "../../../Utility/PostcodeValidation";
import GTMDataLayer from "../../Includes/Layouts/common/GTMDataLayer";

const FormSplitCL_LS_V7 = (props) => {
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const splitForm = useRef(null);
  const [emailCheck, setEmailCheck] = useState();
  const [phoneCheck, setPhoneCheck] = useState();
  const field = "pid";
  const [prevAddressShow1, setPrevAddressShow1] = useState("show");
  const [addressHide, setAddressHide] = useState("show");
  const [blinkEffect1, setBlinkEffect1] = useState("");
  const [prevAddressShow2, setPrevAddressShow2] = useState("hide");
  const [addAnotherButton, setAddAnotherButton] = useState(false);
  const [ivaStatus, setIvaStatus] = useState("");
  const [ivaErr, setIvaErr] = useState("hide");
  const [policyStatus, setPolicyStatus] = useState("");
  const [policyErr, setPolicyErr] = useState("hide");
  const [show, setShow] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const query_string = localStorage.getItem("querystring");

  const history = useHistory();
  const handleCloseModal = () => {
    setIvaStatus(false);
    setPolicyStatus("32");
    setShow(false);
  };
  const handleClosePolicyModal = () => {
    setShow(false);
  };
  const form = splitForm.current;
  const onHandleSubmit = () => {
    form["txtPrevPostCode_1"].value = "";
    form["txtPrevaddress1_1"].value = "";
    form["txtPrevHouseNumber_1"].value = "";
    form["txtPrevroute_1"].value = "";
    form["txtPrevTown_1"].value = "";
    form["txtPrevCountry_1"].value = "";
    form["txtPrevcounty_1"].value = "";
  };

  const basicDetailsValidation = async (e) => {
    e.preventDefault();
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    let txtlstSalutation = await trigger("lstSalutation");
    let txtFNameResult;
    let txtLNameResult;
    let DobDayResult;
    let DobMonthResult;
    let DobYearResult;
    let txtEmailResult;
    let txtPhoneResult;
    let txtHomeOwnerStatus;
    let postCodeResult;
    let prevAddress;
    let prevAddress1Result;
    let prevAddress1_1result;
    let prevAddress1_2result;
    let prevAddress1len;
    if (txtlstSalutation) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("salutationRadio").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      DobDayResult = await trigger("dobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (DobDayResult) {
      DobMonthResult = await trigger("dobMonth");
    } else {
      document.getElementById("dobDay").focus();
      return false;
    }
    if (DobMonthResult) {
      DobYearResult = await trigger("dobYear");
    } else {
      document.getElementById("dobMonth").focus();
      return false;
    }
    if (DobYearResult) {
      txtEmailResult = await trigger("txtEmail");
    } else {
      document.getElementById("dobYear").focus();
      return false;
    }
    if (txtEmailResult) {
      txtPhoneResult = await trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }
    if (txtPhoneResult) {
      txtHomeOwnerStatus = await trigger("homeOwnerStatus");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (txtHomeOwnerStatus) {
      postCodeResult = await trigger("txtPostCode");
    } else {
      document.getElementById("homeOwnerStatus").focus();
      return false;
    }
    if (postCodeResult) {
      if (!PostcodeValidation(form["txtPostCode"].value)) {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
        document.getElementById("txtPostCode").focus();
        return false;
      } else {
        prevAddress = await trigger("address1");
      }
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    prevAddress1len = form["txtPrevPostCode_1"].value;
    if (prevAddress && prevAddress1len != "") {
      prevAddress1Result = await trigger("txtPrevPostCode_1");
    } else if (prevAddress && prevAddress1len == "") {
      document.getElementById("iva").focus();
    } else if (!prevAddress) {
      document.getElementById("address1").focus();
      return false;
    }
    if (prevAddress1Result) {
      if (!PostcodeValidation(form["txtPrevPostCode_1"].value)) {
        setError("txtPrevPostCode_1", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
        document.getElementById("txtPrevPostCode_1").focus();
        return false;
      } else {
        prevAddress1_1result = await trigger("txtPrevaddress1_1");
      }
    }
    if (prevAddress1Result && !prevAddress1_1result) {
      document.getElementById("txtPrevaddress1_1").focus();
      return false;
    }
    if (prevAddress1_1result) {
      formData.txtPrevStreet_1 = form["txtPrevStreet_1"].value;
    }
    if (addAnotherButton) {
      let prevAddField = form["txtPrevPostCode_2"].value;
      if (prevAddField != "") {
        if (!PostcodeValidation(form["txtPrevPostCode_2"].value)) {
          setError("txtPrevPostCode_2", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
          document.getElementById("txtPrevPostCode_2").focus();
          return false;
        } else {
          prevAddress1_2result = await trigger("txtPrevaddress1_2");
        }
      }
      if (!prevAddress1_2result && prevAddField != "") {
        document.getElementById("txtPrevaddress1_2").focus();
        return false;
      }
      if (prevAddress1_2result) {
        formData.txtPrevStreet_2 = form["txtPrevStreet_2"].value;
      }
    }
    if (emailCheck == 0) {
      document.getElementById("txtEmail").focus();
      setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
        shouldFocus: true,
      });
      return false;
    }
    if (phoneCheck == 0) {
      document.getElementById("txtPhone").focus();
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
      return false;
    }
    formData.txtStreet = form["txtStreet"].value;
    if (ivaStatus == "" || ivaStatus == "30") {
      document.getElementById("iva").focus();
      setIvaErr("show");
      return false;
    } else {
      formData.bank = 1;
      formData.question10 = ivaStatus;

      if (policyStatus == "" || policyStatus == "33") {
        setPolicyErr("show");
        return false;
      }else{
        formData.question11 = policyStatus;
      }
      

      localStorage.setItem("formData", JSON.stringify(formData));
      const full_url = window.location.href;
      const query_string_arr = full_url.split("?");
      var queryString = "";
      if (query_string_arr != null) {
        queryString = query_string_arr[1];
      }
      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "CL_LS_V7",
        queryString,
        visitorParameters.data
      );

      GTMDataLayer({
        question: "Form Submitted",
      });

      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            if (EnvConstants.AppConversionPixel === "true") {
              setShow(true);
              history.push("/fbpixel?split_name=" + props.splitName +"&next_page=" + props.nextPage ); // fb fixel firing
              window.scrollTo(0, 0);
            } else {
              window.scrollTo(0, 0);
              history.push(
                "/" + props.nextPage + "?uuid=" +
                visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
              );
            }
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/" + props.nextPage + "?uuid=" +
              visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/" + props.nextPage + "?uuid=" +
            visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
          );
        }
      }
    }
  };
  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div className="form-wrap" action="" autoComplete="">
          <div>
            <div className="container pb-5">
              <PersonalDetails
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                getValues={getValues}
                splitForm={splitForm}
                setError={setError}
              />
              <div className="row">
                <DateOfBirth
                  txtDob="Date of Birth"
                  validation={register}
                  validationMsg={errors}
                />
                <ContactDetails
                  title="Contact Details"
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  setError={setError}
                  getValues={getValues}
                  setEmailCheck={setEmailCheck}
                  setPhoneCheck={setPhoneCheck}
                />
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <div className="form-group row">
                    <label className="text-custom col-md-12">
                      <h5>Home Owner Status</h5>
                    </label>
                    <div className="col-12 mb-3">
                      <SelectBox
                        OptionValue={homeOwnerStatus}
                        className="form-style form-select  custom-select custom-select-lg"
                        id="homeOwnerStatus"
                        name="homeOwnerStatus"
                        onChange={() => clearErrors("homeOwnerStatus")}
                        myRef={register({
                          required: "Please Select Home Owner Status",
                        })}
                      />
                      {errors.homeOwnerStatus && (
                        <div className="mb-3 error">
                          <span className="" id="">
                            {errors.homeOwnerStatus.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <PostCode
                txtAddress="Your Address"
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                setError={setError}
                getValues={getValues}
                splitForm={splitForm}
                setBlinkEffect1={setBlinkEffect1}
              />
              <PreviousAddress
                txtPrevaddress="Previous Address"
                className={addressHide}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                setError={setError}
                getValues={getValues}
                splitForm={splitForm}
                setPrevAddressShow1={setPrevAddressShow1}
                setPrevAddressShow2={setPrevAddressShow2}
                blinkEffect1={blinkEffect1}
                setBlinkEffect1={setBlinkEffect1}
              />
              {addAnotherButton ? (
                <PreviousAddress_2
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  setError={setError}
                  getValues={getValues}
                  splitForm={splitForm}
                />
              ) : (
                ""
              )}
              <div className="row mt-2">
                <div className="col-lg-12">
                  <div className={prevAddressShow1}>
                    <Button
                      id="prevAddressButton"
                      className={`address-btn address-btn1 ${blinkEffect1}`}
                      type="button"
                      buttonText="I don't have any Previous Addresses"
                      onClick={() => {
                        setPrevAddressShow1("hide");
                        setAddressHide("hide");
                        onHandleSubmit();
                      }}
                    />
                  </div>
                  <div className={prevAddressShow2}>
                    <Button
                      id="addMore"
                      className="address-btn address-btn2 "
                      type="button"
                      buttonText="Add Another"
                      onClick={() => {
                        setPrevAddressShow2("hide");
                        setAddAnotherButton(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-5 mb-2">
                <div className="col-lg-7">
                  <p>
                    Have you ever been in or are proposed to be in an IVA, Debt
                    Relief Order, Declared Bankrupt or something similar?
                  </p>
                </div>
                <div className="col-lg-5">
                  <div className="btn-fr  btn-group" id="iva" tabIndex="0">
                    <div
                      className={` btn btn1 btn-light p-2 ps-3 pe-3 `}
                      onClick={() => {
                        setShow(true);
                        setIvaStatus("30");
                        setIvaErr("hide");
                      }}
                    >
                      <RadioButton id="iva_yes" labelName="Yes" ref={register("iva")} />
                    </div>
                    <div
                      className={` btn btn1 btn-light p-2 ps-3 pe-3 ${ivaStatus == "31" ? "current" : ""
                        }`}
                      onClick={() => {
                        setIvaStatus("31");
                        setIvaErr("hide");
                      }}
                    >
                      <RadioButton
                        id="iva_no"
                        value="no"
                        labelName="No"
                        ref={register("iva")}
                      />
                    </div>
                  </div>
                  <span className={`iva_error ${ivaErr} `} id="iva_err">
                    Please Select One
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7">
                  <p>
                    {" "}
                    I confirm I have read and agree with the terms of the
                    Privacy Policy and consent to you contacting me to discuss
                    my Irresponsible Lending claim(s).
                  </p>
                </div>
                <div className="col-lg-5 mb-3">
                  <div className="btn-fr  btn-group">
                    <div className={` btn btn1 btn-light p-2 ps-3 pe-3 ${policyStatus == "32" ? "current" : ""
                      }`}
                      onClick={() => {
                        setPolicyStatus("32");
                        setPolicyErr("hide");
                      }}>
                      <RadioButton
                        id="policy_yes"
                        name="policy"
                        labelName="Yes"
                      />
                    </div>
                    <div className={` btn btn1 btn-light p-2 ps-3 pe-3`}
                      onClick={() => {
                        setShow(true);
                        setPolicyStatus("33");
                        setPolicyErr("hide");
                      }}>
                      <RadioButton
                        id="policy_no"
                        name="policy"
                        labelName="No"
                      />
                    </div>
                  </div>
                  <span className={`iva_error ${policyErr} `} id="policy_err">
                    Please Select One
                  </span>
                </div>
              </div>
              <p>
                {" "}
                You do not need to use our services to make a claim against your
                lender(s). It's free to claim compensation from financial
                companies if you have lost money from a mis-sold financial
                product. You can do this yourself directly with the lender(s)
                and even if they reject your claim you can still take this to
                the Financial Ombudsman Service
                https://www.financial-ombudsman.org.uk. If you aren’t sure how
                to make the claim, you can get free, impartial advice from
                consumer organisations.{" "}
              </p>
            </div>
          </div>
          <section className="mt-lg-5 mt-md-2 mt-sm-0 section-next sec-nxt1">
            <div className="container ">
              <InputButton
                name="formSubmitBtn"
                className="com_bnt w-100 btn-next next-btn"
                id="formSubmitBtn"
                value="Next"
                btnType="button"
                style={{ float: "none" }}
                onClick={basicDetailsValidation}
              />
            </div>
            <ModalPopup
              content="Unfortunately we cannot process any cases based on this criteria"
              popDisplay={show}
              handleClose={handleClosePolicyModal}
            />
          </section>
        </div>
      </form>
    </>
  );
};
export default FormSplitCL_LS_V7;
