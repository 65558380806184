import React from "react";
import { Modal } from "react-bootstrap";
const ModalPopup = ({ content, popDisplay, handleClose }) => {
    return (
        <>
            <Modal show={popDisplay} onHide={handleClose} dialogClassName="modalReact" backdrop="static"
        keyboard={false}>
                <div className="CL_LS_V3" id="model1" backdrop="static"  >
                    <div className="">
                        <div className=" popup-style p-4">
                            <div className=" text-center">
                                <Modal.Body>
                                    <h1>{content}</h1>
                                </Modal.Body>
                            </div>
                            <button
                                type="button"
                                className="btn com_bnt w-100"
                                data-bs-dismiss="modal"
                                onClick={handleClose}
                            >
                               OK
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ModalPopup;