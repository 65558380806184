import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useFollowupUserSignature = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const saveFollowupUserSignature = async (
    signature_data,
    followup_data,
    message_type,
    uuid
  ) => {
    const signatureSubmit = await Api.post("v1/data-ingestion-pipeline", {
      signature_data,
      followup_data,
      message_type,
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid:uuid
      }
      return {
        data : {
          status : "Success"
        }
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return signatureSubmit;
  };
  return {
    saveFollowupUserSignature,
  };
};
